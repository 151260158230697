import { ProgressBar } from "react-bootstrap";


export default function MacroProgressBar({macroName, macroNumber, macroConsumed}){ 
    const percentage = (macroConsumed / macroNumber) * 100;
    
    const constructBackgroundColor = (muscleSets) => {
        if (percentage < 25){
            return 'warning'
        } else if (percentage < 50){
            return 'info'
        } else if (percentage < 100){
            return 'success'
        }
        return 'danger'
    }

    const constructLabel = () => {
        return `${macroConsumed} ${macroName.toUpperCase() == 'CALORIES' ? 'calories' : 'g'}`
    }
    return (
        <div className="muscle-scorecard-bar">
            <h6>{macroName}</h6>
            <p>{Number(macroNumber - macroConsumed).toFixed(2)}{macroName.toUpperCase() == 'CALORIES' ? ' Calories': 'g'} Left</p>
            <ProgressBar
            variant={constructBackgroundColor()}
            animated now={percentage} label={`${constructLabel()}`} striped/>
        </div>
    )
}