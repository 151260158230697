
import {Container, Row, Col, Form, Button, InputGroup, Accordion, Card, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {useState, useEffect, useMemo} from 'react';
import API from './helpers/api';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import faqs from './helpers/faq';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
  const [mode, setMode] = useState(() => 'create');
  const [user, setUser] = useState(() => ({
    email_address: undefined,
    first_name: undefined,
    user_password: undefined,
    confirm_user_password: undefined,
    accept_terms: false,
    accept_health_acknowledgement: false
  }));
  const [showPassword, setShowPassword] = useState(() => false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(() => false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await api.createUser(user);
    if (response){
      navigate('/user-intake');
      return;
    }
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    const response = await api.loginUser(user);
    if (response){
      navigate('/');
      return;
    }
  }

  const handleChange = (key, value) => {
    setUser({...user, [key]: value});
  }

  const scrollToDivById = (id) => {
    const element = document.getElementById(id);
    if (element){
      element.scrollIntoView({behavior: 'smooth'});
    }
  }
  
  if (mode == 'create'){
    return (
      <Container className="mb-5 karved-content-container">
        <Row className="mb-5">
          <Col >
            <img src="/images/HeroImage_compressed.jpg" className="img-fluid"/>

            <Row className="karved-content-container mt-5">
              <h2 className="text-center">Don't Just Lose Weight... Get KarvedUp!</h2>
              <hr/>
              <p>
                KarvedUp is your personalized fitness and nutrition companion. 
                From tailored workout plans to precise macro tracking, we make achieving your fitness goals simple, effective, and fun. 
                Whether you're looking to build muscle, burn fat, or just live a little healthier, KarvedUp has you covered.
                </p>
              <Row className="border-solid-row">

              <Col  xs={12} lg={6} className="mt-3">
                  <img src="/images/food_compressed.webp" className="img-fluid"/>
                </Col>

                <Col xs={12} lg={6} className="mt-3">
                  <Card>
                    <Card.Body>
                        <h4 className="text-center">Smarter Macro Tracking</h4>
                        <hr/>

                        <ul>
                          <li>Macro Suggestions based on Fitness Goal and Lifestyle.</li>
                          <li className="mt-2">AI Powered Food Database to take the guess work out of tracking</li>
                          <li className="mt-2">Barcode Search when you're on the go.</li>
                          <li className="mt-2">Custom Cookbook tailored to your favorite foods and diet restrictions.</li>
                          <li className="mt-2">Weekly Macro Suggestions based on your progress.</li>
                        </ul>
                        <hr className="mt-5"/>
                        <div className="text-center">
                          <h5>Only $4.99 a Month!</h5>
                          <h6>A personal fitness coach for less than the price of a coffee.</h6> <br/>
                          
                          <Button onClick={() => scrollToDivById('sign-up-form')}>Sign Up Now</Button>
                          <p>* Keep this low price forever, if you sign-up before January 1st!</p>
                        </div>
                    </Card.Body>
                  </Card>
                </Col>

                
              </Row>

              <Row className="border-solid-row">
                

                <Col  xs={12} lg={6} className="mt-3">
                  <Card>
                    <Card.Body>
                    <h4 className="text-center">Work Smarter AND Harder!</h4>
                    <hr/>

                    <ul>
                      <li >Custom Workout Plan Tailored to you.</li>
                      <li className="mt-2">Access to Pre-Made Library of Workout Plans</li>
                      <li className="mt-2">Progress Tracking to keep you motivated.</li>
                      <li className="mt-2">Workout Builder to create your own workouts.</li>
                      <li className="mt-2">Create AI Powered Workouts whenever you need a change!</li>
                    </ul>
                    <hr className="mt-5"/>
                    <div className="text-center">
                      <h5>Only $4.99 a Month!</h5>
                      <h6>Industry Quality Workout Plans for less than a gallon of gas!</h6> <br/>
                      
                      <Button onClick={() => scrollToDivById('sign-up-form')}>Sign Up Now</Button>
                      <p>* Keep this low price forever, if you sign-up before January 1st!</p>
                    </div>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} lg={6} className="mt-3">
                  <img src="/images/working_out_compressed.webp" className="img-fluid"/>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>

        <Row className="border-solid-row mb-5">
                <h4 className="text-center mt-5">What Our Members Are Saying</h4>
                <Row>
                    <Card className="p-4">
                        <Card.Body>
                            <Row>
                            <Col xs={12} lg={6} className="text-center">
                                <img src="/testimonials/SteveH.png" className="img-fluid" />
                           </Col>
                           <Col xs={12} lg={6} className="p-2">
                                <h3>Steve H.</h3>
                                <h5>OCB Pro and Karate Hall of Famer</h5>
                                <p className="text-muted">- Steve H.</p>

                                <p>
                                Karved Up is amazing!    More than just a fitness app or something to help you track calories, Karved Up helps you set and achieve goals.   I was on a fitness journey but with no particular destination in mind other than weight loss.   I had tried different approaches which led to inconsistent results.  
                                </p>
                                <p>
Once I was exposed to and started really using the information that Karved Up can give me, I started setting more aggressive and defined goals and, before I knew it, I’m competing in bodybuilding competitions that I would’ve never even considered before.    
</p>
<p>
Because of Karved Up I was able to keep weight off, build muscle, be healthier, and, ultimately, win an OCB Pro Card!
</p>
<p>
You may not know where you’re going on your particular fitness journey or even where to start.   Karved Up is the right place for you!
                                </p>

                                <hr className="mt-5"/>
                                
                                <div className="text-center">
                                  <h5>Only $49.99 a Year!</h5>
                                  <h6>Save 16% by signing up for an annual membership</h6> <br/>
                                  
                                  <Button onClick={() => scrollToDivById('sign-up-form')}>Sign Up Now</Button>
                                  <p>* Keep this low price forever, if you sign-up before January 1st!</p>
                                </div>
                            </Col>
                           
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>

            </Row>
        <Row>
          <Col className="karved-content-container" id="sign-up-form">
            <h1 className="text-center">Let's Get you KarvedUp!</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="text" 
                                placeholder="First Name" 
                                isValid={user.first_name && user.first_name.length > 0}
                                onChange={(e) => handleChange('first_name', e.target.value)} required/>
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" 
                                placeholder="Enter email" 
                                onChange={(e) => handleChange('email_address', e.target.value)}
                                isValid={user.email_address && user.email_address.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)} 
                                required/>
                </Form.Group>

                <Form.Group className="mt-4">
                  <Form.Label>Password</Form.Label>
                  <InputGroup >
                  
            
                  <Form.Control type={showPassword ? 'text' : 'password'} 
                                placeholder="Password" 
                                onChange={(e) => handleChange('user_password', e.target.value)} 
                                isValid={user.user_password && user.user_password.length >= 8}
                                required/>
               
                    <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                    </Button>
       
                  </InputGroup>
                </Form.Group>
                
             

                <Form.Group className="mt-4">
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <Form.Control type={showConfirmPassword ? 'text' : 'password'} 
                                  placeholder="Confirm Password" 
                                  isValid={user.confirm_user_password && user.confirm_user_password === user.user_password}
                                  onChange={(e) => handleChange('confirm_user_password', e.target.value)} 
                                  required/>
                    <Button variant="outline-secondary" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                    </Button>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mt-4">
  <Form.Check
    type="checkbox"
    label={
      <>
        I accept the{' '}
        <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
          terms and conditions
        </a>
        {' '}and I have read the{' '}
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          privacy policy
        </a>
        .
      </>
    }
    onChange={(e) => handleChange('accept_terms', e.target.checked)}
    isValid={user.accept_terms}
    required
  />
</Form.Group>

<Form.Group className="mt-4">
  <Form.Check
    type="checkbox"
    label={
      <>
        I acknowledge that I am responsible for my own health and wellness and that{' '}
        <a href="https://karvedup.com" target="_blank" rel="noopener noreferrer">
          KarvedUp
        </a>{' '}
        is not a substitute for professional medical advice.
      </>
    }
    onChange={(e) => handleChange('accept_health_acknowledgement', e.target.checked)}
    isValid={user.accept_health_acknowledgement}
    required
  />
</Form.Group>



                <div className="text-center">
                  <Button className="mt-4 primary-btn" type="submit">
                    Create User
                  </Button>
                  <br/> <br/>
                  <div> 
                    Already have an account? <a href="#" onClick={() => setMode('login')}>Login</a>
                  </div>
                </div>
            </Form>
          </Col>
        </Row>

        <Row>
                <h4 className="text-center mt-5">Frequently Asked Questions</h4>
                <Row>
                    <Card className="p-4">
                        {faqs.map((faq, index) => {
                            return (
                                <Accordion key={index}>
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>{faq.answer}</Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })}
                    </Card>
                </Row>
            </Row>
      </Container>
    );
  }

  return (
    <Container className="mb-5">
      <Row>
        <Col className="karved-content-container" xs={{span: 10, offset: 1}} sm={{span: 8, offset:2}} md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}}>
          <h1 className="text-center">Welcome Back!</h1>
          <p className="text-center">Login to your account below.</p>

          <Form onSubmit={handleLoginSubmit}>
              <Form.Group>
                <Form.Label>Email Address</Form.Label>
                <Form.Control type="email" 
                              placeholder="Enter email" 
                              onChange={(e) => handleChange('email_address', e.target.value)}
                              isValid={user.email_address && user.email_address.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)} 
                              required/>
              </Form.Group>

              <Form.Group className="mt-4">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                <Form.Control type={showPassword ? 'text' : 'password'}
                              placeholder="Password" 
                              onChange={(e) => handleChange('user_password', e.target.value)} 
                              isValid={user.user_password && user.user_password.length >= 8}
                              required/>
                  <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)}>
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                  </Button>
                </InputGroup>
              </Form.Group>

              <div className="text-center">
                <Button className="mt-4 primary-btn" variant="primary" type="submit">
                  Login
                </Button>
                <br/> <br/>
                <div>
                  Forget your password? <a href="#" onClick={() => navigate('/forgot-password')}>Reset Password</a>
                </div>
                <div> 
                  Don't have an account? <a href="#" onClick={() => setMode('create')}>Create Account</a>
                </div>
              </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
  
}

export default App;
