import { useParams } from "react-router-dom";
import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import API from "../../helpers/api";
import { Row, Col, Form, Button, Container } from "react-bootstrap";

export default function ResetPassword() {
    const dispatch = useDispatch();
    const api = useMemo(() => new API({ dispatch }), [dispatch]);
    const { resetToken } = useParams();
    const [password, setPassword] = useState(() => "");
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const [success, setSuccess] = useState(() => false);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const didSucceed = await api.resetPassword({
        token: resetToken,
        password,
        });
        setIsSubmitting(false);
        if (didSucceed) {
        setSuccess(true);
        }
    };
    
    return (
        <Container>
        <Row>
            <Col>
            <h1>Reset Password</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="password">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                    type="password"
                    value={password}
                    isInvalid={password.length < 8}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                </Form.Group>
                <Button type="submit" disabled={isSubmitting}>
                Submit
                </Button>
            </Form>
            {success && <p>Password reset successfully.</p>}
            </Col>
        </Row>
        </Container>
    );
    }