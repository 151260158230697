import {Modal, Form, Button, Row, Col} from 'react-bootstrap';
import {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';


export default function CardioModal({save, close, cardio}){
    const [newCardio, setNewCardio] = useState(() => {
        if (cardio) return cardio;
        return {
            cardioWorkoutName: '',
            cardioDescription: '',
            activities: [
                {
                    activity: '',
                    duration: 0,
                }
            ],
            totalDuration: 0,
        }
    });

    const handleSave = (e) => {
        e.preventDefault();
        save(newCardio);
    }

    const addActivity = () => {
        setNewCardio({
            ...newCardio,
            activities: [...newCardio.activities, {
                activity: '',
                duration: 0,
            }]
        })
    }

    const removeActivity = (index) => {
        setNewCardio({
            ...newCardio,
            activities: newCardio.activities.filter((act, i) => i !== index)
        })
    }

    const buildActivitiesSection = () => {
        if (!newCardio.activities.length){
            return (
                <Row>
                    <h4>Add Activities to this Cardio Workout</h4>
                    <Button onClick={addActivity}>Add Activity</Button>
                </Row>
            )
        }

        return (
            <div className="border-solid-row mt-1">
                <h5>Activities</h5>
               {
                    newCardio.activities.map((activity, index) => {
                        return (
                            <Row key={index} className="border-solid-row">
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Activity </Form.Label>
                                        <Form.Control type="text" value={activity.activity} onChange={(e) => {
                                            let newActivities = [...newCardio.activities];
                                            newActivities[index].activity = e.target.value;
                                            setNewCardio({...newCardio, activities: newActivities});
                                        }} />
<div className="text-center">
<Button className="mt-3" variant="danger" onClick={() => removeActivity(index)}><FontAwesomeIcon icon={faTrashCan}/> <br/> Remove Activity</Button>
</div>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>Duration (mins)</Form.Label>
                                        <Form.Control type="number" value={activity.duration} onChange={(e) => {
                                            let newActivities = [...newCardio.activities];
                                            newActivities[index].duration = e.target.value;
                                            setNewCardio({...newCardio, activities: newActivities});
                                        }} />
                                    </Form.Group>
                                </Col>
                                
                            </Row>
                        )
                    })
               }
               <div className=" mt-2 mb-5 text-center">
                    <Button variant="primary" onClick={addActivity}><FontAwesomeIcon icon={faPlusCircle}/><br/> Add Another Activity</Button>
               </div>
            </div>
        )
        
    }

    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header className="karved-up-modal" closeButton>
                <Modal.Title>Cardio Workout</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                
                <Form onSubmit={handleSave}>
                    <div className="border-solid-row">
                        <Form.Group>
                            <Form.Label>Cardio Workout Name</Form.Label>
                            <Form.Control type="text" value={newCardio.cardioWorkoutName} onChange={(e) => setNewCardio({...newCardio, cardioWorkoutName: e.target.value})} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cardio Workout Description</Form.Label>
                            <Form.Control type="text" value={newCardio.cardioDescription} onChange={(e) => setNewCardio({...newCardio, cardioDescription: e.target.value})} />
                        </Form.Group>
                    </div>
                    <div>
                        {buildActivitiesSection()}
                    </div>
                    <hr/>
                    <Row>

                    
                    <Button type="submit"><FontAwesomeIcon icon={faSave} /> <br/>Save</Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}