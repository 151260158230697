import {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col, Form, Button, Card, Accordion} from 'react-bootstrap';
import API from '../../helpers/api';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import faqs from '../../helpers/faq';

//SHOW HE PAYMENT OPTIONS AND REDIRECT THE USER TO THE STRIPE CHECKOUT PAGE
const stripePromise = loadStripe('pk_test_EYlmgAFOttijmvgxHgkGGWjt');

const CheckoutSelect = () => {
    const stripe = useStripe();
    const dispatch = useDispatch();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const elements = useElements();
    const [loading, setLoading] = useState(() => false);
    const [error, setError] = useState(() => null);

    useEffect(() => {
        //Scroll to the top of the screen
        window.scrollTo(0, 0);
    }, [])

    const redirectToCheckout = async (priceId) => {
        setLoading(true);
        const response = await api.createCheckoutSession(priceId);
        const result = await stripe.redirectToCheckout({
            sessionId: response.sessionId
        });

        if (result.error){
            setError(result.error.message);
            setLoading(false);
        }
    }

    return (
        <Container className="karved-content-container">
            <h3 className="text-center mb-3">Choose Your Plan & Get KarvedUp!</h3>
            <h5 className="text-center">
                Prices will increase after January 1st, 2025. Lock in your price now!
            </h5>
            <hr/>
                
            <Row>
                <Col md={6}>
                    <Card className="p4">
                       <Card.Body>
                        <h4 className="text-center">Monthly Membership</h4>
                        <h5 className='text-center mt-3 mb-3'>$4.99</h5>
                        <p className="text-center">Billed Monthly</p>

                        <ul>
                        <li>Access to all workout plans, meal tracking, and progress sharing features.</li>
                        <li>No long-term commitment, cancel anytime.</li>
                        <li>Perfect if you want to try KarvedUp before committing.</li>
                    </ul>
                    <Row>
                        <Button className="primary-btn" onClick={() => redirectToCheckout('price_1QDq7JDz3duCfD1EXcafDxow')}>Sign Up for Monthly</Button>
                    </Row>
                       </Card.Body>
                    </Card>
                    
                    
                </Col>
                <Col md={6} >
                    <Card className="p4">
                        <Card.Body>
                            <h4 className="text-center">Annual Membership</h4>
                            <h5 className='text-center mt-3 mb-3'>$49.99</h5>
                            <p className="text-center">Billed Annually</p>
                            <ul>
                                <li>Access to all workout plans, meal tracking, and progress sharing features.</li>
                                <li>No long-term commitment, cancel anytime.</li>
                                <li>Get KarvedUp for Cheaper!</li>
                            </ul>
                            <Row>
                                <Button className="primary-btn" onClick={() => redirectToCheckout('price_1QDq8BDz3duCfD1EdwyAcant')}>Sign Up for Yearly</Button>
                            </Row>
                        </Card.Body>
                    </Card>
                    
                </Col>
            </Row>
            {/* Testimonials */}
            <Row>
                <h4 className="text-center mt-5">What Our Members Are Saying</h4>
                <Row>
                    <Card className="p-4">
                        <Card.Body>
                            <Row>
                           <Col xs={12} md={6}>
                                <img src="/testimonials/SteveH.png" className="img-fluid" />
                           </Col>
                           <Col xs={12} md={6}>
                                <h3>Steve H.</h3>
                                <h5>OCB Pro and Karate Hall of Famer</h5>
                                <p className="text-muted">- Steve H.</p>

                                <p>
                                Karved Up is amazing!    More than just a fitness app or something to help you track calories, Karved Up helps you set and achieve goals.   I was on a fitness journey but with no particular destination in mind other than weight loss.   I had tried different approaches which led to inconsistent results.  
                                </p>
                                <p>
Once I was exposed to and started really using the information that Karved Up can give me, I started setting more aggressive and defined goals and, before I knew it, I’m competing in bodybuilding competitions that I would’ve never even considered before.    
</p>
<p>
Because of Karved Up I was able to keep weight off, build muscle, be healthier, and, ultimately, win an OCB Pro Card!
</p>
<p>
You may not know where you’re going on your particular fitness journey or even where to start.   Karved Up is the right place for you!
                                </p>
                            </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>

            </Row>
            {/* Frequently Asked Questions in Accordions*/}
            <Row>
                <h4 className="text-center mt-5">Frequently Asked Questions</h4>
                <Row>
                    <Card className="p-4">
                        {faqs.map((faq, index) => {
                            return (
                                <Accordion key={index}>
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>{faq.question}</Accordion.Header>
                                        <Accordion.Body>{faq.answer}</Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            )
                        })}
                    </Card>
                </Row>
            </Row>
            
        </Container>
        
    )
}

export default function StripePayment() {
    // Two buttons for subscription options
    return (
        <Elements stripe={stripePromise}>
            <CheckoutSelect />
        </Elements>
    )
}