import {useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import API from '../../helpers/api';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import moment from 'moment';
import CancelSubscriptionModal from './modals/cancel-subscription-modal';
import ReactivateSubscriptionModal from './modals/reactivate-subscription-modal';
import UpdateEmailModal from './modals/update-email-modal';
import UpdatePasswordModal from './modals/update-password-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLock } from '@fortawesome/free-solid-svg-icons';

//This component will show the user's information
//Allow them to update their email
//Allow them to update their password



export default function AccountOverview(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const user = useSelector(state => state.user.userData);
    const [subscriptionData, setSubscriptionData]  = useState(() => null);
    const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] = useState(() => false);
    const [showReactivateSubscriptionModal, setShowReactivateSubscriptionModal] = useState(() => false);
    const [showUpdateEmailModal, setShowUpdateEmailModal] = useState(() => false);
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(() => false);

    const closeModals = async () => {
       const [info] = await Promise.all([
            api.getUserData()
        ])
        setSubscriptionData(info.subscription);
        setShowCancelSubscriptionModal(false);
        setShowReactivateSubscriptionModal(false);
        setShowUpdateEmailModal(false);
        setShowUpdatePasswordModal(false);
    }

    useEffect(() => {
        async function loadUser(){
            if(!user){
                const userData = await api.getUserData();
                if(!userData){
                    navigate('/login');
                }
                const stripeData = await api.getSubscriptionInformation();
                if (stripeData){
                    setSubscriptionData(stripeData.subscription);
                }
            }
        }
        loadUser();
    }, []);

    const gotoSubscriptionManagementPortal = async () => {
        const sessionUrl = await api.getPaymentPortalLink();
        if (sessionUrl){
            window.location.href = sessionUrl;
        }
    }

    if (!user) return null;
    console.log(subscriptionData);
    let modal = null;
    if(showCancelSubscriptionModal){
        modal = <CancelSubscriptionModal close={closeModals} />
    } else if (showReactivateSubscriptionModal){
        modal = <ReactivateSubscriptionModal close={closeModals} />
    } else if (showUpdateEmailModal){
        modal = <UpdateEmailModal close={closeModals} />
    } else if (showUpdatePasswordModal){
        modal = <UpdatePasswordModal close={closeModals} />
    }
    return (
        <Container className="karved-content-container">
            {modal}
            <Row className=" mb-4">
                
                    <h1 className="text-center">Account Overview</h1>
                    <hr/>
                    <h2>Account Information</h2>
                    <Form>
                        <Form.Group controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" value={user.email_address} disabled/>
                        </Form.Group>
                        <hr/>
                        <Button className="primary-btn" onClick={() => setShowUpdateEmailModal(true)}><FontAwesomeIcon icon={faEnvelope}/> Update Email</Button> <br/>
                        <Button className="primary-btn mt-1" onClick={() => setShowUpdatePasswordModal(true)}><FontAwesomeIcon icon={faLock}/> Update Password</Button>
                    </Form>
               
            </Row>

            <Row>
            <h2>Manage Subscription</h2>
            <p>Need to change your credit card information? Cancel your subscription?</p>
            <hr/>
            <Button className="primary-btn" onClick={gotoSubscriptionManagementPortal}>Goto Subscription Portal</Button>
                    
            </Row>
        </Container>
    )
}