//This is the screen where the end user will input their first target goal and get their macros.

import {useState, useMemo, useEffect} from 'react';
import {Container, Row, Col, Form, Button, InputGroup} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import MacroCalculator from '../../helpers/macro-calculator';
import moment from 'moment';
import KarvedUpTooltip from '../shared/karved-up-tooltip';

const macroImageMap = {
    protein: '/buttons/macro-icons/Protein.png',
    carbs: '/buttons/macro-icons/Carbs.png',
    fats: '/buttons/macro-icons/Fats.png',
    calories: '/buttons/macro-icons/Calories.png'
}

const dailyCaloriesPerPound = 500;
export default function FirstGoal(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userIntake = useSelector(state => state.user.userIntake);
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [firstGoal, setFirstGoal] = useState(() => ({
        weekly_weight_change: 0,
        weeks_to_goal: 0,
        target_weight: 0,
        protein_per_lb: 0.8,
        percentage_of_fat: 20,
        target_macros: {
            protein: 0,
            carbs: 0,
            fats: 0,
            calories:0,
            unused_calories: 0,
            
        }
    }));
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    
    useEffect(() => {
        ///START AT TOP OF PAGE
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        (async function(){
            await api.getUserIntake();
            console.log("GOT THE INTAKE")
        })()
    }, [])

    useEffect(() => {
        if (!firstGoal.target_weight || !userIntake) return;
        console.log("FIRST GOAL: ", firstGoal)
        let goal = 'maintain';
        if (firstGoal.target_weight < userIntake.current_weight) goal = 'lose';
        if (firstGoal.target_weight > userIntake.current_weight) goal = 'gain';
        const newMacros = MacroCalculator({
            currentWeight: Number(userIntake.current_weight),
            age: moment().diff(userIntake.dob, 'years'),
            fitnessLevel: userIntake.fitness_level,
            height_in_inches: Number(userIntake.height_in_inches),
            gender: userIntake.gender,
            weekly_weight_change: Number(firstGoal.weekly_weight_change),
            goal,
            protein_per_pound: firstGoal.protein_per_lb,
            fats_percentage: firstGoal.percentage_of_fat / 100,
        })

        console.log("NEW MACROS: ", newMacros);

        setFirstGoal({...firstGoal, target_macros: {...firstGoal.target_macros, ...newMacros}});
    }, [firstGoal.weekly_weight_change, firstGoal.target_weight, userIntake, firstGoal.percentage_of_fat, firstGoal.protein_per_lb])

    useEffect(() => {
        if (userIntake && userIntake.target_weight){
            //Set the target weight as the default
            setFirstGoal({...firstGoal, target_weight: parseFloat(userIntake.target_weight)})
        }
    }, [userIntake])

  


    const handleSubmit = async (e) => {
        e.preventDefault();
        if ( firstGoal.target_weight == 0) return;
        setIsSubmitting(true);
        const response = await api.upsertUserIntake(firstGoal);
        const macrosSaved = await api.createUserDailyMacros({
            protein: firstGoal.target_macros.protein,
            fat: firstGoal.target_macros.fats,
            carbohydrate: firstGoal.target_macros.carbs,
            calories: firstGoal.target_macros.calories,
        });
        
        const goalRequestObject = {
            goal: firstGoal.target_weight < userIntake.current_weight ? 'lose' : 'gain',
            target_weight: firstGoal.target_weight,
            goal_date: moment().add(firstGoal.weeks_to_goal, 'weeks').format('YYYY-MM-DD')
        }
        const savedGoal = await api.createUserGoal(goalRequestObject);
        if (response && macrosSaved && savedGoal) {
            navigate('/user-payment');
            return
        }
        setIsSubmitting(false);
    }

    const scrollToTop = () => {
        /*
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 250)
        */
    }

    const handleChange = (key, value, scroll = true) => {
        
        if (key == 'weekly_weight_change' && !isNaN(value) && firstGoal.target_weight && userIntake.current_weight){
            const weeks_to_goal = Math.abs(Math.floor((firstGoal.target_weight - userIntake.current_weight) / value));
            console.log(weeks_to_goal)
            setFirstGoal({...firstGoal, [key]: value, weeks_to_goal});
            if (scroll) scrollToTop();
            
            return;
        }
        setFirstGoal({...firstGoal, [key]: value});
        scrollToTop();
    }

    const buildWeightRow = () => {
        if (!userIntake) return null;
        let goalOverviewSection = null;
        if (userIntake.current_weight > firstGoal.target_weight){ 
            goalOverviewSection = (
                <div className="mt-5">
                   
                    <img src="/goal-icons/Lose-Fat.png" className="img-fluid"/>
                    <p>The macros generated for you below are calculated to put you in a caloric deficit.</p>
                    <p>Suggestions will be updated weekly as we get a better sense of your activity level and metabolism.</p>
                </div>
            )
        } else if (userIntake.current_weight < firstGoal.target_weight){
            goalOverviewSection = (
                <div className="mt-5">
                   
                    <img src="/goal-icons/Build.png" className="img-fluid"/>
                    <p>The macros generated for you below are calculated to put you in a caloric surplus.</p>
                    <p>Suggestions will be updated weekly as we get a better sense of your activity level and metabolism.</p>
                </div>
            )
        } else {
            goalOverviewSection = (
                <div className="mt-5">
                 
                    <img src="/goal-icons/Maintain.png" className="img-fluid"/>
                    <p>The macros generated for you below are calculated to help you maintain your current weight.</p>
                    <p>Suggestions will be updated weekly as we get a better sense of your activity level and metabolism.</p>
                </div>
            )
        }
        return (
            <div>
            <Row >
                <Col xs={12} md={6} 
                    className="text-center border-solid-row">
                    <h5>Your Current Weight</h5>
                    <h6>{userIntake.current_weight}lbs</h6>
                </Col>
                
                <Col xs={12} md={6} className="text-center border-solid-row">
                    <h5>Your Target Weight</h5>
                    <Form.Group>
                        <Form.Control type="number"
                                      value={firstGoal.target_weight}
                                      isValid={!isNaN(firstGoal.target_weight) && firstGoal.target_weight > 0}
                                      onChange={(e) => handleChange('target_weight', e.target.valueAsNumber)} 
                                      required/>
                    </Form.Group>
                </Col>
            </Row>
            <Row className="text-center">
                {goalOverviewSection}
            </Row>
            </div>
        )
    }

    

    const buildProteinPerPoundRow = () => {

    }

    const buildFatPercentageRow = () => {
        return(
            <Row className="karved-up-row mt-5">
            
            </Row>
        )
    }

    const buildWeeklyWeightChangeRow = () => {
        if (!firstGoal.target_weight) return null;

        //SLIDER THAT GOES FROM -2 To 2 in 0.25 increments

        //STYLE SLIDER TO HAVE KARVEDUP GREEN COLOR
        let title = "How much weight would you like to lose per week "
        if (firstGoal.target_weight < userIntake.current_weight) title = "How much weight would you like to lose per week ";
        if (firstGoal.target_weight > userIntake.current_weight) title = "How much weight would you like to gain per week ";
        const slider = (
            <Form.Group className="karved-up-slider">
                <Form.Label>Weekly Weight Change (in LBS): {firstGoal.weekly_weight_change}lbs</Form.Label>
                <Form.Control type="range" 
                              min="0"
                              max="2"
                              step="0.25"
                              value={firstGoal.weekly_weight_change}
                              onChange={(e) => handleChange('weekly_weight_change', e.target.valueAsNumber, false)} required/>
            </Form.Group>
        )
        return (
            <Row className="mt-5">
                <hr/>
                <Col>
                    <h5 className="mb-3">{title} 
                        <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                The more aggressive the weight change, the more likely you are to lose muscle mass. 
                                We recommend a weight change of 1-2lbs per week for optimal results.    
                            </p>
                        </KarvedUpTooltip>
                    </h5>
                    <p>(Use the Slider Below)</p>
                    {slider}
                </Col>

                
            </Row>
        )
    }

    const buildMacroAdjustmentBar = () => {
        return (
            <Row className="mt-5">
                <Col>
                    <Form.Group>
                        <h5>% of Calories From Fats {' '}
                            <KarvedUpTooltip>
                                <p style={{color: '#fff'}}>
                                    Fats help promote brain health and hormonal balance. 
                                    We recommend a range of 20-60% of your daily calories come from fats.
                                </p>
                            </KarvedUpTooltip>
                        </h5>
                        <p>Fats help promote brain health and hormonal balance</p>
                        <InputGroup>
                        <Form.Control type="number" 
                                      min={20}
                                      max={60}
                                      step={.5}
                                      value={firstGoal.percentage_of_fat}
                                      onChange={(e) => handleChange('percentage_of_fat', e.target.valueAsNumber)} 
                                      required/>
                        <InputGroup.Text className="karved-up-input-group-text">%</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>

                <Col>
                    <Form.Group>
                        <h5>Protein Per Pound {' '}
                            <KarvedUpTooltip>
                                <p style={{color: '#fff'}}>
                                    Protein helps build and repair muscle. 
                                    We recommend a range of .4-1.2g of protein per pound of body weight.
                                </p>
                            </KarvedUpTooltip>
                        </h5>
                        <p>Protein helps build and repair muscle.</p>
                        <InputGroup>
                        <Form.Control   type="number"
                                        min={.4}
                                        max={1.2}
                                        step={.1}
                                        value={firstGoal.protein_per_lb}
                                        onChange={(e) => handleChange('protein_per_lb', e.target.valueAsNumber)} 
                                        required/>
                        <InputGroup.Text className="karved-up-input-group-text">g</InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    const buildWeeksToGoalRow = () => {
        if (!firstGoal.weekly_weight_change) return null;
        return (
            <Row className="karved-up-row mt-5">
                <h5 className="mb-3">Based on your weekly weight change, you should reach your goal in {firstGoal.weeks_to_goal} weeks.</h5>
            </Row>
        )
    }

    const buildTargetMacrosRow = () => {
        
        return (<Row className="karved-up-row mt-5">
            <h5 className="mb-3">Based on your goal, you should aim for the following macros:</h5>
                <Row>
                    {Object.keys(firstGoal.target_macros).map((key, i) => {
                        if (key == 'unused_calories') return null;
                        if (key == 'protein_per_lb' || key == 'percentage_of_fat') return null;
                        return (
                            <Col key={i} className="text-center">
                                <img src={macroImageMap[key]} className="img-fluid"/>
                                <h6>{key.charAt(0).toUpperCase() + key.slice(1)}: {firstGoal.target_macros[key]}</h6>
                            </Col>
                        )
                    })}
                </Row>
               
        </Row>)
    }

    const buildSubmitButton = () => {
        if (firstGoal.target_macros.calories == 0) return null;
        return (
            <Row className="karved-up-row mt-5">
                <Button className="primary-btn" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Saving... Hang Tight!" : "Let's Start The Journey!"}
                </Button>
            </Row>
        )
    }

    console.log(userIntake)

    return (
        <Container className="karved-content-container">
            <h1 className="text-center">Let's Generate Your Macros!</h1>
            <h5 className="text-center">Muscle is built in the kitchen.</h5>

            <Form onSubmit={handleSubmit}>
                {buildWeightRow()}
                {buildWeeklyWeightChangeRow()}
                {buildMacroAdjustmentBar()}
                {buildWeeksToGoalRow()}
                {buildTargetMacrosRow()}
                {buildSubmitButton()}
            </Form>
        </Container>
    )
}