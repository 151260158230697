import {Container, Row, Col, Form, Button, Card} from 'react-bootstrap';
import {useState, useEffect, useMemo} from 'react';
import API from '../../helpers/api';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

//TODO: Account for User Created Plans
//TODO: Account for Premium Plans
//TODO: Account for AI Generated Plans

export default function SelectWorkoutPlan() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userWorkouts = useSelector(state => state.workout.user_workout_plans);
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [isSaving, setIsSaving] = useState(() => false);

    const loadData = async() => {
        Promise.all([
            api.getUserWorkoutPlans()
        ])
    }
    useEffect(() => {
        loadData();
        window.scrollTo(0, 0);
    }, []);

    console.log(userWorkouts);

    const makeActiveWorkoutPlan = async(workoutPlanId, workout) => {
        setIsSaving(true);
        const requestObj = {
            workoutPlanId: workoutPlanId,
            workoutStartDate: moment().format('YYYY-MM-DD'),
            workoutEndDate: moment().add(workout.workout_plan_duration_in_weeks, 'weeks').format('YYYY-MM-DD')
        }
        const success = await api.saveUserActiveWorkoutPlan(requestObj);
        if (success){
            navigate('/dashboard');
        }
        setIsSaving(false);
    }

    const buildUserWorkoutPlanSelection = () => {
        if (userWorkouts.length == 0){
            return (
                <Col className="text-center"> 
                    <Card>
                        <Card.Header className='p-4'>
                            <h5>
                                Prefer to create your own workout plan?
                            </h5>
                        </Card.Header>  
                        <Card.Body className='p-4'>
                            <p>Use our easy to use Workout Planner to create your own workout plan.</p>    
                        </Card.Body>  
                        <Card.Footer>
                            <Button className="primary-btn" onClick={() => navigate('/workout-planner')}>Create Workout Plan</Button>
                        </Card.Footer>
                    </Card>               
                    
                </Col>

            )
        }

        return (
            <Col >
                <h4 className="text-center">Plans Created By/For You</h4>
                <p className="text-center"><Button onClick={() => navigate('/workout-planner')}>Create Another Plan</Button></p>
                {
                    userWorkouts.map((workout, index) => {
                        return (
                                <div key={index} className="border-solid-row">
                                    <h5 className="text-center">{workout.workout_plan_name} <br/>({workout.workout_plan_duration_in_weeks} Week Plan)</h5>
                                    <h6>Created On {moment(workout.created_on).format('MM/DD/YYYY hh:mma')}</h6>
                                    <p>{workout.workout_plan_description}</p>
                                    <div className="text-center">
                                        <Button disabled={isSaving} onClick={() => {makeActiveWorkoutPlan(workout.id, workout)}}>Select This Plan</Button>
                                    </div>
                                </div>
                        )
                    })
                }
            </Col>
        )

    }

    const buildAIWorkoutPlanSection = () => {
        return (
            <Col className="text-center">
            <Card >
                        <Card.Header className="p-4">
                            <h5>
                                Want a 100% customized plan?
                            </h5>
                        </Card.Header>  
                        <Card.Body className="p-4">
                            <p>Try out our KarvedUp AI Workout Generator!</p>    
                        </Card.Body>  
                        <Card.Footer>
                            <Button className="primary-btn">Generate AI Plan</Button>
                        </Card.Footer>
            </Card> 
            </Col>
        )
    }

    const buildPremiumWorkoutSection = () => {
        return (
            <Col className="text-center">
            <Card >
                        <Card.Header className="p-4">
                            <h5>
                            Want a workout plan written by a Professional Coach?
                            </h5>
                        </Card.Header>  
                        <Card.Body className="p-4">
                            <p>Check our selection of pre-written plans.</p>    
                        </Card.Body>  
                        <Card.Footer>
                            <Button className="primary-btn" onClick={() => navigate('/select-premium-plan')}>Explore Premium Plans</Button>
                        </Card.Footer>
            </Card> 
            </Col>
        )
    }

    return (
        <Container className="mb-5 karved-content-container" >
             <h1 className="text-center">SELECT A WORKOUT PLAN</h1>
             <p className="text-center">It's easier to stay on point when you have a plan!</p>
             <hr/>
            <Row className="mt-5">     
                    {buildUserWorkoutPlanSelection()}
                    {buildPremiumWorkoutSection()}
            </Row>
        </Container>
    )
}
