import { faBowlFood, faDrumSteelpan, faDrumstickBite, faDumbbell, faGear, faHome, faHouse, faUtensils } from '@fortawesome/free-solid-svg-icons';
import {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';



const showMap = ['/dashboard', '/account', '/workout-home', '/nutrition-home'];
export default function StickyFooter (){
    const navigate = useNavigate();
    const [show, setShow] = useState(() => true);

    useEffect(() => {
        setShow(showMap.includes(window.location.pathname));
    }, [
        window.location.href
    ]);

    if (!show) {
        return null;
    }

    return (
        <div className="karved-sticky-footer">
            <Button className="nav-button" variant="outline-light" onClick={() => navigate('/dashboard')}>
                <FontAwesomeIcon icon={faHouse} />
            </Button>
            {' '}
            <Button className='nav-button' variant="outline-light" onClick={() => navigate('/workout-home')}>
                <FontAwesomeIcon icon={faDumbbell} />
            </Button>
            {' '}
            
            <Button className="nav-button" variant="outline-light" onClick={() => navigate('/nutrition-home')}>
                <FontAwesomeIcon icon={faUtensils} />
            </Button>
            {' '}
            <Button className="nav-button" variant="outline-light" onClick={() => navigate('/account')}>
                <FontAwesomeIcon icon={faGear} />
            </Button>
        </div>
    )
}