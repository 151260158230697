import {useEffect, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import API from './helpers/api';
import {useDispatch} from 'react-redux';
import moment from 'moment';


export default function DetermineInitialRoute() {
    const dispatch = useDispatch();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const navigate = useNavigate();

    useEffect(() => {
        //GET THE USER TO THE RIGHT PLACE
        const loadUser = async() => {
            const user = await api.getUserData();
            if (!user){
              return  navigate('/access');
            }
            const userIntake = await api.getUserIntake();
            if (!userIntake){
             return    navigate('/user-intake');
            }
            if (!userIntake.equipment_access){
              return  navigate('/user-intake/workout')
            }

            if (!userIntake.nutrition_preferences){
              return  navigate('/user-intake/nutrition')
            }

            const userGoal = await api.getUserGoal();
            if (!userGoal){
               return navigate('/user-intake/first-goal')
            }
            
            if (!user.stripe_expires_on || moment().isAfter(moment(user.stripe_expires_on))){
              return  navigate('/user-payment');
            }

            return navigate('/dashboard');

           
        }
        loadUser();
    }, [])
    //INITIALLY JUST ACT AS A LOADING SCREEN FOR A MOBILE APP
    return (
        <div className="text-center" style={{position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', backgroundColor: 'Black'}}>
            <img className="img-fluid flashing-image-center-of-div " src="/logo512.png"/>
        </div>
    )
}