import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {useEffect, useState, useMemo} from 'react';
import API from '../../../helpers/api';
import {useSelector, useDispatch} from 'react-redux';
import moment from 'moment';
import MacroCalculator from '../../../helpers/macro-calculator';
import { useNavigate } from 'react-router-dom';

//THIS IS GOING TO QUIZ THE USER ABOUT THE PAST WEEK

export default function AdjustMacrosModal({close, report}){
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), []);
    const userIntake = useSelector(state => state.user.userIntake);
    const [quizAnswers, setQuizAnswers] = useState(() => ({
        mealsProperlyTracked: null,
        cheatMealsNotRecorded: null,
        mealsWereUnderReported: null,
    }));
    const navigate = useNavigate();

    const [variables, setVariables] = useState(() => ({
        age: null,
        currentWeight: null,
        fitnessLevel: null,
        height_in_inches: null,
        goal: null,
        protein_per_pound: .8,
        fats_percentage: 25,
        weight_change_per_week: 0
    }))

    const [suggestedMacros, setSuggestedMacros] = useState(() => ({
        calories: null,
        protein: null,
        fats: null,
        carbs: null,
    }));

    const macroAdjustments = useState(() => ({
        proteinPerPound: 0.8,
        fatsPercentage: 25
    }));

    const handleCalculationChangeBasedOnVariable = (newVariables) => {
       
        setSuggestedMacros(MacroCalculator({
            age: newVariables.age,
            currentWeight: newVariables.currentWeight,
            fitnessLevel: newVariables.fitnessLevel,
            gender: newVariables.gender,
            weekly_weight_change: newVariables.weight_change_per_week,
            height_in_inches: newVariables.height_in_inches,
            goal: newVariables.goal,
            protein_per_pound: newVariables.protein_per_pound,
            fats_percentage: newVariables.fats_percentage / 100,
        }));
    }

    const handleMacroChange = (key, value) => {
        const newMacros = {...suggestedMacros};
        newMacros[key] = value;
        const calories = Number(newMacros.protein * 4) + Number(newMacros.fats * 9) + Number(newMacros.carbs * 4);
        setSuggestedMacros({...newMacros, calories});
    }

    const handleCalculateInitialMacros = async () => {
        console.log(report);
        let fitnessLevel = 1;
        if (report.total_workouts >= 5){
            fitnessLevel++;
        }
        if (report.total_cardio_sessions >= 3 && report.total_cardio_duration >= 150){
            fitnessLevel++;
        }
        let intake = userIntake || await api.getUserIntake();
        console.log(intake)
        if (!intake) return;
        console.log(intake)

        const userGoal = await api.getUserGoal();
        let goal = 'lose';
        let weightChangePerWeek = 0;
        if (!userGoal){
            if (intake.target_weight < report.ending_week_weight){
                goal = 'lose';
                weightChangePerWeek = 1
            } else if (intake.target_weight > report.ending_week_weight){
                goal = 'gain';
                weightChangePerWeek = 1;
                
            }
        } else {
            goal = userGoal.goal;
            weightChangePerWeek = Number(userGoal.target_weight) / Math.abs(moment().diff(moment(userGoal.goal_date), 'weeks'));

            if (weightChangePerWeek > 2){
                weightChangePerWeek = 2; //Cap it at 2lbs per week to promote healthy weight loss
            }
            
        }

        const newMacros = MacroCalculator({
            age: report.current_age,
            currentWeight: report.ending_week_weight,
            fitnessLevel,
            height_in_inches: Number(intake.height_in_inches),
            gender: intake.gender,
            weekly_weight_change: weightChangePerWeek,
            goal,
        });

        setVariables({...variables, 
            age: report.current_age,
            currentWeight: report.ending_week_weight,
            fitnessLevel,
            height_in_inches: Number(intake.height_in_inches),
            goal,
            gender: intake.gender,
            weight_change_per_week: weightChangePerWeek,   
        });

        setSuggestedMacros(newMacros);
        console.log(newMacros);
    }

    useEffect(() => {
        if (!report) return;
        handleCalculateInitialMacros();
    }, [report, ])

    const [step, setStep] = useState(() => 1);
    const [isCalculating, setIsCalculating] = useState(() => true);

    let section = null;

    const handleVariableChange = (key, value) => {
        const newVariables = {...variables};
        newVariables[key] = value;
        setVariables({...newVariables});
        handleCalculationChangeBasedOnVariable(newVariables);
    }

    const gotoNext = () => {
        setIsCalculating(true);
        //THIS IS WHERE WE CALCULATE THE NEW MACRO SUGGESTIONS
        console.log("REPORT: ", report);
    }

    const updateMacros = async (evt) => {
        //TODO: ERROR HANDLING
        evt.preventDefault();
        console.log(suggestedMacros);
       
        await api.createUserDailyMacros({
            calories: suggestedMacros.calories,
            protein: suggestedMacros.protein,
            fat: suggestedMacros.fats,
            carbohydrate: suggestedMacros.carbs,
        });
        navigate('/dashboard');
    }

    if (step == 0){
        //THIS IS THE QUIZ ASKING THE END USER IF THEY TRAINED ALL WEEK
        section = (
            <div>
                <Form>
                <h6>Did you track ALL of your meals as accurately as possible?</h6>
                <Form.Check
                    type="radio"
                    label="Yes"
                    name="mealsProperlyTracked"
                    id="mealsProperlyTracked-yes"
                    onChange={() => setQuizAnswers({mealsProperlyTracked: true})}
                />
                <Form.Check
                    type="radio"
                    label="No"
                    name="mealsProperlyTracked"
                    id="mealsProperlyTracked-no"
                    onChange={() => setQuizAnswers({mealsProperlyTracked: false})}
                />
                </Form>

                {quizAnswers.mealsProperlyTracked !== null && (
                    <div>
                        <hr/>
                        <h6>Did you track your cheat meals?</h6>
                        <Form.Check
                            type="radio"
                            label="Yes"
                            name="cheatMealsNotRecorded"
                            id="cheatMealsNotRecorded-yes"
                            onChange={() => setQuizAnswers({cheatMealsNotRecorded: true})}
                        />
                        <Form.Check
                            type="radio"
                            label="No"
                            name="cheatMealsNotRecorded"
                            id="cheatMealsNotRecorded-no"
                            onChange={() => setQuizAnswers({cheatMealsNotRecorded: false})}
                        />
                    </div>
                )}
                {quizAnswers.cheatMealsNotRecorded !== null && (
                    <div>
                        <hr/>
                        <Button onClick={gotoNext}>Next</Button>
                    </div>
                )}
            </div>
        )
    } else if (step == 1){
        //THIS IS WHERE WE ASK THE USER TO ADJUST THEIR MACROS
        console.log(variables)
        section = (
            <div>
                <h6>Based on your activity this week, we suggest the following macro adjustments:</h6>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Protein Per Pound</Form.Label>
                            <Form.Control 
                                type="number"
                                min={20}
                                max={30}
                                step={.5}
                                value={variables.protein_per_pound}
                                onChange={(e) => handleVariableChange('protein_per_pound', e.target.valueAsNumber)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Fats Percentage</Form.Label>
                            <Form.Control 
                                type="number"
                                min={.5}
                                max={1.2}
                                step={.05}
                                value={`${variables.fats_percentage}`}
                                onChange={(e) => handleVariableChange('fats_percentage', e.target.valueAsNumber)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <hr/>
                <Form onSubmit={updateMacros}>
                <h6>Below are your macros for the coming week, feel free to change them as you feel fit.</h6>
                <Row>
                    <Form.Group>
                        <Form.Label>Calories</Form.Label>
                        <Form.Control 
                            type="number"
                            value={suggestedMacros.calories}
                            readOnly
                        />
                    </Form.Group>

                    
                </Row>

                <Row>
                    <Col>
                    <Form.Group>
                        <Form.Label>Protein</Form.Label>
                        <Form.Control 
                            type="number"
                            value={suggestedMacros.protein}
                            onChange={evt => handleMacroChange('protein', evt.target.valueAsNumber)}
                            required
                        />
                    </Form.Group>
                    </Col>

                    <Col>
                    <Form.Group>
                        <Form.Label>Fats</Form.Label>
                        <Form.Control 
                            type="number"
                            value={suggestedMacros.fats}
                            onChange={evt => handleMacroChange('fats', evt.target.valueAsNumber)}
                            required
                        />
                    </Form.Group>
                    </Col>

                    <Col>
                        <Form.Group>
                            <Form.Label>Carbs</Form.Label>
                            <Form.Control 
                                type="number"
                                value={suggestedMacros.carbs}
                                onChange={evt => handleMacroChange('carbs', evt.target.valueAsNumber)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <div className="text-center mt-5">
                    <Button type="submit">Update Macros and Keep getting KarvedUp!</Button>
                </div>
                </Form>
            </div>
        )
    }
    return (
        <Modal show={true} onHide={close} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Adjust Calories + Macros</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {section}
            </Modal.Body>
        </Modal>
    )
}