import {Container, Row, Col, Button, Accordion} from 'react-bootstrap';
import {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import MacroProgressBar from '../dashboard/charts/macro-progress-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faPencil } from '@fortawesome/free-solid-svg-icons';
import LogMealModal from '../dashboard/modals/log-meal-modal';

export default function NutritionDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const userDailyMacros = useSelector(state => state.user.userDailyMacros);
    const userDailyNutrientIntake = useSelector(state => state.user.userDailyNutrientIntake);
    const [showLogMealModal, setShowLogMealModal] = useState(() => false);
    const [selectedMeal, setSelectedMeal] = useState(() => null);  
    const [selectedMealIndex, setSelectedMealIndex] = useState(() => null);
    const [gotoId, setGotoId] = useState(() => null);

    const loadData = async () => {
        await Promise.all([
            api.getUserIntake(),
            api.getUserDailyMacros(),
            api.getUserDailyNutrientIntake(),
            api.getUserSavedMeals(),
            api.getUserMuscleRecipes(),
        ]);
    }

    const gotoIdElement = () => {
        if (gotoId) {
            const element = document.getElementById(gotoId);
            if (element) {
                element.scrollIntoView({behavior: 'smooth'});
                setGotoId(null);
            }
        }
    }

    const closeModal = () => {
        loadData();
        setShowLogMealModal(false);
        setSelectedMeal(null);
        setSelectedMealIndex(null);
        gotoIdElement();
    }
    
    const buildMacroArea = () => {
        if (!userDailyMacros || !userDailyNutrientIntake) return null;

        const dailyCalories = Number(userDailyMacros.calories);
        const dailyProtein = Number(userDailyMacros.protein);
        const dailyCarbs = Number(userDailyMacros.carbohydrate);
        const dailyFats = Number(userDailyMacros.fat);

        let consumedCalories = 0;
        let consumedProtein = 0;
        let consumedCarbs = 0;
        let consumedFats = 0;
        let mealCount = 0;
        if (userDailyNutrientIntake && userDailyNutrientIntake.meals){
            consumedCalories = Number(userDailyNutrientIntake.calories);
            consumedProtein = Number(userDailyNutrientIntake.protein);
            consumedCarbs = Number(userDailyNutrientIntake.carbohydrate);
            consumedFats = Number(userDailyNutrientIntake.fat);
            mealCount = userDailyNutrientIntake.meals.meals.length;
        }

        
        return (
            <Row className="karved-up-row text-center" id="macro-area">
                <h5>Daily Macro Consumption</h5>
                <hr/>
                <Accordion className="mb-3">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{mealCount} Meals Logged</Accordion.Header>
                        <Accordion.Body>
                            {userDailyNutrientIntake && userDailyNutrientIntake.meals && userDailyNutrientIntake.meals.meals.length && userDailyNutrientIntake.meals.meals.map ((meal, index) => {
                                //Moving to a Mobile Friendly Row... Tables just don't cut it
                                return (
                                    <Row className="karved-content-container">
                                        <Col xs={12} lg={6}>
                                            <h5>Meal #{index + 1}</h5>
                                            <Button onClick={() => {
                                                    setSelectedMeal(meal);
                                                    setSelectedMealIndex(index);
                                                    setGotoId('macro-area');
                                                    setShowLogMealModal(true);
                                                }}> <FontAwesomeIcon icon={faPencil} /> <br/> Edit Meal</Button>
                                            <hr/>
                                            <p>Calories: {meal.calories}</p>
                                            <p>Protein: {meal.protein}</p>
                                            <p>Carbs: {meal.carbohydrate}</p>
                                            <p>Fats: {meal.fat}</p>
                                            <hr className="mb-3"/>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <h5>Items</h5>
                                            <hr/>
                                            {meal.items.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item.name} <br/>
                                                        ({item.quantity} - {item.unit})
                                                        <hr/>
                                                    </div>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                )
                            })}

                                
                               
                            
                            
                        


                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className='mb-3'>
                <Button className="primary-btn" onClick={() => {
                    setShowLogMealModal(true)
                    setGotoId('macro-area')
                    }}><FontAwesomeIcon icon={faPlusCircle} /> Log Meal</Button>
                </div>
                <MacroProgressBar macroName="Calories" macroNumber={dailyCalories} macroConsumed={consumedCalories}/>
                <MacroProgressBar macroName="Protein" macroNumber={dailyProtein} macroConsumed={consumedProtein}/>
                <MacroProgressBar macroName="Carbs" macroNumber={dailyCarbs} macroConsumed={consumedCarbs}/>
                <MacroProgressBar macroName="Fats" macroNumber={dailyFats} macroConsumed={consumedFats}/>
            </Row>
        )
    }

    useEffect(() => {
        loadData();
        window.scrollTo(0, 0);
    }, []);
    let modal = null;
    if (showLogMealModal) {
        modal = <LogMealModal close={closeModal} previousMeal={selectedMeal} previousMealIndex={selectedMealIndex}/>
    }
    return (
        <Container className="karved-content-container">
            {modal}
            {buildMacroArea()}
        </Container>
    )
}