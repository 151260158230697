import { ProgressBar } from "react-bootstrap";

export default function CardioScoreCardBar({totalCardio = 0}){
    // Min Cardio is 150 minutes per week
    const percentage = totalCardio / 150 * 100;

    const constructVolumeText = (totalCardio) => {
        if (totalCardio === 0) return <p>No Cardio</p>
        if (totalCardio < 75) {
            return <p>Volume: Low Cardio</p>
        } else if (totalCardio < 150){
            return <p>Volume: <span>Moderate Cardio</span></p>
        } else if (totalCardio < 225){
            return <p>Volume: <span>Optimal Cardio</span></p>
        } else if (totalCardio <= 300){
            return <p>Volume: <span>Optimal Cardio</span></p>
        } else {
            return <p>Volume: <span>Potential Cardio Overload</span></p>
        }
    }

    const constructBackgroundColor = (totalCardio) => {
        if (totalCardio === 0) return 'danger';
        if (totalCardio < 75) return 'danger';
        if (totalCardio < 150) return 'warning';
        if (totalCardio < 225) return 'success';
        if (totalCardio <= 300) return 'success';
        return 'danger';
    }

    return (
        <div className="muscle-scorecard-bar">
            <h6>Weekly Cardio</h6>
            <ProgressBar
            variant={constructBackgroundColor(totalCardio)}
            animated now={percentage} label={`${totalCardio} minutes`} striped/>
            {constructVolumeText(totalCardio)}
        </div>
    )
}