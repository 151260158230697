import {Container, Row, Col, Button, Table} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {useEffect, useState, useMemo} from 'react';
import API from '../../helpers/api';
import {useNavigate} from 'react-router-dom';
import LogWorkoutModal from '../dashboard/modals/log-workout-modal';
import LogCardioModal from '../dashboard/modals/log-cardio-modal';
import LineChart from '../dashboard/charts/line-chart';
import MuscleScoreCard from '../workout-creator/shared/muscle-scorecard-bar';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faPlusCircle, faRunning } from '@fortawesome/free-solid-svg-icons';

export default function WorkoutDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [isLoading, setIsLoading] = useState(true);
    const user = useSelector(state => state.user.userData);
    const userActiveWorkoutPlan = useSelector(state => state.workout.user_active_workout_plan);
    const userCompletedWorkouts = useSelector(state => state.user.userCompletedWorkouts);
    const userCompletedCardioSessions = useSelector(state => state.user.userCompletedCardioSessions);
    const userWeeklyStats = useSelector(state => state.user.userWeeklyStats);
    const [showLogWorkoutModal, setShowLogWorkoutModal] = useState(() => false);
    const [showLogCardioModal, setShowLogCardioModal] = useState(() => false);
    const [selectedWorkout, setSelectedWorkout] = useState(() => null);
    const [gotoId, setGotoId] = useState(() => null);
    const [selectedCardioSession, setSelectedCardioSession] = useState(() => null);
    const [selectedPlannedWorkout, setSelectedPlannedWorkout] = useState(() => null);
    const [selectedPlannedCardioSession, setSelectedPlannedCardioSession] = useState(() => null);

    const loadData = async () => {
        try {
            await Promise.all([
                api.getUserIntake(),
                api.getUserDailyCheckin(),
                api.getUserCompleteWorkoutsFromLastSevenDays(),
                api.getUserCompleteWorkoutsByDate(moment().format('YYYY-MM-DD')),
                api.getUserActiveWorkoutPlan(),
                api.getUserCardioWorkouts(moment().format('YYYY-MM-DD')),
                api.getAllExercises(),
                
            ]);
            setIsLoading(false);
        } catch(err) {
            console.log(err)
        }
    }

    const gotoIdElement = () => {
        if (gotoId){
            const element = document.getElementById(gotoId);
            if (element){
                element.scrollIntoView({behavior: 'smooth'});
                setGotoId(null);
            }
        }
    }

    const closeModal = () => {
        setShowLogWorkoutModal(false);
        setShowLogCardioModal(false);
        loadData();
        gotoIdElement();
    }

    useEffect(() => {
        loadData();
    }, []);

    const buildWorkoutArea = () => {
        

        if (!userCompletedWorkouts || !userCompletedWorkouts.length){
            return (
                <Row className="karved-up-row" id="workout-area">
                    <h3>No workouts logged today</h3>
                    <Button onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId("workout-area")}}>Log Workout</Button>
                    
                </Row>
            )
        }
        const totalWorkouts = userCompletedWorkouts.length;
        const totalVolume   = userCompletedWorkouts.reduce((acc, workout) => {
            return acc + workout.workout_data.totalVolume;
        }, 0);
        const totalSets = userCompletedWorkouts.reduce((acc, workout) => {
            return acc + workout.workout_data.totalWorkoutSetCount;
        }, 0)
        const musclesTrainedMap = {};
        userCompletedWorkouts.forEach(workout => {
            for (let muscle in workout.workout_data.muscleSetMap){
                if (musclesTrainedMap[muscle]){
                    musclesTrainedMap[muscle] += workout.workout_data.muscleSetMap[muscle];
                } else {
                    musclesTrainedMap[muscle] = workout.workout_data.muscleSetMap[muscle];
                }
            }

        })
        return (
            <Row className="karved-up-row" id="workout-area">
                <Col xs={12}>
                    <h5 classname="text-center">Today's Workout Stats</h5>
                    <hr/>
                    <h6>Total Workouts: {totalWorkouts}</h6>
                    <h6>Total Volume: {totalVolume}lbs</h6>
                    <h6>Total Sets: {totalSets}</h6>
                    <Button onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId('workout-area')
                        }}>Log Another Workout</Button>
                    <Table className="mt-5" responsive variant="dark">
                        <thead>
                            <tr>
                                <th>Workout</th>
                                <th>Total Sets</th>
                                <th>Total Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                    {userCompletedWorkouts.map((workout, index) => {
                        return (
                            <tr key={index} onClick={() => {
                                setSelectedWorkout(workout);
                                setGotoId('workout-area');
                                setShowLogWorkoutModal(true);
                            }}>
                                <td>{workout.workout_data.workoutName}</td>
                                <td>{workout.workout_data.totalWorkoutSetCount}</td>
                                <td>{workout.workout_data.totalVolume}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    </Table>
                </Col>
                
                <Col xs={12}>
                    <h2>Muscles Trained</h2>
                    <Table variant='dark' responsive>
                        <thead>
                            <tr>
                                <th>Muscle</th>
                                <th>Sets</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(musclesTrainedMap).sort(
                            (a, b) => musclesTrainedMap[b] - musclesTrainedMap[a]
                        ).map((muscle, index) => {
                            return (
                                <tr key={index}>
                                    <td>{muscle}</td>
                                    <td>{musclesTrainedMap[muscle]} sets</td>
                                </tr>
                            )
                            //return <li key={muscle}>{muscle}: {musclesTrainedMap[muscle]} sets</li>
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    const buildCheckinArea = () => {
        const workoutFontAwesome = <FontAwesomeIcon icon={faDumbbell} />
        const createWorkoutFontAwesome = <FontAwesomeIcon icon={faPlusCircle} />
        const cardioFontAwesome = <FontAwesomeIcon icon={faRunning} />
        const freeWorkoutFontAwesome = <FontAwesomeIcon icon={faDumbbell} />
        let checkinCol = null;
        let workoutCol = null;
        let cardioCol = null;
        let freeStyleCol = (
             <Col className="text-center border-solid-row p-5"xs={12}>
                    <h5>Go with the flow</h5>
                    <p>Just want to do your own thing? We got you covered.</p>
                    <Button className="primary-btn" onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId('workout-area')
                        }}>{freeWorkoutFontAwesome}Log Workout</Button><br/>
                    <Button className='primary-btn mt-2' onClick={() => {
                        setShowLogCardioModal(true)
                        setGotoId('cardio-area')
                        }}>{cardioFontAwesome} Log Cardio Session</Button>
                </Col>
        )

        
        if (userActiveWorkoutPlan) {
            const dayTitle = moment().format('dddd').toLowerCase();
            const workoutDay = userActiveWorkoutPlan.workout_plan_data.days.find(day => day.day.toLowerCase() == dayTitle);
            
            if (workoutDay){
                cardioCol = (
                    <div className="text-center border-solid-row p-5" >
                        {
                            !workoutDay.cardioSessions.length ? (
                                <div>
                                    <p>No cardio sessions scheduled for today</p>
                                    <Button onClick={() => {
                                        setShowLogCardioModal(true);
                                        setGotoId('cardio-area');
                                    }}>Log Cardio Session</Button>
                                </div>
                            ): (
                                <div>
                                    {workoutDay.cardioSessions.map((cardio, index) => {
                                        const completedCardioSession = userCompletedCardioSessions.find(c => c.cardio_data.cardioWorkoutName == cardio.cardioWorkoutName && c.cardio_data.cardioDescription == cardio.cardioDescription); 
                                        console.log(completedCardioSession);
                                        console.log(cardio);
                                        let cardioButton = (
                                            <Button onClick={() => {
                                                setSelectedPlannedCardioSession(cardio);
                                                setShowLogCardioModal(true);
                                                setGotoId('cardio-area');
                                            }}>Begin Cardio Session</Button>
                                        );
                                        if (completedCardioSession){
                                            console.log("Building Herer")
                                            cardioButton = (
                                                <Button onClick={() => {
                                                    setSelectedCardioSession(completedCardioSession);
                                                    setShowLogCardioModal(true);
                                                    setGotoId('cardio-area');
                                                }}>Continue/Edit Cardio Session</Button>
                                            )
                                        }
                                        return (
                                            <div key={index}>
                                                <h5>Session {index + 1}</h5>
                                                <ul>
                                                    {cardio.activities.map((activity, index) => {
                                                        return (
                                                            <li key={index}>
                                                                {activity.activity} - {activity.duration} minutes
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <p>{cardio.activities.reduce((a, c) => a + Number(c.duration || 0), 0)} minutes</p>
                                                {cardioButton}
                                            </div>
                                        )
                                    })}
                                    </div>
                            )
                        }
                    </div>
            )
                workoutCol = (
                    <Col className="border-solid-row  mb-5" xs={12}>
                        <h5 className="text-center">Today's Training</h5>
                        <hr/>
                        {
                            !workoutDay.workouts.length ? (
                                <div className="text-center border-solid-row">
                                    <h5>No workouts scheduled for today</h5>
                                    <Button onClick={
                                        () => {
                                            setShowLogWorkoutModal(true);
                                            setGotoId('workout-area');
                                        }
                                    }>Log Workout</Button>
                                </div>
                            ): (
                                <div>
                                    {workoutDay.workouts.map((workout, index) => {
                                        let workoutButton = (
                                            <Button onClick={
                                                () => {
                                                    setSelectedPlannedWorkout(workout);
                                                    setShowLogWorkoutModal(true);
                                                    setGotoId('workout-area');
                                                }
                                            }>
                                                <FontAwesomeIcon icon={faDumbbell} /><br/>
                                                Begin Workout
                                            </Button>
                                        );
                                        
                                        const completedWorkout = userCompletedWorkouts.find(
                                            completed => (completed.workout_data.workoutName == workout.workoutName) && (completed.workout_data.workoutDescription == workout.workoutDescription)
                                        );

                                        if (completedWorkout){
                                            workoutButton = (
                                                <Button onClick={() => {
                                                    setSelectedWorkout(completedWorkout);
                                                    setShowLogWorkoutModal(true);
                                                    setGotoId('workout-area');
                                                }}>Continue/Edit Workout</Button>
                                            )
                                        }
                                        return (
                                            <div key={index} className="border-solid-row text-center">
                                                <h5>{workout.workoutName}</h5>
                                                <p>{workout.totalWorkoutSets} sets</p>
                                                {workoutButton}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        {cardioCol}
                    </Col>
                )

                
        
        }
        } else {
           
            workoutCol = (
                <Col className="text-center border-solid-row p-5" xs={12}>
                    <h5>You don't have a Workout Plan!</h5>
                    <p>Having a workout plan will help you stay on track</p>
                    <Button className="primary-btn" onClick={() => navigate('/select-workout-plan')}>{workoutFontAwesome} Select a Workout Plan</Button><br/>
                    <Button className="primary-btn mt-2" onClick={() => navigate('/workout-planner')}>{createWorkoutFontAwesome} Create a Workout Plan</Button>
                </Col>
            )

            freeStyleCol = (
                <Col className="text-center border-solid-row p-5"xs={12} >
                    <h5>Go with the flow</h5>
                    <p>Just want to do your own thing? We got you covered.</p>
                    <Button className="primary-btn" onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId('workout-area')
                        }}>{freeWorkoutFontAwesome}Log Workout</Button><br/>
                    <Button className='primary-btn mt-2' onClick={() => {
                        setShowLogCardioModal(true)
                        setGotoId('cardio-area')
                        }}>{cardioFontAwesome} Log Cardio Session</Button>
                </Col>
            )
        }

       
       
        return (
            <div>
                
                <Row className='karved-up-row'>
                    {checkinCol}
                    {workoutCol}
                </Row>
                
            </div>
            
        )
    }


    const buildTodaysTrainingVolume = () => {
        const dayTitle = moment().format('dddd');
        let trainingSection = (
            <div>
                <h4 className="text-center">You haven't trained today!</h4>
            </div>
        )
        if (userActiveWorkoutPlan === null) {
            trainingSection = (
                <div className="border-solid-row">
                    <h4 className="text-center">You don't have an active workout plan!</h4>
                </div>
            )
        }
       
        
        return (
            <div className="border-solid-row">
                {buildCheckinArea()}
                
                <hr/>
                {buildWorkoutArea()}
                {buildCardioArea()}
            </div>
        )
    }

    const buildWeeklyTrainingVolume = () => {
        console.log(userWeeklyStats)

        const lineChart = {
            labels: Object.keys(userWeeklyStats.workoutDateDataVolumeMap).map(d => moment(d).format('MM/DD')),
            datasets: [
                {
                    label: 'Volume',
                    data: Object.values(userWeeklyStats.workoutDateDataVolumeMap),
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 2,
                    fill: false,
                    
                }
            ]
        }

        return (
            <div className="border-solid-row">
                <h4 className="text-center">Weekly Training Volume</h4>
                <p className="text-center">Your weekly muscle stimulous, remember 10-20 sets per week offers the best stimulous for muscle growth.</p>
                <hr/>
               
                <LineChart data={lineChart} options={{responsive: true}} />
                <div className="border-solid-row">
                <h4 className="text-center">Total Workouts</h4>
                <h6 className="text-center"> {userWeeklyStats.totalWeeklyWorkouts} workouts</h6>
                <hr/>
                <h4 className="text-center mt-2">Total Pounds Lifted This Week</h4>
                <h6 className="text-center"> {userWeeklyStats.totalWeeklyVolume} (lbs)</h6>
                <hr/>
                <h4 className="text-center mt-2">Total Sets</h4>
                <h6 className="text-center"> {userWeeklyStats.totalWeeklySets} sets</h6>
                <hr/>
                <h4 className="text-center mt-2">Average Sets Per Workout</h4>
                <h6 className="text-center"> {userWeeklyStats.averageSetsPerWorkout} sets</h6>
                <hr/>
                <h4 className="text-center mt-2">Average Volume Per Workout</h4>
                <h6 className="text-center"> {userWeeklyStats.averageVolumePerWorkout} (lbs)</h6>
                <hr/>
                <h4 className="text-center mt-2">Muscle Volume</h4>
                <p className="text-center">Sets per muscle group, over the past 7 days.</p>
                <Row>
                {Object.keys(userWeeklyStats.muscleVolumeMap).map(muscle => {
                    return(
                        <Col xs={12} lg={6}>
                    
                            <MuscleScoreCard muscleName={muscle} muscleSets={userWeeklyStats.muscleVolumeMap[muscle]} />
                        
                        </Col>
                    )
                })}
                </Row>
                </div>
            </div>
        )
    }

    const buildCardioArea = () => {
        if (!userCompletedCardioSessions || !userCompletedCardioSessions.length){
            return (
                <Row className="karved-up-row">
                    <h3>No cardio sessions logged today</h3>
                    <Button onClick={() => {
                        setShowLogCardioModal(true)
                        setGotoId('cardio-area')
                        }}>Log Cardio</Button>
                </Row>
            )
        }

        return (
            <Row className="karved-up-row" id={'cardio-area'}>
                <h3>Today's Cardio Sessions</h3>
                <Table className="mt-5" responsive variant="dark">
                    <thead>
                        <tr>
                            <th>Cardio Workout</th>
                            <th>Total Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userCompletedCardioSessions.map((cardio, index) => {
                            return (
                                <tr key={index} onClick={() => {
                                    setSelectedCardioSession(cardio);
                                    setShowLogCardioModal(true);
                                    setGotoId('cardio-area');
                                }}>
                                    <td>{cardio.cardio_data.cardioWorkoutName}</td>
                                    <td>{cardio.cardio_data.totalDuration} minutes</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Row>
        )
    }

    useEffect(() => {
        //Scroll TO Top
        window.scrollTo(0, 0);
    }, [])
    let modal = null;
    if (showLogWorkoutModal){
        modal = <LogWorkoutModal close={closeModal} workout={selectedWorkout} preplannedWorkout={selectedPlannedWorkout}/>
    } else if (showLogCardioModal){
        modal = <LogCardioModal close={closeModal} cardioSession={selectedCardioSession} plannedCardioSession={selectedPlannedCardioSession} />
    }
    return (
        <Container className="karved-content-container">
            {modal}
            <Row>
                <Col xs={12}>
                    <h1 className="text-center">Workout Dashboard</h1>
                    <hr/>
                </Col>
                <Col xs={12} lg={6} className="mt-5">
                    {buildTodaysTrainingVolume()}
                </Col>
                <Col xs={12} lg={6} className="mt-5">
                    {buildWeeklyTrainingVolume()}
                </Col>
            </Row>
        </Container>
    )
}