import {useState, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import API from '../../helpers/api';
import {Row, Col, Form, Button, Container} from 'react-bootstrap'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

export default function ForgotPassword() {
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const [email, setEmail] = useState(() => '');
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const [success, setSuccess] = useState(() => false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const didSucceed = await api.triggerForgotPassword({email_address: email});
        setIsSubmitting(false);
        if(didSucceed){
            setSuccess(true);
        }
    }

    return (
        <Container className="karved-content-container">
            <Row>
                <Col>
                    <h5>Forgot Password?</h5>
                    <p>Enter your email address and we'll send you a link to reset your password.</p>
                    <hr/>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="email">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type="email" 
                                          value={email} 
                                          isInvalid={!email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)}
                                          onChange={(e) => setEmail(e.target.value)} 
                                          required/>
                        </Form.Group>
                        <div className="mt-3 text-center">
                            <Button type="submit" disabled={isSubmitting}>
                                <FontAwesomeIcon icon={faEnvelope} className="mr-2"/><br/>
                                Send Reset Link
                            </Button>
                        </div>
                       
                    </Form>
                    {success && <p>Email sent. Please check your inbox.</p>}
                </Col>
            </Row>
        </Container>
    )
}