export default function MacroCalculator(
    {
    age,
    currentWeight,
    fitnessLevel,
    height_in_inches,
    gender,
    weekly_weight_change,
    goal,
    protein_per_pound = 0.8,
    fats_percentage   = .25,
    }
){
    // First Calculate BMR

    let bmr = 0;
    let calories = 0;
    let protein = 0;
    let fats = 0;
    let carbs = 0;

    if (gender == 'male'){
        bmr = 66 + (6.23 * currentWeight) + (12.7 * height_in_inches) - (6.8 * age);
    } else {
        bmr = 655 + (4.35 * currentWeight) + (4.7 * height_in_inches) - (4.7 * age);
    }

    // Next Calculate TDEE
    let tdee = 0;
    switch(fitnessLevel){
        case 1:
            tdee = bmr * 1.2;
            break;
        case 2:
            tdee = bmr * 1.55;
            break;
        case 3:
            tdee = bmr * 1.725;
            break;
        default:
            tdee = bmr * 1.2;
            break;
    }

    // Calculate Calories
    if (goal == 'lose'){
        calories = tdee - (500 * Number(weekly_weight_change));
    } else {
        calories = tdee + (500 * Number(weekly_weight_change));
    }
    calories = Math.floor(calories);
    // Calculate Macros
    protein = Math.ceil(Number(currentWeight) * Number(protein_per_pound));
    console.log(protein_per_pound);
    fats = Math.ceil(calories * Number(fats_percentage) / 9);
    
    carbs = Math.ceil((calories - (protein * 4) - (fats * 9)) / 4);


    return {
        calories,
        protein,
        fats,
        carbs
    }
    
}