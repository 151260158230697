//THIS COMPONENT IS TO SHOW OFF THE USER'S PROGRESS FOR THE PAST WEEK AND TO OFFER MACRO SUGGESTIONS
import {useState, useEffect, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Container, Row, Col, Button} from 'react-bootstrap';
import API from '../../helpers/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faFaceFrown, faFaceSmile, faFaceMeh } from '@fortawesome/free-solid-svg-icons';
import MacroProgressBar from '../dashboard/charts/macro-progress-bar';
import MuscleScorecardBar from '../workout-creator/shared/muscle-scorecard-bar';
import AdjustMacrosModal from './modals/adjust-macros-modal';


export default function WeeklyReportCard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [loading, setLoading] = useState(() => true);
    const [reportCard, setReportCard] = useState(() => null);
    const [showModal, setShowModal] = useState(() => false);

    const handleReportCardGeneration = async () => {
        //Check if today is sunday
        const day = moment().format('dddd').toLowerCase();
        if (day !== 'sunday') {
            setLoading(false);
            return;
        }

        const rc = await api.getUserWeeklyReportCard(moment().format('YYYY-MM-DD'));
        if (rc){
            setReportCard(rc);
            setLoading(false);
            return;
        }

        const newReportCard = {
            report_card_date: moment().format('YYYY-MM-DD'),
            report_card_data: {
                
            }
        };

        //GET THE CURRENT WEIGHT AND THE MEDIAN WEIGHT FOR THE PAST 7 DAYS
        const checkins = await api.getUserDailyCheckinByDateRange(7);
        const userGoal = await api.getUserGoal();
        const userIntake = await api.getUserIntake();
       
        let targetWeight = userGoal && userGoal.target_weight ? userGoal.target_weight : userIntake.target_weight;
   
        if (userGoal) {
            newReportCard.report_card_data.goal = userGoal;
        }
        newReportCard.report_card_data.current_age          = moment().diff(userIntake.dob, 'years');
        newReportCard.report_card_data.checkin_count        = checkins.length;
        newReportCard.report_card_data.starting_week_weight = Number(checkins[0].weight);
        newReportCard.report_card_data.ending_week_weight   = Number(checkins[checkins.length - 1].weight);
        newReportCard.report_card_data.average_week_weight  = (checkins.reduce((acc, curr) => acc + Number(curr.weight), 0) / checkins.length).toFixed(2);
        newReportCard.report_card_data.target_weight        = Number(targetWeight);
        newReportCard.report_card_data.weight_change        = (newReportCard.report_card_data.ending_week_weight - newReportCard.report_card_data.starting_week_weight).toFixed(2);
        newReportCard.report_card_data.weight_to_goal       = Number(newReportCard.report_card_data.target_weight - newReportCard.report_card_data.ending_week_weight).toFixed(2);
        
        //GET THE AVERAGE MACROS OVER THE PAST 7 DAYS
        const userNutrientIntakes = await api.getUserDailyNutrientIntakeByRateRange(7);
        let macroMap = {
            calories: 0,
            fat: 0,
            carbohydrate: 0,
            protein: 0
        }
        userNutrientIntakes.forEach(nutrient => {
            macroMap.calories += Number(nutrient.calories);
            macroMap.fat += Number(nutrient.fat);
            macroMap.carbohydrate += Number(nutrient.carbohydrate);
            macroMap.protein += Number(nutrient.protein);
        });

        newReportCard.report_card_data.average_calories_consumed = (macroMap.calories / userNutrientIntakes.length).toFixed(2);
        newReportCard.report_card_data.average_fat_consumed = (macroMap.fat / userNutrientIntakes.length).toFixed(2);
        newReportCard.report_card_data.average_carbohydrate_consumed = (macroMap.carbohydrate / userNutrientIntakes.length).toFixed(2);
        newReportCard.report_card_data.average_protein_consumed = (macroMap.protein / userNutrientIntakes.length).toFixed(2);
        
        const userDailyMacros = await api.getUserDailyMacros();
        console.log(userDailyMacros);

        newReportCard.report_card_data.average_calories_goal = Number(userDailyMacros.calories);
        newReportCard.report_card_data.average_fat_goal = Number(userDailyMacros.fat);
        newReportCard.report_card_data.average_carbohydrate_goal = Number(userDailyMacros.carbohydrate);
        newReportCard.report_card_data.average_protein_goal = Number(userDailyMacros.protein);

        //DIFFERENCES IN MACROS
        newReportCard.report_card_data.calories_difference = (newReportCard.report_card_data.average_calories_consumed - newReportCard.report_card_data.average_calories_goal).toFixed(2);
        newReportCard.report_card_data.fat_difference = (newReportCard.report_card_data.average_fat_consumed - newReportCard.report_card_data.average_fat_goal).toFixed(2);
        newReportCard.report_card_data.carbohydrate_difference = (newReportCard.report_card_data.average_carbohydrate_consumed - newReportCard.report_card_data.average_carbohydrate_goal).toFixed(2);
        newReportCard.report_card_data.protein_difference = (newReportCard.report_card_data.average_protein_consumed - newReportCard.report_card_data.average_protein_goal).toFixed(2);

        //WORKOUT AND CARDIO DATA  
        const workouts = await api.getUserCompletedWorkoutsByDateRange(7);
        const cardio = await api.getUserCompletedCardioByDateRange(7);

        let totalWorkouts = 0;
        let totalCardioSessions = 0;
        let totalWeeklyVolume = 0;
        let totalSetCount = 0;
        let averageSetCount = 0;
        let totalCardioDuration = 0;
        let averageWorkoutVolume = 0;
        let muscleGroupCompleteSetsMap = {};



        workouts.forEach(workout => {
            //Skip if workout_data is null
            if (!workout.workout_data) return;
            let workoutData = workout.workout_data;

            if (workoutData.totalVolume > 0) totalWorkouts++; //ONLY COUNT WORKOUTS WITH LEGIT VOLUME
            totalWeeklyVolume += Number(workoutData.totalVolume);

            
            workoutData.exercises.forEach(exercise => {
                
                totalSetCount +=  exercise.sets.reduce((acc, c) => {
                    if (!c.weight || !c.reps) return acc;
                    if (Number(c.weight) > 0 && Number(c.reps) > 0){
                        let count = 1;
                        if (c.setType && c.setType != 'standard') {
                            if (c.setMetadata && c.setMetadata.reps && c.setMetadata.reps > 0) count = 2
                        }
                        return acc + count;
                    } else {
                        return acc;
                    }
                }, 0)

                exercise.sets.forEach(set => {
                    if (set.weight && set.reps){
                        exercise.primaryMuscles.forEach(muscle => {
                            if (!muscleGroupCompleteSetsMap[muscle]) muscleGroupCompleteSetsMap[muscle] = 0;
                            muscleGroupCompleteSetsMap[muscle]++;

                            if (set.setType === 'dropset') muscleGroupCompleteSetsMap[muscle]++;
                        })
                        exercise.secondaryMuscles.forEach(muscle => {
                            if (!muscleGroupCompleteSetsMap[muscle]) muscleGroupCompleteSetsMap[muscle] = 0;
                            muscleGroupCompleteSetsMap[muscle] += .5;
                            if (set.setType === 'dropset') muscleGroupCompleteSetsMap[muscle] += .5;
                        })
                    }

                    if (set.setType == 'superset' && set.setMetadata){
                        if (set.setMetadata.reps && set.setMetadata.weight){
                            set.setMetadata.supersetExercisePrimaryMuscles.forEach(muscle => {
                                if (!muscleGroupCompleteSetsMap[muscle]) muscleGroupCompleteSetsMap[muscle] = 0;
                                muscleGroupCompleteSetsMap[muscle]++;
                            })
                            set.setMetadata.supersetExerciseSecondaryMuscles.forEach(muscle => {
                                if (!muscleGroupCompleteSetsMap[muscle]) muscleGroupCompleteSetsMap[muscle] = 0;
                                muscleGroupCompleteSetsMap[muscle] += .5;
                            })
                        }
                    }

                    
                })

            })
            
        })

        averageSetCount      = Math.ceil(totalSetCount / totalWorkouts);
        averageWorkoutVolume = Math.ceil(totalWeeklyVolume / totalWorkouts);


        cardio.forEach(session => {
            totalCardioDuration += session.cardio_data.totalDuration;
            totalCardioSessions++
        });

        newReportCard.report_card_data.total_workouts = totalWorkouts;
        newReportCard.report_card_data.total_cardio_sessions = totalCardioSessions;
        newReportCard.report_card_data.total_weekly_volume = totalWeeklyVolume;
        newReportCard.report_card_data.total_set_count = totalSetCount;
        newReportCard.report_card_data.average_set_count = averageSetCount;
        newReportCard.report_card_data.total_cardio_duration = totalCardioDuration;
        newReportCard.report_card_data.average_workout_volume = averageWorkoutVolume;
        newReportCard.report_card_data.muscle_group_complete_sets_map = muscleGroupCompleteSetsMap;

        console.log(newReportCard);
        setReportCard(newReportCard);
        setLoading(false);
        //TODO: Save To Database AFTER we validate styles
        await api.upsertUserReportCard(newReportCard);
    }

    const buildWeightProgressRow = () => {
        if (!reportCard || loading) return null;

        //FIRST LET'S SEE IF THE TARGET WEIGHT IS GREATER THAN OR LESS THAN CURRENT WEIGHT
        let weightChange = reportCard.report_card_data.weight_change;
        let weightToGoal = reportCard.report_card_data.weight_to_goal;
        let targetWeight = reportCard.report_card_data.target_weight;
        let endOfWeekWeight = reportCard.report_card_data.ending_week_weight;
        let weightChangeCarretColor = 'green';
        //CARET LOGIC... IF THEY LOSE WEIGHT DOWN, IF THEY GAIN WEIGHT UP
        let carretStyle = weightChange > 0 ? faCaretUp : faCaretDown;
        
        //IF A USER WANTS TO LOSE WEIGHT AND THEY GAINED WEIGHT
        if (endOfWeekWeight > targetWeight && weightChange > 0) weightChangeCarretColor = 'red';

        //IF A USER WANTS TO GAIN WEIGHT AND THEY LOST WEIGHT
        if (endOfWeekWeight < targetWeight && weightChange < 0) weightChangeCarretColor = 'red';
        
        let weightChangeCarret = (
            <FontAwesomeIcon icon={carretStyle} color={weightChangeCarretColor} />
        )

        let smileyFaceIcon = faFaceSmile;

        if (endOfWeekWeight > targetWeight && weightChange >= 0) smileyFaceIcon = faFaceMeh;
        if (endOfWeekWeight < targetWeight && weightChange <= 0) smileyFaceIcon = faFaceMeh;
        
        //IF THEY GAINED WEIGHT OR LOST WEIGHT WHEN THEY DIDN'T WANT TO FROWNY FACE
        if (endOfWeekWeight > targetWeight && weightChange >= 2) smileyFaceIcon = faFaceFrown;
        if (endOfWeekWeight < targetWeight && weightChange <= 2) smileyFaceIcon = faFaceFrown;

        

        return (
            <Row>
                <Col className="text-center border-solid-row">
                    <h3>End of Week Weight</h3>
                    <h5>{endOfWeekWeight}lbs</h5>
                    <h6>{weightChangeCarret} {weightChange}lbs</h6>
                </Col>
                <Col className="text-center border-solid-row">
                    <h3>Target Weight</h3>
                    <h5>{targetWeight}lbs</h5>
                    <h6>Only {Math.abs(weightToGoal)}lbs away from your goal!</h6>
                </Col>
                <Col className="text-center border-solid-row" >
                    <h3>Grade</h3>
                    <FontAwesomeIcon icon={smileyFaceIcon} size="3x" color='yellow'/>
                </Col>
            </Row>
        )
    }

    const buildMacroProgressRow = () => {
        if (!reportCard || loading) return null;
        
        let percentageMap = {
            calories: Number(reportCard.report_card_data.average_calories_consumed / reportCard.report_card_data.average_calories_goal) * 100,
            protein: Number(reportCard.report_card_data.average_protein_consumed / reportCard.report_card_data.average_protein_goal) * 100,
            carbohydrate: Number(reportCard.report_card_data.average_carbohydrate_consumed / reportCard.report_card_data.average_carbohydrate_goal) * 100,
            fat: Number(reportCard.report_card_data.average_fat_consumed / reportCard.report_card_data.average_fat_goal) * 100
        }

        const totalAveragePercentage = Number(Object.values(percentageMap).reduce((acc, curr) => acc + curr, 0) / 4);
        
        let macroTitle = 'Average Daily Macro Consumption';
        let macroGrade = faFaceSmile;

        if (totalAveragePercentage < 70) macroGrade = faFaceFrown;
        let gradeText = `You hit ${totalAveragePercentage.toFixed(2)}% of your target macros`;
        let calorieBreakdownText = 'You are currently eating a Maintenance amount of calories';
        const calorieToLbsConversionDaily = Number(500 * Math.abs(reportCard.report_card_data.weight_change)).toFixed(2);
        if (reportCard.report_card_data.weight_change > 0) {
            calorieBreakdownText = `You are currently in a caloric surplus of ${calorieToLbsConversionDaily} calories per day`;
        } else if (reportCard.report_card_data.weight_change < 0) {
            calorieBreakdownText = `You are currently in a caloric deficit of ${calorieToLbsConversionDaily} calories per day`;
        }
        
        return (
            <Row>
                <Col className="text-center border-solid-row">
                    <h3>Average Daily Macro Consumption</h3>
                    <MacroProgressBar macroName='Calories' macroNumber={reportCard.report_card_data.average_calories_goal} macroConsumed={reportCard.report_card_data.average_calories_consumed}/>
                    <MacroProgressBar macroName='Protein' macroNumber={reportCard.report_card_data.average_protein_goal} macroConsumed={reportCard.report_card_data.average_protein_consumed}/>
                    <MacroProgressBar macroName='Carbs' macroNumber={reportCard.report_card_data.average_carbohydrate_goal} macroConsumed={reportCard.report_card_data.average_carbohydrate_consumed}/>
                    <MacroProgressBar macroName='Fats' macroNumber={reportCard.report_card_data.average_fat_goal} macroConsumed={reportCard.report_card_data.average_fat_consumed}/>
                </Col>
                <Col className="text-center border-solid-row">
                    <h3>Calorie Breakdown</h3>
                    <hr/>
                    <h5>{calorieBreakdownText}</h5>
                </Col>
                <Col className='text-center border-solid-row'>
                    <h3>Grade</h3>
                    <FontAwesomeIcon icon={macroGrade} size="3x" color='yellow'/>
                    <hr/>

                    <p>{gradeText}</p>
                    <p>Putting you within {Number(100 - totalAveragePercentage).toFixed(2)} of your target macros!</p>
                </Col>
            </Row>
        )
    }

    const buildWorkoutProgressRow = () => {
        if (!reportCard || loading) return null;
        let smileyFaceIcon = faFaceSmile;
        let trainingFrequencyGradeSmile = faFaceSmile;

        let AverageMuscleSetCount = Object.keys(reportCard.report_card_data.muscle_group_complete_sets_map).reduce((acc, curr) => {
            return acc + reportCard.report_card_data.muscle_group_complete_sets_map[curr];
        }, 0) / Object.keys(reportCard.report_card_data.muscle_group_complete_sets_map).length;
       
        if (reportCard.report_card_data.total_workouts < 3) trainingFrequencyGradeSmile = faFaceFrown;
        if (AverageMuscleSetCount < 5) trainingFrequencyGradeSmile = faFaceFrown;

        return (
            <Row>
                <Col className="border-solid-row">
                    <h3>Total Workouts</h3>
                    <h5>{reportCard.report_card_data.total_workouts}</h5>
                    <hr/>
                
              
                    <h3>Total Weekly Volume</h3>
                    <h5>{addCommasToNumber(reportCard.report_card_data.total_weekly_volume)}lbs</h5>
                    <hr/>
            
                    <h3>Average Set Count</h3>
                    <h5>{reportCard.report_card_data.average_set_count}</h5>
                    <hr/>
              
                    <h3>Average Workout Volume</h3>
                    <h5>{addCommasToNumber(reportCard.report_card_data.average_workout_volume)}lbs</h5>
                </Col>
                <Col className="border-solid-row">
                    <h3>Muscle Stimulous Breakdown</h3>
                    {
                        Object.keys(reportCard.report_card_data.muscle_group_complete_sets_map)
                        .sort((a, b) => {
                            return reportCard.report_card_data.muscle_group_complete_sets_map[b] - reportCard.report_card_data.muscle_group_complete_sets_map[a]
                        }).map((muscle, index) => {
                            return (
                                <MuscleScorecardBar key={index} muscleName={muscle} muscleSets={reportCard.report_card_data.muscle_group_complete_sets_map[muscle]}/>
                            )
                        })
                    }
                </Col>

                <Col className='text-center border-solid-row'>
                    <h3>Grade</h3>
                    <hr/>
                    <div>
                        <FontAwesomeIcon icon={trainingFrequencyGradeSmile} size="3x" color='yellow'/>
                        <p>Training Frequency</p>
                    </div>
                    <hr/>
                    <div>
                        <FontAwesomeIcon icon={smileyFaceIcon} size="3x" color='yellow'/>
                        <p>Muscle Stimulous</p>
                    </div>

                </Col>
                
            </Row>
        )
    }

    const buildCardioProgressRow = () => {
        if (!reportCard || loading) return null;
        let frequecySmileIcon = faFaceSmile;
        let durationSmileIcon = faFaceSmile;

        if (reportCard.report_card_data.total_cardio_sessions < 3) frequecySmileIcon = faFaceFrown;
        if (reportCard.report_card_data.total_cardio_duration < 150) durationSmileIcon = faFaceFrown;
        return (
            <Row>
                <Col className="text-center border-solid-row">
                    <h3>Total Cardio Sessions</h3>
                    <h5>{reportCard.report_card_data.total_cardio_sessions}</h5>
                </Col>
                <Col className="text-center border-solid-row">
                    <h3>Total Cardio Duration</h3>
                    <h5>{reportCard.report_card_data.total_cardio_duration} minutes</h5>
                </Col>
                <Col className="text-center border-solid-row">
                    <h3>Grade</h3>
                    <hr/>
                    <FontAwesomeIcon icon={frequecySmileIcon} size="3x" color='yellow'/>
                    <p>Cardio Frequency</p>
                    <hr/>
                    <FontAwesomeIcon icon={durationSmileIcon} size="3x" color='yellow'/>
                    <p>Cardio Duration</p>
                </Col>
            </Row>
        )
    }

    const addCommasToNumber = (number) => {
            //1,000 or mre gets commas
            if (number >= 1000) {
                return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else {
                return number;
            }
    }

    const buildProgressReport = () => {
        const day = moment().format('dddd').toLowerCase();
        if (loading) return null;
        if (!reportCard) return (
            <h3 className="text-center mt-5">Reports are generated on Sundays!</h3>
        );

        return (
            <div> 
                {buildWeightProgressRow()}
                <hr/>
                {buildMacroProgressRow()}
                <hr/>
                {buildWorkoutProgressRow()}
                <hr/>
                {buildCardioProgressRow()}
            </div>
        )

    }

    const closeModal = () => {
        setShowModal(false);
        navigate('/dashboard');
    }

    useEffect(() => {
        handleReportCardGeneration();
    }, []);

    let modal = null;

    if (showModal) {
        modal = (
            <AdjustMacrosModal close={closeModal} report={reportCard.report_card_data}/>
        )
    }

    return (
        <Container className="karved-content-container">
            {modal}
            <Row>
                <h1 className="text-center">Weekly Progress Report</h1>
                <hr/>

                {loading && <h3 className="text-center mt-3">Loading...</h3>}

                {buildProgressReport()}
            </Row>
            <div className="text-center">
                    <Button onClick={() => setShowModal(true)}>
                        Get Updated Macros!
                    </Button>
            </div>
        </Container>
    )
}