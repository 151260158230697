import { Row, Col } from 'react-bootstrap';


export default function Footer() {
  return (
    <footer className="footer sticky-footer">
      <Row className="center-small-screen">
        <Col xs="12" sm="12" md="4" lg="4">
          <img src="/logo.png" alt="KarvedUp Logo" className="footer-image" />
        </Col>
        <Col xs="12" sm="12" md="4" lg="4">
          <h5>Quick Links</h5>
          <ul>
            <li><a href="mailto:Rick@KarvedUp.com">Contact Us</a></li>
            <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
            <li><a href="/privacy-policy">Privacy Policy</a></li>
          </ul>
          <p>© 2024 - KarvedUp LLC</p>
        </Col>
        <Col xs="12" sm="12" md="4" lg="4">
          <h5>Social Media</h5>
          <ul>
            <li><a href="https://www.facebook.com/profile.php?id=61561947080324" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://www.instagram.com/karvedup" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            <li><a href="https://www.twitter.com/karvedup_app" target="_blank" rel="noopener noreferrer">Twitter</a></li>
            <li><a href="https://www.tiktok.com/@karvedup" target="_blank" rel="noopener noreferrer">TikTok</a></li>
          </ul>
        </Col>
      </Row>
    </footer>
  );
}
