import {Modal, Col, Row, Button, Table} from 'react-bootstrap';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export default function ViewWorkoutPlanModal({close}){
    const userActiveWorkoutPlan = useSelector(state => state.workout.user_active_workout_plan);
    const navigate = useNavigate();

    console.log(userActiveWorkoutPlan);

    if (!userActiveWorkoutPlan) return null;

    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header className="karved-up-modal" closeButton>
                <Modal.Title>{userActiveWorkoutPlan.workout_plan_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Row>
                    <Col xs={12}  className="border-solid-row">
                        <h4 className="text-center">Workout Plan Details</h4>
                        
                        <hr/>
                        <p><strong>Plan Name:</strong> {userActiveWorkoutPlan.workout_plan_name}</p>
                        <p><strong>Plan Description:</strong> {userActiveWorkoutPlan.workout_plan_description}</p>
                        <p><strong>Plan Duration:</strong> {userActiveWorkoutPlan.workout_plan_duration_in_weeks} weeks</p>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs={12} className="border-solid-row">
                    <h4 className="text-center">Workout Plan Schedule</h4>
                    <hr/>
                    {userActiveWorkoutPlan.workout_plan_data.days.map((day, index) => {
                        return (
                            <Row className="border-solid-row">
                                <Col xs={12}>
                                    <h5 className="text-center">{day.day.charAt(0).toUpperCase() + day.day.slice(1)}</h5>

                                  
                                    {day.workouts && day.workouts.length ? day.workouts.map((workout, index) => {
                                        return (
                                            <Row className="border-solid-row">
                                                <Col xs={12}>
                                                    <h6 className="text-center">{workout.workoutName}</h6>
                                                    <p className="text-center"> {workout.workoutDescription}</p>
                                                    <hr/>
                                                    <Table variant="dark" striped>
                                                        <thead>
                                                            <tr>
                                                                <th>Exercise Name</th>
                                                                <th>Sets</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {workout.exercises.map((exercise, eIndex) => {
                                                            return (
                                                                <tr key={eIndex}>
                                                                    <td>{exercise.exerciseName}</td>
                                                                    <td>
                                                                        <strong>{exercise.sets.length} Sets</strong>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        )
                                    }): <p className="text-center">No workouts scheduled for this day</p>
                                    }
                                </Col>
                                
                            </Row>
                        )
                    })}
                    </Col>
                </Row>
                <div className="text-center mt-5 mb-5">
                    <Button variant="primary" onClick={close}>Back to Dashboard</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}