import { combineReducers } from "redux";
import user from './user';
import alerts from "./alerts";
import nutrition from "./nutrition";
import workout from './workout';

export default combineReducers({
    user,
    alerts,
    nutrition,
    workout
});