import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import {useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import API from '../../../helpers/api';
import BarcodeScannerV2 from '../../../shared/barcode-scanner-v2';
import {faPencil, faCancel, faTrashCan, faPlusCircle, faBarcode, faKeyboard, faMagnifyingGlass, faX, faXmarkCircle, faArrowLeftRotate, faLeftLong, faSave, faJournalWhills, faBook} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const itemTypes = [
    'manual-entry', //USER ENTERS EVERYTHING
    'usda', //USER SEARCHES FOR USDA FOOD ITEM
    'barcode' //USER SCANS BARCODE
]


export default function LogMealModal({close, previousMeal, previousMealIndex}){
    const dispatch = useDispatch();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const userDailyNutrientIntake = useSelector(state => state.user.userDailyNutrientIntake);
    const userSavedMeals = useSelector(state => state.user.userSavedMeals);
    const userRecipes = useSelector(state => state.nutrition.userRecipes);
    const [meal, setMeal] = useState(() => {
        if (previousMeal){
            return previousMeal;
        }
        return {
            calories: 0,
            protein: 0,
            carbohydrate: 0,
            fat: 0,
            items: []
        }
    });
    const [editSubmenuToggleMap, setEditSubmenuToggleMap] = useState(() => ({}));
    const [searchTerm, setSearchTerm] = useState(() => '');
    const usdaResults = useSelector(state => state.nutrition.usdaResults);
    const [mealName, setMealName] = useState(() => '');
    const [phase, setPhase] = useState(() => 'main')
    const [mealSaved, setMealSaved] = useState(() => false);
    const [barcode, setBarcode] = useState(() => '');
    const [barcodeSubmitting, setBarcodeSubmitting] = useState(() => false);

    //SEARCHING PARAMETERS
    const [isSearching, setIsSearching] = useState(() => false);
    const [isPerformingFoodInsert, setIsPerformingFoodInsert] = useState(() => false);
    const [foodInsertFailed, setFoodInsertFailed] = useState(() => false);
    const [showDeleteMeal, setShowDeleteMeal] = useState(() => false);
    const [isDeletingMeal, setIsDeletingMeal] = useState(() => false);
    const [isLoggingMeal, setIsLoggingMeal] = useState(() => false);
    const [isSavingMeal, setIsSavingMeal] = useState(() => false);
    


    

    const addRecipeItem = (recipe) => {
        const newItem = {
            name: recipe.recipe_name,
            type: 'recipe',
            calories: recipe.calories_per_serving,
            protein: recipe.protein_per_serving,
            carbohydrate: recipe.carbohydrate_per_serving,
            fat: recipe.fat_per_serving,
            quantity: 1,
            unit: recipe.serving_size,
            calories_per_serving: recipe.calories_per_serving,
            protein_per_serving: recipe.protein_per_serving,
            carbohydrate_per_serving: recipe.carbohydrate_per_serving,
            fat_per_serving: recipe.fat_per_serving,
        }
        setMeal({
            ...meal,
            items: [...meal.items, newItem]
        });
    }
    const addUSDAItem = (item) => {
        let newItem = {
            name: item.food_name,
            type: 'usda',
            calories: 0,
            protein: 0,
            carbohydrate: 0,
            fat: 0,
            quantity: 0,
            unit: 'g',
            caloriesPerGram: item.calories_per_gram,
            proteinPerGram: item.protein_per_gram,
            carbohydratePerGram: item.carbohydrate_per_gram,
            fatPerGram: item.fat_per_gram,
            caloriesPerOunce: item.calories_per_oz,
            proteinPerOunce: item.protein_per_oz,
            carbohydratePerOunce: item.carbohydrate_per_oz,
            fatPerOunce: item.fat_per_oz,
            serving_variations: item.common_servings.serving_sizes || []
        }

        if (item.common_servings.serving_sizes && item.common_servings.serving_sizes.length){
            newItem.unit = item.common_servings.serving_sizes[0].serving_size;
            newItem.quantity = 1
            newItem.calories = Number(item.common_servings.serving_sizes[0].calories_per_serving);
            newItem.protein = Number(item.common_servings.serving_sizes[0].protein_per_serving);
            newItem.carbohydrate = Number(item.common_servings.serving_sizes[0].carbohydrates_per_serving);
            newItem.fat = Number(item.common_servings.serving_sizes[0].fat_per_serving);

        }
        setMeal({
            ...meal,
            items: [...meal.items, newItem]
        });
        setPhase('main');
    }

    const addStandardItem = () => {
        setMeal({
            ...meal,
            items: [...meal.items, {
                name: '',
                type: 'manual-entry', //USER ENTERS EVERYTHING VS. USING USDA TYPEAHEAD
                calories: 0,
                protein: 0,
                carbohydrate: 0,
                fat: 0,
                quantity: 0,
                unit: ''
            }]
        });
    }

    const removeItem = (index) => {
        setMeal({
            ...meal,
            items: meal.items.filter((item, i) => i !== index)
        });
    }

    const updateStandardItem = (index, key, value) => {
        setMeal({
            ...meal,
            items: meal.items.map((item, i) => i === index ? {...item, [key]: value} : item)
        });
    }

    const buildMacroStatsDisplay = () => {
        return (
            <div className="border-solid-row">
                <h2 className="text-center mb-5">Calories + Macros</h2>
                <Row className="text-center">
                    <Col>
                        <h5>Calories</h5>
                        <h6>{meal.calories}</h6>
                    </Col>
                    <Col>
                        <h5>Protein</h5>
                        <h6>{meal.protein}g</h6>
                    </Col>
                    <Col>
                        <h5>Carbohydrate</h5>
                        <h6>{meal.carbohydrate}g</h6>
                    </Col>
                    <Col>
                        <h5>Fat</h5>
                        <h6>{meal.fat}g</h6>
                    </Col>   
                </Row>
            </div>
        )
    }

    const updateBrandedItem = (index, key, value) => {
        let item = meal.items[index];

        if (key === 'quantity' && isNaN(value)){
            value = 0;
        }
        item[key] = value;

        const calories = item.calories_per_serving * item.quantity;
        const protein = item.protein_per_serving * item.quantity;
        const carbohydrate = item.carbohydrate_per_serving * item.quantity;
        const fat = item.fat_per_serving * item.quantity;

        

        setMeal({
            ...meal,
            items: meal.items.map((item, i) => i === index ? {
                ...item,
                [key]: value,
                calories: calories,
                protein: protein,
                carbohydrate: carbohydrate,
                fat: fat
            } : item)
        });
    }

    const updateUSDAItem = (index, key, value) => { 
        const item = meal.items[index];
        let calories = 0;
        let protein = 0;
        let carbohydrate = 0;
        let fat = 0;
      
        if (key === 'quantity' && isNaN(value)){
            value = 0;
        }

        if (key === 'unit'){
            if (value === 'g'){
                calories = Math.round(item.caloriesPerGram * item.quantity);
                protein = Math.round(item.proteinPerGram * item.quantity);
                carbohydrate = Math.round(item.carbohydratePerGram * item.quantity);
                fat = Math.round(item.fatPerGram * item.quantity);
            } else if (value === 'oz'){
                calories = Math.round(item.caloriesPerOunce * item.quantity);
                protein = Math.round(item.proteinPerOunce * item.quantity);
                carbohydrate = Math.round(item.carbohydratePerOunce * item.quantity);
                fat = Math.round(item.fatPerOunce * item.quantity);
            } else {
                const servingVariation = item.serving_variations.find(variation => variation.serving_size === value);
                calories = Math.round(Number(servingVariation.calories_per_serving) * item.quantity);
                protein = Math.round(Number(servingVariation.protein_per_serving) * item.quantity);
                carbohydrate = Math.round(Number(servingVariation.carbohydrates_per_serving) * item.quantity);
                fat = Math.round(Number(servingVariation.fat_per_serving) * item.quantity);
            }
        }

        if (key === 'quantity'){
            if (item.unit === 'g'){
                calories = Math.round(item.caloriesPerGram * value);
                protein = Math.round(item.proteinPerGram * value);
                carbohydrate = Math.round(item.carbohydratePerGram * value);
                fat = Math.round(item.fatPerGram * value);
            } else if (item.unit === 'oz'){
                calories = Math.round(item.caloriesPerOunce * value);
                protein = Math.round(item.proteinPerOunce * value);
                carbohydrate = Math.round(item.carbohydratePerOunce * value);
                fat = Math.round(item.fatPerOunce * value);
            } else {
                const servingVariation = item.serving_variations.find(variation => variation.serving_size === item.unit);
                calories = Math.round(Number(servingVariation.calories_per_serving) * value);
                protein = Math.round(Number(servingVariation.protein_per_serving) * value);
                carbohydrate = Math.round(Number(servingVariation.carbohydrates_per_serving) * value);
                fat = Math.round(Number(servingVariation.fat_per_serving) * value);
            }
        }

        setMeal({
            ...meal,
            items: meal.items.map((item, i) => i === index ? {
                ...item,
                [key]: value,
                calories: calories,
                protein: protein,
                carbohydrate: carbohydrate,
                fat: fat
            } : item)
        });
    }

    const handleLoadMealSelect = (e) => {
        if (!e.target.value || e.target.value === 'Select A Meal') return;
        const selectedMeal = userSavedMeals.find(meal => meal.id === e.target.value);
        setMeal({
            calories: selectedMeal.calories,
            protein: selectedMeal.protein,
            carbohydrate: selectedMeal.carbohydrate,
            fat: selectedMeal.fat,
            items: selectedMeal.meal_data.items || []
        });
    }

    const buildLoadMeals = () => {
        return (
            <div className="border-solid-row">
                <Form.Group>
                    <Form.Label>Load A Meal</Form.Label>
                    <Form.Select onChange={handleLoadMealSelect}>
                        <option>Select A Meal</option>
                        {userSavedMeals.map((meal, index) => {
                            return (
                                <option key={index} value={meal.id}>{meal.meal_name}</option>
                            )
                        }
                        )}
                    </Form.Select>
                </Form.Group>
            </div>
        )
    }

    const handleToggleEditSubmenu = (index) => {
        const editSubmenuToggleMapCopy = {...editSubmenuToggleMap};
        editSubmenuToggleMapCopy[index] = !editSubmenuToggleMapCopy[index];
        setEditSubmenuToggleMap(editSubmenuToggleMapCopy);
    }

    const updateRecipeItem = (index, key, value) => {
        const item = meal.items[index];
        let calories = 0;
        let protein = 0;
        let carbohydrate = 0;
        let fat = 0;

        if (key === 'quantity' && isNaN(value)){
            value = 0;
        }

        if (key === 'quantity'){
            calories = item.calories_per_serving * value;
            protein = item.protein_per_serving * value;
            carbohydrate = item.carbohydrate_per_serving * value;
            fat = item.fat_per_serving * value;
        }

        setMeal({
            ...meal,
            items: meal.items.map((item, i) => i === index ? {
                ...item,
                [key]: value,
                calories: calories,
                protein: protein,
                carbohydrate: carbohydrate,
                fat: fat
            } : item)
        });
    }

    const buildItems = () => {


        
        return (
            <div className="border-solid-row">
                <h2 className="text-center mt-2 mb-5">Food Items</h2>
                {meal.items.map((item, index) => {
                    let submenu = null;
                    const toggleButton = (
                        <Button variant='danger' onClick={() => {
                            handleToggleEditSubmenu(index);
                        }}><FontAwesomeIcon icon={faTrashCan}/></Button>
                    )
                    if (editSubmenuToggleMap[index]){
                        submenu = (
                            <div className="border-solid-row"> 
                                <h6>Delete this item?</h6>
                                <Button variant='danger' onClick={() => {
                                    removeItem(index);
                                    handleToggleEditSubmenu(index);
                                }}>Yes, Remove it</Button> <Button onClick={() => {
                                    handleToggleEditSubmenu(index);
                                }} variant='success'>No, Keep It!</Button>
                            </div>
                        )
                    }
                    if (item.type === 'recipe'){
                    
                        return (
                            <div className="border-solid-row" key={index}>
                                <h3>Item {index + 1} {toggleButton}</h3>
                                {submenu}
                                <Row className="mb-2">
                                    <Col xs={12}>
                                        <h5>{item.name}</h5>
                                    </Col>
                                    <hr/>
                                    <Row className="text-center">
                                        <Col xs={3} className="">
                                            <h6>Calories</h6>
                                            <h6>{item.calories} calories</h6>
                                        </Col>
                                        <Col xs={3} className="text-center">
                                            <h6>Protein</h6>
                                            <h6>{item.protein}g</h6>
                                        </Col>
                                        <Col xs={3} className="text-center">
                                            <h6>Carbohydrate</h6>
                                            <h6>{item.carbohydrate}g</h6>
                                        </Col>
                                        <Col xs={3} className="text-center">
                                            <h6>Fat</h6>
                                            <h6>{item.fat}g</h6>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row>
                                        <Col>
                                            <h6>Servings</h6>
                                            <Form.Control 
                                                type="number"
                                                value={item.quantity}
                                                onChange={(evt) => updateRecipeItem(index, 'quantity', evt.target.valueAsNumber)}/>
                                        </Col>
                                        <Col className="text-center">
                                            <h6>Serving Size</h6>
                                            <h6>{item.unit}</h6>
                                        </Col>
                                    </Row>
                                </Row>
                            </div>
                        )
                    } 
                    
                    if (item.type === 'barcode') {
                       
                        return (
                            <div className="border-solid-row" key={index}>
                                <h3>Item {index + 1} {toggleButton}</h3>
                                {submenu}
                                <Row className="mb-2">
                                    <Col xs={2}>
                                        <img className="img-fluid"src={item.image_url} alt={item.food_name} style={{minWidth: '75px'}}/>
                                    </Col>
                                    <Col xs={{span: 8, offset: 1}}>
                                        <h5>{item.food_name}</h5>
                                        <h6>({item.brand_name})</h6>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    
                                    <Col>
                                        <h5>{item.calories} <br/> calories</h5>
                                    </Col>
                                    <Col>
                                        <h5>{item.protein}g <br/> protein</h5>
                                    </Col>
                                    <Col>
                                        <h5>{item.carbohydrate}g <br/> carbs</h5>
                                    </Col>
                                    <Col>
                                        <h5>{item.fat}g <br/> fat</h5>
                                    </Col>
                                </Row>
                                <hr/>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type='number'
                                                placeholder='Quantity'
                                                value={item.quantity}
                                                onChange={(e) => updateBrandedItem(index, 'quantity', e.target.valueAsNumber)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Label>Serving Size</Form.Label>
                                        <h5>{item.serving_size}</h5>
                                    </Col>
                                </Row>
                                
                            </div>

                        )
                    }
                    if (item.type === 'usda'){
                        return (
                            <div className='border-solid-row' key={index}>
                                <h3>{item.name} {toggleButton}</h3>
                                {submenu}
                                <Row className="text-center mt-3">
                                    
                                    <Col>
                                        <h5>{item.calories} <br/> calories</h5>
                                    </Col>
                                    <Col>
                                        <h5>{item.protein}g <br/> protein</h5>
                                    </Col>
                                    <Col>
                                        <h5>{item.carbohydrate}g <br/> carbs</h5>
                                    </Col>
                                    <Col>
                                        <h5>{item.fat}g <br/> fat</h5>
                                    </Col>
                                </Row>
                                <Row className="text-center mt-3">
                                    <hr/>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Quantity</Form.Label>
                                            <Form.Control
                                                type='number'
                                                placeholder='Quantity'
                                                value={item.quantity}
                                                onChange={(e) => updateUSDAItem(index, 'quantity', e.target.valueAsNumber)}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Unit</Form.Label>
                                            <Form.Select value={item.unit} onChange={e => updateUSDAItem(index, 'unit', e.target.value)}>
                                                <option value="g">g</option>
                                                <option value="oz">oz</option>
                                                {item.serving_variations.map((variation, index) => {
                                                    return (
                                                        <option key={index} value={variation.serving_size}>{variation.serving_size}</option>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                                <hr/>
                            </div>
                        )
                    }
                    return (
                        <div key={index} className="border-solid-row">
                            <h3>Item {index + 1} {toggleButton}</h3>
                             {submenu}
                        <Row >
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label><strong>Item Name </strong></Form.Label>
                                        <Form.Control
                                            type='text'
                                            placeholder='Name'
                                            value={item.name}
                                            onChange={(e) => updateStandardItem(index, 'name', e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label><strong>Calories</strong></Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder='Calories'
                                            value={item.calories}
                                            onChange={(e) => updateStandardItem(index, 'calories', e.target.valueAsNumber)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                         
                            <Row className="mt-3">
                                <hr/>
                                <Col>
                                    <Form.Group>
                                        <Form.Label><strong>Protein (g)</strong></Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder='Protein'
                                            value={item.protein}
                                            onChange={(e) => updateStandardItem(index, 'protein', e.target.valueAsNumber)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label><strong>Carbohydrate (g)</strong></Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder='Carbohydrate'
                                            value={item.carbohydrate}
                                            onChange={(e) => updateStandardItem(index, 'carbohydrate', e.target.valueAsNumber)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label><strong>Fat (g)</strong></Form.Label>
                                        <Form.Control
                                            type='number'
                                            placeholder='Fat'
                                            value={item.fat}
                                            onChange={(e) => updateStandardItem(index, 'fat', e.target.valueAsNumber)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            </Row>
                            <Row className="mt-3 mb-3">
                            <hr/>
                            <Col>
                                <Form.Label><strong>Quantity</strong></Form.Label>
                                <Form.Control
                                    type='number'
                                    placeholder='Quantity'
                                    value={item.quantity}
                                    onChange={(e) => updateStandardItem(index, 'quantity', e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Label><strong>Unit</strong></Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Unit'
                                    value={item.unit}
                                    onChange={(e) => updateStandardItem(index, 'unit', e.target.value)}
                                />
                            </Col>
                            
                        </Row>
                        
                        </div>
                    )
                })}
                <hr/>
                <Row className="border-solid-row">
                    <h3>Add An Item</h3>
                    <p>Select an Option Below</p>
                        <div >
                            <Row>
                                <Col className="text-center" xs={4}>
                                    <Button  onClick={() => setPhase('usda-lookup')}><FontAwesomeIcon icon={faMagnifyingGlass}  size='2x'/><br/>Food Lookup<br/> (Search Our Food Database)</Button>
                                </Col>
                                <Col className="text-center">
                                    <Button  onClick={() => setPhase('barcode')}><FontAwesomeIcon icon={faBarcode} size='2x'/><br/> Barcode Scanner<br/> (Scan Food Label)</Button>
                                </Col>
                                <Col className="text-center">
                                    <Button  onClick={() => setPhase('recipe')}><FontAwesomeIcon icon={faBook} size='2x'/><br/>Add Item From Personal Cookbook </Button>
                                </Col>
                                <Col className="text-center">
                                    <Button  onClick={addStandardItem}><FontAwesomeIcon icon={faKeyboard} size='2x'/><br/>Manual Entry<br/> (Add Macros Manually)</Button>
                                </Col>
                                
                                
                            </Row>
                        </div>
                    
                        
                </Row>

            </div>
        )
              
    }

    const loadData = async() => {
        await api.getUserDailyNutrientIntake();
    }
    const deleteMeal = async () => {
        if (!previousMeal || !previousMeal == undefined || !userDailyNutrientIntake || !userDailyNutrientIntake.meals || !userDailyNutrientIntake.meals.meals) return;
        setIsDeletingMeal(true);
        const newUserDailyNutrientIntake = {
            calories: 0,
            protein: 0,
            carbohydrate: 0,
            fat: 0,
            meals: {
                meals: userDailyNutrientIntake.meals.meals.filter((m, index) => index !== previousMealIndex)
            }
        }

        newUserDailyNutrientIntake.meals.meals.forEach(meal => {
            newUserDailyNutrientIntake.calories += Number(meal.calories);
            newUserDailyNutrientIntake.protein += Number(meal.protein);
            newUserDailyNutrientIntake.carbohydrate += Number(meal.carbohydrate);
            newUserDailyNutrientIntake.fat += Number(meal.fat);
        })

        await api.upsertUserDailyNutrientIntake(newUserDailyNutrientIntake);

        close();
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        setIsLoggingMeal(true);
        const newUserDailyNutrientIntake = {
            calories: 0,
            protein: 0,
            carbohydrate:0,
            fat: 0,
        }

        if (previousMeal && previousMealIndex !== undefined && userDailyNutrientIntake && userDailyNutrientIntake.meals && userDailyNutrientIntake.meals.meals){ 
            newUserDailyNutrientIntake.meals = {
                meals: userDailyNutrientIntake.meals.meals.map((m, index) => {
                    if (index === previousMealIndex){
                        return meal;
                    }
                    return m;
                })
            }
        } else if (userDailyNutrientIntake && userDailyNutrientIntake.meals && userDailyNutrientIntake.meals.meals){
            newUserDailyNutrientIntake.meals = {
                meals: [...userDailyNutrientIntake.meals.meals, meal]
            }
        } else {
            newUserDailyNutrientIntake.meals = {
                meals: [meal]
            }
        }

        newUserDailyNutrientIntake.meals.meals.forEach(meal => {
            newUserDailyNutrientIntake.calories += Number(meal.calories);
            newUserDailyNutrientIntake.protein += Number(meal.protein);
            newUserDailyNutrientIntake.carbohydrate += Number(meal.carbohydrate);
            newUserDailyNutrientIntake.fat += Number(meal.fat);
        })

        await api.upsertUserDailyNutrientIntake(newUserDailyNutrientIntake);

        checkForSimilarMealsAndClose(meal.items);
    }
    

    const checkForSimilarMealsAndClose = (items) => {
        let hasBeenSaved = false;
        //Check the Meal Items vs the userSavedMeals
        for (let i = 0, len = userSavedMeals.length; i < len; i++){
            const savedMealItems = userSavedMeals[i].meal_data.items;
            if (savedMealItems.length !== items.length) continue;
            let match = true;
            for (let j = 0, len = items.length; j < len; j++){
                if (savedMealItems[j].name !== items[j].name){
                    match = false;
                    break;
                }
            }
            if (match){
                hasBeenSaved = true;
                break
            }
        }

        if (hasBeenSaved){
            //Meal has already been saved
            close();
        }

        setPhase('save_meal');
    }

    const manualBarcodeScan = (e) => {
        e.preventDefault();
        setBarcodeSubmitting(true);
        let formattedBarcode = barcode.replace(/ /g, '');
        handleCodeScanned(formattedBarcode);
    } 

    const handleCodeScanned = async (code) => {
        setBarcodeSubmitting(true);
        const result = await api.searchBarcodeV2(code);
        if (!result) {
            setBarcodeSubmitting(false);
            return;
        };
       
        addUSDAItem(result);
        setBarcodeSubmitting(false);
        setPhase('main');
    }

    const performUSDASearch = async(e) => {
        e.preventDefault();
        if (!searchTerm.trim() || !searchTerm.trim().length) return;
        setIsSearching(true);
        setIsPerformingFoodInsert(false);
        setFoodInsertFailed(false);
        const results = await api.searchFoodItems(searchTerm);
        if (results && results.length){
            setIsSearching(false);
            return;
        }
        //DO A DIRECT LOOKUP TO INSERT FOOD ITEM
        setIsPerformingFoodInsert(true);
        const result = await api.manualFoodLookup(searchTerm);
        if (!result){
            setFoodInsertFailed(true);
            setIsPerformingFoodInsert(false);
            return;
        }

        setIsSearching(false);
        setIsPerformingFoodInsert(false);
        setFoodInsertFailed(false);
    }

    const handleSaveMeal = async (e) => {
        e.preventDefault();
    
        if (!mealName.trim() || !mealName.trim().length) return;
        setIsSavingMeal(true);
        let calories = 0;
        let protein = 0;
        let carbohydrate = 0;
        let fat = 0;

        meal.items.forEach(item => {
            calories += Number(item.calories);
            protein += Number(item.protein);
            carbohydrate += Number(item.carbohydrate);
            fat += Number(item.fat);
        });

        await api.saveUserMeal({
            meal_name: mealName,
            meal_data: meal,
            protein: protein,
            fat: fat,
            carbohydrate: carbohydrate,
            calories: calories
        });

        setMealSaved(true);
        close();
    }

    useEffect(() => {
        loadData();
        if (!meal.items.length){
            addStandardItem();
        }
    }, [])

    useEffect(() => {
        let calories = 0;
        let protein = 0;
        let carbohydrate = 0;
        let fat = 0;

        meal.items.forEach(item => {
            calories += item.calories;
            protein += item.protein;
            carbohydrate += item.carbohydrate;
            fat += item.fat;
        });

        setMeal({
            ...meal,
            calories: calories,
            protein: protein,
            carbohydrate: carbohydrate,
            fat: fat
        });
    }, [meal.items])

    const buildFoodItemsListSection = () => {
        if (foodInsertFailed){
            return (
                <div>
                    <h6 className="text-center">We couldn't find any nutritional facts for {searchTerm}</h6>
                </div>
            )
        }
        if (isPerformingFoodInsert){
            return (
                <div>
                    <h6 className="text-center">We are looking for nutritional facts for {searchTerm}...</h6>
                </div>
            )
        }
        if (isSearching){
            return (
                <div>
                    <h6 className="text-center">Searching...</h6>
                </div>
            )
        }


        if (!usdaResults.length) return null;

        return (
            <div>
                <h3 className="text-center">Food Items</h3>
                <h6>{usdaResults.length} items found</h6>

                {usdaResults.map((result, index) => {
                  
                    let title = 'Macros Per 100 Grams';
                    let calories = Number(result.calories_per_gram) * 100;
                    let protein = Number(result.protein_per_gram) * 100;
                    let carbohydrate = Number(result.carbohydrate_per_gram) * 100;
                    let fat = Number(result.fat_per_gram) * 100;
                    const servingVariations = result.common_servings.serving_sizes.length;

                    if (result.common_servings && result.common_servings.serving_sizes && result.common_servings.serving_sizes.length){ 
                        const serving = result.common_servings.serving_sizes[0];
                        calories = serving.calories_per_serving;
                        protein = serving.protein_per_serving;
                        carbohydrate = serving.carbohydrates_per_serving;
                        fat = serving.fat_per_serving;

                        title = 'Per '   + serving.serving_size

                    }
                    return (
                        <div key={index} className="usda-row" onClick={() => addUSDAItem(result)}>
                            <h6>{result.food_name.toUpperCase()}</h6>
                            <hr/>
                            <Row>
                                <h6 className="text-center mb-4">{title}</h6>
                                <Col>
                                    <h6>Calories:</h6>
                                    <span>{calories}</span>
                                </Col>
                                <Col>
                                    <h6>Protein: {result.protein}</h6>
                                    <span>{protein}g</span>
                                </Col>
                                <Col>
                                    <h6>Carbohydrate: {result.carbohydrate}</h6>
                                    <span>{carbohydrate}g</span>
                                </Col>
                                <Col>
                                    <h6>Fat: {result.fat}</h6>
                                    <span>{fat}g</span>
                                </Col>
                            </Row>
                            {(servingVariations) ? (
                                <Row>
                                    <h6 className='text-center mt-4'>
                                    {servingVariations} serving variations
                                    </h6>
                                    
                                </Row>
                            ) : null}
                        </div>
                    )
                })}

            </div>        
        )

    }
    if (phase === 'save_meal'){
        return (
            <Modal show={true} onHide={close} size='xl'>
                <Modal.Header closeButton={true} className="karved-up-modal" >
                    <Modal.Title>Log Meal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="karved-up-modal">
                    <div className="text-center">
                        <h5 className="text-center">Eat This Meal Alot? Save it as a preset meal.</h5>
                        <p className="text-center">Give this meal a name (after you complete all of the fields) and save it as a preset.</p>
                        
                        <Form>
                            <Form.Group>
                                <Form.Label>Meal Name</Form.Label>
                                <Form.Control value={mealName} 
                                            type='text' 
                                            placeholder='Meal Name' 
                                            onChange={(e) => setMealName(e.target.value)}
                                            required/>

                            </Form.Group>
                            <div className="text-center mt-3">
                                <Button disabled={isSavingMeal} onClick={handleSaveMeal} variant='primary'><FontAwesomeIcon icon={faSave}/><br/>Save Meal</Button>
                                <br/> <br/>
                                <Button onClick={close} variant="secondary">I don't want to save this meal</Button>
                            </div>
                        </Form>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
    if (phase === 'main'){
        return (
            <Modal show={true} onHide={close} size='xl'>
                <Modal.Header closeButton={true} className="karved-up-modal" >
                    <Modal.Title>Log Meal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="karved-up-modal">
                    
                    {buildMacroStatsDisplay()}
                    
                    {buildLoadMeals()}
                    {previousMeal && previousMealIndex !== undefined && (
                        <div className="text-center mt-3 mb-3">
                            <Button variant='danger' onClick={() => setShowDeleteMeal(true)}>Delete This Meal</Button>
                            {showDeleteMeal && (
                                <div className="mt-3">
                                    <Button onClick={deleteMeal} variant='danger' disabled={isDeletingMeal}><FontAwesomeIcon icon={faTrashCan} /> <br/>Yes, Delete Meal</Button>
                                    <Button onClick={() => setShowDeleteMeal(false)} variant='secondary'><FontAwesomeIcon icon={faCancel} /> <br/>No, Keep Meal</Button>
                                </div>
                            )}
                        </div>
                    )}

                    
                    <Form onSubmit={handleSubmit}>
                        {buildItems()}
                     
                      
                        <hr className="mt-5"/>
                        
                            <div className="text-center">
                                <h5>You're Crushing It!</h5>
                                <Button className="mb-5" disabled={isLoggingMeal || !meal.items || !meal.items.length} variant='primary' type='submit'><FontAwesomeIcon icon={faJournalWhills}/><br/> Log This Meal</Button>
                            </div>
                        
                    </Form>
               
                    
                </Modal.Body>
            </Modal>
        )
    } else if (phase === 'usda-lookup'){
        return (
            <Modal show={true} onHide={close} size='xl'>
                <Modal.Header closeButton={true} className="karved-up-modal" >
                    <Modal.Title>Log Meal</Modal.Title>
                </Modal.Header>

                <Modal.Body className="karved-up-modal">
                    <div className="">
                    <h2 className="text-center mb-5">Food Lookup</h2>
                    <Form onSubmit={performUSDASearch}>
                        <Form.Group>
                            <Form.Label>Search</Form.Label>
                            <Form.Control type='text' 
                                          placeholder='Search Our Food Database'
                                          value={searchTerm}
                                          onChange={(e) => setSearchTerm(e.target.value)}
                                          required/>
                        </Form.Group>
                        <div className="mt-2">
                            <Button  variant='danger' onClick={() => setPhase('main')}><FontAwesomeIcon icon={faXmarkCircle}/><br/>Cancel</Button>
                            <Button type="submit" variant='primary'><FontAwesomeIcon icon={faMagnifyingGlass}/><br/>Search</Button>
                            
                        </div>
                        
                    </Form>
                    <hr/>
                    {buildFoodItemsListSection()}
                    </div>
                </Modal.Body>
            </Modal>
        )
    } else if (phase == 'barcode'){
        
        return (
            <Modal show={true} onHide={close} size='xl'>
                <Modal.Header closeButton={true} className="karved-up-modal" >
                    <Modal.Title>Log Meal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="karved-up-modal">
                <Button variant='danger' onClick={() => setPhase('main')}><FontAwesomeIcon icon={faLeftLong} /></Button>
                   {!barcodeSubmitting ? (
                    <div>
                    <h2 className="text-center mb-5">Barcode Scanner</h2>
                    <Form onSubmit={manualBarcodeScan}>
                    
                        <Form.Group>
                            <Form.Label>Search Food by Barcode Number</Form.Label>
                            <Form.Control type='text' placeholder='Enter Barcode' value={barcode} onChange={evt => setBarcode(evt.target.value)}/>
                            <Button disabled={barcodeSubmitting} type='submit'> Search </Button>
                        </Form.Group>
                        <hr/>
                       
                    </Form>
                    </div>
                    ): (
                        <div className="text-center">
                            <h5 className="text-center">Searching for Food Facts...</h5>
                            <p>This may take a few moments if the food is not in the system.</p>
                        </div>
                    )}
                    
                    
                </Modal.Body>
            </Modal>
        )
    } else if (phase === 'recipe') {
        return (
            <Modal show={true} onHide={close} size='xl'>
                <Modal.Header closeButton={true} className="karved-up-modal" >
                    <Modal.Title>Log Meal</Modal.Title>
                </Modal.Header>
                <Modal.Body className="karved-up-modal">
                    <h2 className="text-center">Your Personalized Recipes</h2>
                    <p className="text-center mb-5">Every month your cook book will get bigger! <br/>
                    <Button variant="danger" className="mt-3" onClick={() => setPhase('main')}>
                            <FontAwesomeIcon icon={faCancel} /> Cancel
                    </Button></p>
                   
                    <Row className="text-center p-4">
                        {
                            userRecipes.map((recipe, index) => {
                                return (
                                    <Col key={index} className="border-solid-row mt-3 p-4" xs={6}>
                                        <h5>{recipe.recipe_name}</h5>
                                        <p>{recipe.recipe_description}</p>
                                        
                                        <hr/>
                                        <h6>Macros Per Serving</h6>
                                        <h6>({recipe.serving_size})</h6>
                                        <h6>Calories: {recipe.calories_per_serving} | Protein: {recipe.protein_per_serving}g | Carbs: {recipe.carbohydrate_per_serving}g | Fat: {recipe.fat_per_serving}g</h6>
                                        <hr/>
                                        <h6>Ingredients</h6>
                                        <div>
                                            {recipe.ingredients.map((item, index) => {
                                                return (
                                                    <div style={{textAlign:'left'}} key={index}>{item}</div>
                                                )
                                            })}
                                        </div>
                                        <hr/>
                                        <h6>Cooking Instructions</h6>
                                        <div>
                                            {
                                                recipe.cooking_instructions.map((instruction, index) => {
                                                    return (
                                                        <div style={{textAlign:'left'}} key={index}>{instruction}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <hr/>
                                        <Button onClick={() => {
                                           addRecipeItem(recipe);
                                           setPhase('main');
                                        }}>Add Item to Meal</Button>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
    
}
