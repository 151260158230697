import {useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import API from '../../helpers/api';
import {faBars} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const allowedPages = ['/privacy-policy', '/terms-and-conditions', '/forgot-password', '/reset-password'];

export default function Navbar() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const user = useSelector(state => state.user).userData;
    const [showMenu, setShowMenu] = useState(false);

    //Close Submenu when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (e) => {
            if(e.target.closest('.karved-up-navbar') == null){
                setShowMenu(false);
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    useEffect(() => {
        console.log("Running")
        async function loadUser(){
            const userData = await api.getUserData();
            
            if (allowedPages.includes(window.location.pathname)){
                return; //Permitted Pages
            } else if(!userData && window.location.pathname != '/access'){
                navigate('/');
            } else if (userData && window.location.pathname == '/access'){
                navigate('/dashboard');
            }
            
        }
        loadUser();
    }, [window.location.pathname]);

    const handleItemClick = (path) => {
        setShowMenu(false);
        navigate(path);
    }

    

    const buildSubMenu = () => {
        if(!user){
            return null;
        }
        if(showMenu){
            return (
                <div className="sub-menu">
                    <div className="sub-menu-item" onClick={() => handleItemClick('/dashboard')}>Dashboard</div>
                    <div className="sub-menu-item" onClick={() => handleItemClick('/workout-planner')}>Create Workout</div>
                    <div className="sub-menu-item" onClick={() => handleItemClick('/account')}>Manage Account</div>
                    <div className="sub-menu-item" onClick={() => {
                        window.localStorage.clear();
                        window.location.href="/";
                    }}>Log Out</div>
                </div>
            )
        }
        return null;
    }

    return (
        <div className="karved-up-navbar">
            <img onClick={() => handleItemClick('/')} src="/logo.png" alt="Karved Up Fitness Logo" />
            {user && (
                <div className="menu-icon" onClick={() => setShowMenu(!showMenu)}>
                <FontAwesomeIcon icon={faBars} size='2xl' />
                </div>
            )}
            {buildSubMenu()}
        </div>
    )
}