import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import { Alert } from 'react-bootstrap';
import * as ACTIONS from '../constants/actions';

export default function AlertContainer(){
    const dispatch = useDispatch();
    const alerts = useSelector(state => state.alerts.activeAlerts);

    useEffect(() => {
        const timeout = setTimeout(() => {
            dispatch({type: ACTIONS.REMOVE_ALL_ALERTS});
        }, 5000);
        return () => clearTimeout(timeout);
    }, [alerts]);

    if (!alerts.length) return null;
    return (
        <div className="alert-container">
            {alerts.map((alert, index) => {
                console.log(alert)
                return (
                    <Alert key={index} variant={alert.variant}>
                        <Alert.Heading>{alert.title}</Alert.Heading> 
                            {alert.message}
                    </Alert>
                )
            })}
        </div>
    )
};