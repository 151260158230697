import {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col, Form, Button, Table, Accordion} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import API from '../../helpers/api';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import LogMealModal from './modals/log-meal-modal';
import CheckinModal from './modals/checkin-modal';
import LogWorkoutModal from './modals/log-workout-modal';
import LogCardioModal from './modals/log-cardio-modal';
import ViewWorkoutPlanModal from './modals/view-workout-plan-modal';
import LineChart from './charts/line-chart';
import MacroProgressBar from './charts/macro-progress-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faPencil, faPlusCircle, faRunning, faWeightScale } from '@fortawesome/free-solid-svg-icons';

export default function MainDashboard() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const userDailyMacros = useSelector(state => state.user.userDailyMacros);
    const userDailyNutrientIntake = useSelector(state => state.user.userDailyNutrientIntake);
    const userDailyCheckin = useSelector(state => state.user.userDailyCheckin);
    const userCompletedWorkouts = useSelector(state => state.user.userCompletedWorkouts);
    const userCompletedCardioSessions = useSelector(state => state.user.userCompletedCardioSessions);
    const lastSevenCheckins = useSelector(state => state.user.userDailyCheckinLastSevenDays);
    const userActiveWorkoutPlan = useSelector(state => state.workout.user_active_workout_plan);
    const [showLogMealModal, setShowLogMealModal] = useState(() => false);
    const [showCheckinModal, setShowCheckinModal] = useState(() => false);
    const [showLogCardioModal, setShowLogCardioModal] = useState(() => false);
    const [showLogWorkoutModal, setShowLogWorkoutModal] = useState(() => false);
    const [showViewWorkoutPlanModal, setShowViewWorkoutPlanModal] = useState(() => false);  
    const [selectedWorkout, setSelectedWorkout] = useState(() => null);
    const [selectedCardioSession, setSelectedCardioSession] = useState(() => null);
    const [selectedPlannedCardioSession, setSelectedPlannedCardioSession] = useState(() => null);
    const [selectedPlannedWorkout, setSelectedPlannedWorkout] = useState(() => null);
    const [selectedMeal, setSelectedMeal] = useState(() => undefined);
    const [selectedMealIndex, setSelectedMealIndex] = useState(() => undefined);
    const [isLoading, setIsLoading] = useState(() => true);
    const [gotoId, setGotoId] = useState(() => null);
    
    useEffect(() => {
        ///START AT TOP OF PAGE
        window.scrollTo(0, 0);
    }, []);

    const scrollToElement = () => {
        if (!gotoId) return;
        const element = document.getElementById(gotoId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }

        setGotoId(null);
    }
    
    const loadMacroData = async() => {
        //TODO: OPTIMIZE TO NOT CALL EVERYTHING EVERYTIME... FOR NOW IT IS OKAY
        await Promise.all([
            api.getUserIntake(),
            api.getUserDailyMacros(),
            api.getUserDailyNutrientIntake(),
            api.getUserDailyCheckin(),
            api.getUserSavedMeals(),
            api.getUserCompleteWorkoutsByDate(moment().format('YYYY-MM-DD')),
            api.getUserDailyCheckinByDateRange(7),
            api.getUserActiveWorkoutPlan(),
            api.getUserCardioWorkouts(moment().format('YYYY-MM-DD')),
            api.getAllExercises(),
            api.getUserMuscleRecipes(),
        ]);

        setIsLoading(false);

        //Check if today is Sunday:
        const day = moment().format('dddd').toLowerCase();
        if (day !== 'sunday') return;

        //CHECK IF THE USER HAS A WEEKLY REPORT CARD
        const weeklyReportCard = await api.getUserWeeklyReportCard(moment().format('YYYY-MM-DD'));
        
        //CHECK IF THE USER CHECKED IN TODAY
        const dailyCheckin = await api.getUserDailyCheckin();

        //IF THEY HAVE A CHECKIN, BUT NO WEEKLY REPORT CARD, NAVIGATE TO REPORT CARD SCREEN
        if (dailyCheckin && !weeklyReportCard){
            navigate('/weekly-report-card');
        } else if (!dailyCheckin && !weeklyReportCard){
            //TODO: GIVE THE USER A PROMPT TO CHECK IN

        }
    }

    const closeModal = () => {
        setShowLogMealModal(false);
        setShowCheckinModal(false);
        setShowLogWorkoutModal(false);
        setShowLogCardioModal(false);
        setSelectedWorkout(null);
        setSelectedPlannedWorkout(null);
        setSelectedCardioSession(null);
        setSelectedPlannedCardioSession(null);
        setSelectedMeal(undefined);
        setSelectedMealIndex(undefined);
        setShowViewWorkoutPlanModal(false);
        loadMacroData();
        setTimeout(() => {
            scrollToElement();
        }, 1000);
    }

    const checkUserHasActiveSubscription = async () =>{
        const subscription = await api.getSubscriptionInformation();
        console.log(subscription);
        if (!subscription || 
            !subscription.subscription || 
            (subscription.subscription.status !== 'active' &&
            subscription.subscription.status !== 'trialing' &&
            moment(subscription.subscription.current_period_end * 1000).isBefore(moment()))){

            navigate('/user-payment');
            return;
        }
        loadMacroData();
    }

    useEffect(() => {
        checkUserHasActiveSubscription();
    }, [])

    const buildMacroArea = () => {
      

        if (!userDailyMacros || !userDailyNutrientIntake) return null;

        const dailyCalories = Number(userDailyMacros.calories);
        const dailyProtein = Number(userDailyMacros.protein);
        const dailyCarbs = Number(userDailyMacros.carbohydrate);
        const dailyFats = Number(userDailyMacros.fat);

        let consumedCalories = 0;
        let consumedProtein = 0;
        let consumedCarbs = 0;
        let consumedFats = 0;
        let mealCount = 0;
        if (userDailyNutrientIntake && userDailyNutrientIntake.meals){
            consumedCalories = Number(userDailyNutrientIntake.calories);
            consumedProtein = Number(userDailyNutrientIntake.protein);
            consumedCarbs = Number(userDailyNutrientIntake.carbohydrate);
            consumedFats = Number(userDailyNutrientIntake.fat);
            mealCount = userDailyNutrientIntake.meals.meals.length;
        }

        
        return (
            <Row className="karved-up-row text-center" id="macro-area">
                <h5>Daily Macro Consumption</h5>
                <hr/>
                <Accordion className="mb-3">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{mealCount} Meals Logged</Accordion.Header>
                        <Accordion.Body>
                            {userDailyNutrientIntake && userDailyNutrientIntake.meals && userDailyNutrientIntake.meals.meals.length && userDailyNutrientIntake.meals.meals.map ((meal, index) => {
                                //Moving to a Mobile Friendly Row... Tables just don't cut it
                                return (
                                    <Row className="karved-content-container">
                                        <Col xs={12} lg={6}>
                                            <h5>Meal #{index + 1}</h5>
                                            <Button onClick={() => {
                                                    setSelectedMeal(meal);
                                                    setSelectedMealIndex(index);
                                                    setGotoId('macro-area');
                                                    setShowLogMealModal(true);
                                                }}> <FontAwesomeIcon icon={faPencil} /> <br/> Edit Meal</Button>
                                            <hr/>
                                            <p>Calories: {meal.calories}</p>
                                            <p>Protein: {meal.protein}</p>
                                            <p>Carbs: {meal.carbohydrate}</p>
                                            <p>Fats: {meal.fat}</p>
                                            <hr className="mb-3"/>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <h5>Items</h5>
                                            <hr/>
                                            {meal.items.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        {item.name} <br/>
                                                        ({item.quantity} - {item.unit})
                                                        <hr/>
                                                    </div>
                                                )
                                            })}
                                        </Col>
                                    </Row>
                                )
                            })}

                                
                               
                            
                            
                        


                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                <div className='mb-3'>
                <Button className="primary-btn" onClick={() => {
                    setShowLogMealModal(true)
                    setGotoId('macro-area')
                    }}><FontAwesomeIcon icon={faPlusCircle} /> Log Meal</Button>
                </div>
                <MacroProgressBar macroName="Calories" macroNumber={dailyCalories} macroConsumed={consumedCalories}/>
                <MacroProgressBar macroName="Protein" macroNumber={dailyProtein} macroConsumed={consumedProtein}/>
                <MacroProgressBar macroName="Carbs" macroNumber={dailyCarbs} macroConsumed={consumedCarbs}/>
                <MacroProgressBar macroName="Fats" macroNumber={dailyFats} macroConsumed={consumedFats}/>
            </Row>
        )
    }


    const buildWorkoutPlanArea = () => {
        if (!userActiveWorkoutPlan) return null;
        console.log("USER ACTIVE WORKOUT PLAN", userActiveWorkoutPlan); 
        return (
            <Row className="border-solid-row mt-5 mb-5">
                <h4 className="text-center">Your Current Workout Plan</h4>
                <hr/>
                <h5>{userActiveWorkoutPlan.workout_plan_data.workoutPlanName}</h5>
                <h6>Plan ends on {moment(userActiveWorkoutPlan.workout_end_date).format('MM/DD/YYYY')}</h6>
                <p>{userActiveWorkoutPlan.workout_plan_data.workoutPlanDescription}</p>
                <div className="text-center">
                    <Button onClick={() => setShowViewWorkoutPlanModal(true)}>View Plan Details</Button> <br/><br/>
                    <Button variant='secondary' onClick={() => navigate('/select-workout-plan')}>Change Workout Plan</Button>
                    
                </div>
            </Row>
        )
    }

    const buildCheckinArea = () => {
        const workoutFontAwesome = <FontAwesomeIcon icon={faDumbbell} />
        const createWorkoutFontAwesome = <FontAwesomeIcon icon={faPlusCircle} />
        const cardioFontAwesome = <FontAwesomeIcon icon={faRunning} />
        const freeWorkoutFontAwesome = <FontAwesomeIcon icon={faDumbbell} />
        let checkinCol = null;
        let workoutCol = null;
        let cardioCol = null;
        let freeStyleCol = (
             <Col className="text-center border-solid-row p-5"xs={12}>
                    <h5>Go with the flow</h5>
                    <p>Just want to do your own thing? We got you covered.</p>
                    <Button className="primary-btn" onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId('workout-area')
                        }}>{freeWorkoutFontAwesome}Log Workout</Button><br/>
                    <Button className='primary-btn mt-2' onClick={() => {
                        setShowLogCardioModal(true)
                        setGotoId('cardio-area')
                        }}>{cardioFontAwesome} Log Cardio Session</Button>
                </Col>
        )

        
        if (userActiveWorkoutPlan) {
            const dayTitle = moment().format('dddd').toLowerCase();
            const workoutDay = userActiveWorkoutPlan.workout_plan_data.days.find(day => day.day.toLowerCase() == dayTitle);
            
            if (workoutDay){
                cardioCol = (
                    <div className="text-center border-solid-row p-5" >
                        {
                            !workoutDay.cardioSessions.length ? (
                                <div>
                                    <p>No cardio sessions scheduled for today</p>
                                    <Button onClick={() => {
                                        setShowLogCardioModal(true);
                                        setGotoId('cardio-area');
                                    }}>Log Cardio Session</Button>
                                </div>
                            ): (
                                <div>
                                    {workoutDay.cardioSessions.map((cardio, index) => {
                                        const completedCardioSession = userCompletedCardioSessions.find(c => c.cardio_data.cardioWorkoutName == cardio.cardioWorkoutName && c.cardio_data.cardioDescription == cardio.cardioDescription); 
                                        console.log(completedCardioSession);
                                        console.log(cardio);
                                        let cardioButton = (
                                            <Button onClick={() => {
                                                setSelectedPlannedCardioSession(cardio);
                                                setShowLogCardioModal(true);
                                                setGotoId('cardio-area');
                                            }}>Begin Cardio Session</Button>
                                        );
                                        if (completedCardioSession){
                                            console.log("Building Herer")
                                            cardioButton = (
                                                <Button onClick={() => {
                                                    setSelectedCardioSession(completedCardioSession);
                                                    setShowLogCardioModal(true);
                                                    setGotoId('cardio-area');
                                                }}>Continue/Edit Cardio Session</Button>
                                            )
                                        }
                                        return (
                                            <div key={index}>
                                                <h5>Session {index + 1}</h5>
                                                <ul>
                                                    {cardio.activities.map((activity, index) => {
                                                        return (
                                                            <li key={index}>
                                                                {activity.activity} - {activity.duration} minutes
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                                <p>{cardio.activities.reduce((a, c) => a + Number(c.duration || 0), 0)} minutes</p>
                                                {cardioButton}
                                            </div>
                                        )
                                    })}
                                    </div>
                            )
                        }
                    </div>
            )
                workoutCol = (
                    <Col className="border-solid-row p-5" xs={12} md={6}>
                        <h5 className="text-center">Today's Training</h5>
                        <hr/>
                        {
                            !workoutDay.workouts.length ? (
                                <div className="text-center border-solid-row">
                                    <h5>No workouts scheduled for today</h5>
                                    <Button onClick={
                                        () => {
                                            setShowLogWorkoutModal(true);
                                            setGotoId('workout-area');
                                        }
                                    }>Log Workout</Button>
                                </div>
                            ): (
                                <div>
                                    {workoutDay.workouts.map((workout, index) => {
                                        let workoutButton = (
                                            <Button onClick={
                                                () => {
                                                    setSelectedPlannedWorkout(workout);
                                                    setShowLogWorkoutModal(true);
                                                    setGotoId('workout-area');
                                                }
                                            }>
                                                <FontAwesomeIcon icon={faDumbbell} /><br/>
                                                Begin Workout
                                            </Button>
                                        );
                                        
                                        const completedWorkout = userCompletedWorkouts.find(
                                            completed => (completed.workout_data.workoutName == workout.workoutName) && (completed.workout_data.workoutDescription == workout.workoutDescription)
                                        );

                                        if (completedWorkout){
                                            workoutButton = (
                                                <Button onClick={() => {
                                                    setSelectedWorkout(completedWorkout);
                                                    setShowLogWorkoutModal(true);
                                                    setGotoId('workout-area');
                                                }}>Continue/Edit Workout</Button>
                                            )
                                        }
                                        return (
                                            <div key={index} className="border-solid-row text-center">
                                                <h5>{workout.workoutName}</h5>
                                                <p>{workout.totalWorkoutSets} sets</p>
                                                {workoutButton}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }
                        {cardioCol}
                    </Col>
                )

                
        
        }
        } else {
           
            workoutCol = (
                <Col className="text-center border-solid-row p-5" xs={12} md={6}>
                    <h5>You don't have a Workout Plan!</h5>
                    <p>Having a workout plan will help you stay on track</p>
                    <Button className="primary-btn" onClick={() => navigate('/select-workout-plan')}>{workoutFontAwesome} Select a Workout Plan</Button><br/>
                    <Button className="primary-btn mt-2" onClick={() => navigate('/workout-planner')}>{createWorkoutFontAwesome} Create a Workout Plan</Button>
                </Col>
            )

            freeStyleCol = (
                <Col className="text-center border-solid-row p-5"xs={12} sm={6} md={4}>
                    <h5>Go with the flow</h5>
                    <p>Just want to do your own thing? We got you covered.</p>
                    <Button className="primary-btn" onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId('workout-area')
                        }}>{freeWorkoutFontAwesome}Log Workout</Button><br/>
                    <Button className='primary-btn mt-2' onClick={() => {
                        setShowLogCardioModal(true)
                        setGotoId('cardio-area')
                        }}>{cardioFontAwesome} Log Cardio Session</Button>
                </Col>
            )
        }

        if (!userDailyCheckin) {
            checkinCol =  (
                <Col className="text-center border-solid-row p-5" xs={12} md={6}>
                     <h5>Log your weight for the day!</h5>
                     <p>Logging your weight daily will help you track progress over time.</p>
                     <Button className="primary-btn" onClick={() => setShowCheckinModal(true)}><FontAwesomeIcon icon={faWeightScale} /> Log your Weight</Button>
                     <hr/>
                     <h5>Log your Food Intake</h5>
                     <p>Logging your meals will help you track your daily macro intake.</p>
                     <Button  onClick={() => {
                            setShowLogMealModal(true)
                            setGotoId('macro-area')
                            }}><FontAwesomeIcon icon={faPlusCircle} /> Log Meal</Button>
                </Col>
            )
        } else {
            checkinCol = (
                <Col className="border-solid-row p-5"xs={12} md={6}>
                    <h5 className="text-center">Today's Checkin</h5>
                    <hr/>
                    <div className="align-left">
                        <p>Weight: {userDailyCheckin.weight} lbs</p>
                        <p>Energy Level: {userDailyCheckin.energy_level}</p>
                        <p>Mood: {userDailyCheckin.mood}</p>
                        <p>Sleep Hours: {userDailyCheckin.sleep_hours}</p>
                    </div>
                    <div className="text-center">
                    <Button className="primary-btn" onClick={() => setShowCheckinModal(true)}> <FontAwesomeIcon icon={faPencil}/> <br/>Edit Checkin</Button>
                    <hr/>
                    <h5>Log your Food Intake</h5>
                    <p>Logging your meals will help you track your daily macro intake.</p>
                    <Button onClick={() => {
                        setShowLogMealModal(true)
                        setGotoId('macro-area')
                        }}><FontAwesomeIcon icon={faPlusCircle} /> Log Meal</Button>
                    </div>
                </Col>
            )
        }

        let checkinChartCol = null;

        if (lastSevenCheckins && lastSevenCheckins.length){
            //BUILD A REACT CHART Line Chart
            const chartData = {
                
                labels: lastSevenCheckins.map(checkin => moment(checkin.check_in_date).format('MM/DD')),
                datasets: [
                    {
                        label: 'Weight',
                        data: lastSevenCheckins.map(checkin => Number(checkin.weight)),
                        fill: false,
                        borderColor: '#FFF',
                        backgroundColor: '#FFF',
                        color: '#FFF',
                        tension: 0.1
                    }
                ]
            }

            const options = {
                responsive: true,
                
                plugins: {
                  legend: {
                    display: false,
                    position: 'top',
                    color: '#FFF',
                  },
                  title: {
                    display: true,
                    color: '#FFF',
                    text: '7 Day Weight Tracker',
                  },
                },
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: 'Date',
                      color: '#FFF',
                    },
                    ticks: {
                        color: '#FFF',
                    },
                    borderColor: '#FFF',
                    
                  },
                  y: {
                    title: {
                      display: true,
                      color: '#FFF',
                      text: 'Weight (lbs)',
                    },
                    ticks: {
                        color: '#FFF',
                    },
                    beginAtZero: false, // You can set this to `true` if you want the Y-axis to start at 0
                  },
                },
              };
            checkinChartCol = (
                <Row className="mt-3 mb-3">
                    <h4 className="text-center">Weight Tracker</h4>
                    <div style={{minWidth: 320, maxWidth: 700, margin: '0px auto'}}>
                        <LineChart data={chartData} options={options}/>
                    </div>
                </Row>
            )
        }
       
        return (
            <div>
                
                <Row className='karved-up-row'>
                {checkinCol}
                {workoutCol}
              
                
                </Row>
                {checkinChartCol}
            </div>
            
        )
    }

    const buildCardioArea = () => {
        if (!userCompletedCardioSessions || !userCompletedCardioSessions.length){
            return (
                <Row className="karved-up-row">
                    <h3>No cardio sessions logged today</h3>
                    <Button onClick={() => {
                        setShowLogCardioModal(true)
                        setGotoId('cardio-area')
                        }}>Log Cardio</Button>
                </Row>
            )
        }

        return (
            <Row className="karved-up-row" id={'cardio-area'}>
                <h3>Today's Cardio Sessions</h3>
                <Table className="mt-5" responsive variant="dark">
                    <thead>
                        <tr>
                            <th>Cardio Workout</th>
                            <th>Total Duration</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userCompletedCardioSessions.map((cardio, index) => {
                            return (
                                <tr key={index} onClick={() => {
                                    setSelectedCardioSession(cardio);
                                    setShowLogCardioModal(true);
                                    setGotoId('cardio-area');
                                }}>
                                    <td>{cardio.cardio_data.cardioWorkoutName}</td>
                                    <td>{cardio.cardio_data.totalDuration} minutes</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </Row>
        )
    }

    const buildWorkoutArea = () => {
        

        if (!userCompletedWorkouts || !userCompletedWorkouts.length){
            return (
                <Row className="karved-up-row" id="workout-area">
                    <h3>No workouts logged today</h3>
                    <Button onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId("workout-area")}}>Log Workout</Button>
                    
                </Row>
            )
        }
        const totalWorkouts = userCompletedWorkouts.length;
        const totalVolume   = userCompletedWorkouts.reduce((acc, workout) => {
            return acc + workout.workout_data.totalVolume;
        }, 0);
        const totalSets = userCompletedWorkouts.reduce((acc, workout) => {
            return acc + workout.workout_data.totalWorkoutSetCount;
        }, 0)
        const musclesTrainedMap = {};
        userCompletedWorkouts.forEach(workout => {
            for (let muscle in workout.workout_data.muscleSetMap){
                if (musclesTrainedMap[muscle]){
                    musclesTrainedMap[muscle] += workout.workout_data.muscleSetMap[muscle];
                } else {
                    musclesTrainedMap[muscle] = workout.workout_data.muscleSetMap[muscle];
                }
            }

        })
        return (
            <Row className="karved-up-row" id="workout-area">
                <Col xs={12}>
                    <h5 classname="text-center">Today's Workout Stats</h5>
                    <hr/>
                    <h6>Total Workouts: {totalWorkouts}</h6>
                    <h6>Total Volume: {totalVolume}lbs</h6>
                    <h6>Total Sets: {totalSets}</h6>
                    <Button onClick={() => {
                        setShowLogWorkoutModal(true)
                        setGotoId('workout-area')
                        }}>Log Another Workout</Button>
                    <Table className="mt-5" responsive variant="dark">
                        <thead>
                            <tr>
                                <th>Workout</th>
                                <th>Total Sets</th>
                                <th>Total Volume</th>
                            </tr>
                        </thead>
                        <tbody>
                    {userCompletedWorkouts.map((workout, index) => {
                        return (
                            <tr key={index} onClick={() => {
                                setSelectedWorkout(workout);
                                setGotoId('workout-area');
                                setShowLogWorkoutModal(true);
                            }}>
                                <td>{workout.workout_data.workoutName}</td>
                                <td>{workout.workout_data.totalWorkoutSetCount}</td>
                                <td>{workout.workout_data.totalVolume}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                    </Table>
                </Col>
                
                <Col xs={12}>
                    <h2>Muscles Trained</h2>
                    <Table variant='dark' responsive>
                        <thead>
                            <tr>
                                <th>Muscle</th>
                                <th>Sets</th>
                            </tr>
                        </thead>
                        <tbody>
                        {Object.keys(musclesTrainedMap).sort(
                            (a, b) => musclesTrainedMap[b] - musclesTrainedMap[a]
                        ).map((muscle, index) => {
                            return (
                                <tr key={index}>
                                    <td>{muscle}</td>
                                    <td>{musclesTrainedMap[muscle]} sets</td>
                                </tr>
                            )
                            //return <li key={muscle}>{muscle}: {musclesTrainedMap[muscle]} sets</li>
                        })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        )
    }

    console.log(selectedPlannedCardioSession)
    let modal = null;
    if (showLogMealModal){
        modal = <LogMealModal previousMeal={selectedMeal} previousMealIndex={selectedMealIndex} close={closeModal}/>
    } else if (showCheckinModal){
        modal = <CheckinModal close={closeModal} existingCheckin={userDailyCheckin || null}/>
    } else if (showLogWorkoutModal){
        modal = <LogWorkoutModal close={closeModal} workout={selectedWorkout} preplannedWorkout={selectedPlannedWorkout}/>
    } else if (showLogCardioModal){
        modal = <LogCardioModal close={closeModal} cardioSession={selectedCardioSession} plannedCardioSession={selectedPlannedCardioSession}/>
    } else if (showViewWorkoutPlanModal){
        modal = <ViewWorkoutPlanModal close={closeModal}/>
    }

    if (isLoading){
        return (
            <Container className="karved-content-container">
                <Row className="text-center p-5">
                    <h5>Loading...</h5>
                </Row>
            </Container>
        )
    }

    return (
        <Container className="karved-content-container">
            {modal}
            <h1 className="text-center">Dashboard</h1>
            <hr/>
            {buildCheckinArea()}
            {buildWorkoutPlanArea()}
            <Row>
                <Col xs={12} md={6}>
                    {buildMacroArea()}
                </Col>
                <Col xs={12} md={6}>
                {buildWorkoutArea()}
                {buildCardioArea()}
                </Col>
            </Row>
            
        </Container>
    )
}