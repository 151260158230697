import { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Button, Form} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import WorkoutModal from './modals/workout-modal';
import CardioModal from './modals/cardio-modal';
import MuscleScorecardBar from './shared/muscle-scorecard-bar';
import CardioScoreCardBar from './shared/cardio-scorecard-bar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDumbbell, faPencil, faRunning, faSave } from '@fortawesome/free-solid-svg-icons';

export default function WorkoutPlanner(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [workoutPlan, setWorkoutPlan] = useState(() => ({
        workoutPlanName: '',
        workoutPlanDescription: '',
        workoutPlanDurationInWeeks: 0,
        muscleGroupSetMap: {},
        days:[ {
            day: "monday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
            musclesTrained: [],
        },
        {
            day: "tuesday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
            musclesTrained: [],
        },
        {
            day: "wednesday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
        },
        {
            day: "thursday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
            musclesTrained: [],
        },
        {
            day: "friday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
            musclesTrained: [],
        },
        {
            day: "saturday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
        },
        {
            day: "sunday",
            workouts: [],
            cardioSessions: [],
            musclesTrained: [],
            setsForMuscles: {
                chest: 0,
                lats: 0,
                shoulders: 0,
                "middle back": 0,
                "lower back": 0,
                "traps": 0,
                quadriceps: 0,
                hamstrings: 0,
                calves: 0,
                glutes: 0,
                biceps: 0,
                triceps: 0,
                forearms: 0,
                abdominals: 0
            },
        }
    ]
    }));
    const [showWorkoutModal, setShowWorkoutModal] = useState(() => false);
    const [showCardioModal, setShowCardioModal] = useState(() => false);
    const [selectedDayIndex, setSelectedDayIndex] = useState(() => null);
    const [selectedWorkoutIndex, setSelectedWorkoutIndex] = useState(() => -1);
    const [selectedCardioIndex, setSelectedCardioIndex] = useState(() => -1);

    const loadInitialData = async () => {
        await Promise.all([
            api.getAllExercises(),
            api.getUserData(),
            api.getUserIntake()
        ]);

        console.log("Loaded");
    }

    const closeModal = () => {
        setShowWorkoutModal(false);
        setShowCardioModal(false);
        setSelectedCardioIndex(-1);
        setSelectedWorkoutIndex(-1);
        setSelectedDayIndex(null);
    };

    const tallyUpSetsPerDay = (days) => {
        let combinedSetsForMuscles = {};
        days.forEach(day => {
            Object.keys(day.setsForMuscles).forEach(muscle => {
                if (combinedSetsForMuscles[muscle]){
                    combinedSetsForMuscles[muscle] += day.setsForMuscles[muscle];
                } else {
                    combinedSetsForMuscles[muscle] = day.setsForMuscles[muscle];
                }
            })
        });
        return combinedSetsForMuscles;
    }


    const constructDayMuscleGroupSetMap = (workouts) => {
        let muscleGroupSetMap = {};
        workouts.forEach(workout => {
            Object.keys(workout.workoutSetMap).forEach(muscle => {
                if (muscleGroupSetMap[muscle]){
                    muscleGroupSetMap[muscle] += workout.workoutSetMap[muscle];
                } else {
                    muscleGroupSetMap[muscle] = workout.workoutSetMap[muscle];
                }
            })
        });
        
        return muscleGroupSetMap;
    }

    const constructCardioDuration = (cardioSessions) => {
        let totalDuration = 0;
        cardioSessions.forEach(cardio => {
            cardio.activities.forEach(activity => {
                console.log(activity)
                totalDuration += Number(activity.duration);
            })
        });
        return totalDuration;
    }

    const constructTotalCardioDuration = (days) => {
        let totalDuration = 0;
        days.forEach(day => {
            totalDuration += (day.totalCardioDuration || 0);
        });
        return totalDuration;
    }

    const saveWorkout = (workout) => {
        console.log("Saving workout: ", workout);
        console.log("SELECTED INDEX: ", selectedDayIndex);
        let newWorkoutPlan = {...workoutPlan};
        newWorkoutPlan.days[selectedDayIndex].workouts.push(workout);
        newWorkoutPlan.days[selectedDayIndex].setsForMuscles = constructDayMuscleGroupSetMap(newWorkoutPlan.days[selectedDayIndex].workouts);
        newWorkoutPlan.muscleGroupSetMap = tallyUpSetsPerDay(newWorkoutPlan.days);
        setWorkoutPlan(newWorkoutPlan);
        closeModal();
    }

    const updateWorkout = (workout) => {
        console.log("Updating workout: ", workout);
        let newWorkoutPlan = {...workoutPlan};
        newWorkoutPlan.days[selectedDayIndex].workouts[selectedWorkoutIndex] = workout;
        newWorkoutPlan.days[selectedDayIndex].setsForMuscles = constructDayMuscleGroupSetMap(newWorkoutPlan.days[selectedDayIndex].workouts);
        newWorkoutPlan.muscleGroupSetMap = tallyUpSetsPerDay(newWorkoutPlan.days);
        setWorkoutPlan(newWorkoutPlan);
        closeModal();
    }

    const saveCardio = (cardio) => {
        console.log("Saving cardio: ", cardio);
        let newWorkoutPlan = {...workoutPlan};
        newWorkoutPlan.days[selectedDayIndex].cardioSessions.push(cardio);
        newWorkoutPlan.days[selectedDayIndex].totalCardioDuration = constructCardioDuration(newWorkoutPlan.days[selectedDayIndex].cardioSessions);
        newWorkoutPlan.totalCardioDuration = constructTotalCardioDuration(newWorkoutPlan.days);
        console.log(newWorkoutPlan)
        setWorkoutPlan(newWorkoutPlan);
        closeModal();
    }

    const updateCardio = (cardio) => {
        console.log("Updating cardio: ", cardio);
        let newWorkoutPlan = {...workoutPlan};
        newWorkoutPlan.days[selectedDayIndex].cardioSessions[selectedCardioIndex] = cardio;
        newWorkoutPlan.days[selectedDayIndex].totalCardioDuration = constructCardioDuration(newWorkoutPlan.days[selectedDayIndex].cardioSessions);
        newWorkoutPlan.totalCardioDuration = constructTotalCardioDuration(newWorkoutPlan.days);
        console.log(newWorkoutPlan)
        setWorkoutPlan(newWorkoutPlan);
        closeModal();
    }


    const saveWorkoutPlan = async (e) => {
        e.preventDefault();
        console.log("Saving workout plan: ", workoutPlan);
        // GO THROUGH EACH DAY AND MAKE SURE THAT THERE IS AT LEAST ONE WORKOUT OR CADIO SESSION TOTOAL
        let valid = false;
        workoutPlan.days.forEach(day => {
            if (day.workouts.length || day.cardioSessions.length){
                valid = true;
            }
        });

        if (!valid) return;

        const success = await api.saveUserWorkoutPlan({...workoutPlan, workoutPlanData: {...workoutPlan}});
        if (success){
            navigate('/select-workout-plan');
        }
    }

    const buildDay = (workoutDay, index) => {
        const {day, workouts, cardioSessions, musclesTrained, setsForMuscles} = workoutDay;
        let workoutSection = (
            <Col xs="12">
                <h6>Workouts</h6>
                <p>No workouts for this day.</p>
                <Button className="primary-btn" onClick={() => {
                    setSelectedDayIndex(index);
                    setShowWorkoutModal(true);
                }}><FontAwesomeIcon icon={faDumbbell}/><br/> Add Workout</Button>
            </Col>
        );

        let cardioSection = (
            <Col xs="12">
                <h6>Cardio Sessions</h6>
                <p>No cardio sessions for this day.</p>
                <Button className="primary-btn" onClick={() => {
                    setSelectedDayIndex(index);
                    setShowCardioModal(true);
                }}><FontAwesomeIcon icon={faRunning}/> <br/>Add Cardio</Button>
            </Col>
        );
        
        if (workouts.length){
            workoutSection = (
                <Col xs="12">
                    <h6>Workouts</h6>
                    {workouts.map((workout, workoutIndex) => {
                        return (
                            <div key={workoutIndex}>
                                <h6>{workout.workoutName}</h6>
                                <p>{workout.workoutDescription}</p>
                                <p>{workout.exercises.length} Exercises</p>
                                <p>{workout.totalWorkoutSets} Total Sets</p>
                                <div className="text-center">
                                        <Button onClick={() => {
                                            setSelectedDayIndex(index);
                                            setSelectedWorkoutIndex(workoutIndex);
                                            setShowWorkoutModal(true);
                                        }}><FontAwesomeIcon icon={faPencil}/> <br/>Edit</Button>
                                </div>
                            </div>
                        )
                    })}
                    <Button className="primary-btn" onClick={() => {
                        setSelectedDayIndex(index);
                        setShowWorkoutModal(true);
                    }}><FontAwesomeIcon icon={faDumbbell}/><br/>Add Workout</Button>
                </Col>
            )
        } 

        if (cardioSessions.length){
            cardioSection = (
                <Col xs="12">
                    <h6>Cardio Sessions</h6>
                    {cardioSessions.map((cardio, cardioIndex) => {
                        return (
                            <div key={cardioIndex}>
                                <h6>{cardio.cardioWorkoutName}</h6>
                                <p>{cardio.activities.length} Activities</p>
                                <p>{cardio.activities.reduce((sum, n) => sum + Number(n.duration), 0)} Minutes Total</p>
                                <div className="text-center">
                                        <Button onClick={() => {
                                            setSelectedDayIndex(index);
                                            setSelectedCardioIndex(cardioIndex);
                                            setShowCardioModal(true);
                                        }}><FontAwesomeIcon icon={faPencil}/> <br/>Edit</Button>
                                </div>
                            </div>
                        )
                    })}
                    <Button className="primary-btn" onClick={() => {
                        setSelectedDayIndex(index);
                        setShowCardioModal(true);
                    }}><FontAwesomeIcon icon={faRunning}/><br/>Add Cardio</Button>
                </Col>
            )
        }
        return (
            <div className="mb-5 p-2 border-solid-row">
                <h4 className="text-center">{day.toUpperCase()}</h4>
                <hr/>
                <Row className="text-center">
                    {workoutSection}
                    <hr className="mt-3"/>
                    {cardioSection}
                </Row>
            </div>
        )
    }

    const buildWorkoutScoreCard = () => {

        let muscleTargetedState = (
            <div>
                <h6>Schedule Workouts to track muscle stimulous</h6>
                <p>Use the workout planner to schedule workouts and weekly monthly stimulous for each muscle group.</p>
            </div>
        )

        if (Object.keys(workoutPlan.muscleGroupSetMap).length){
            muscleTargetedState = (
                <div>
                    <h6>Weekly Muscle Targeted</h6>
                    <div>
                        {Object.keys(workoutPlan.muscleGroupSetMap).sort((a, b) => {
                            return workoutPlan.muscleGroupSetMap[b] - workoutPlan.muscleGroupSetMap[a];
                        }).map(muscle => {
                            return (
                                <MuscleScorecardBar muscleName={muscle} muscleSets={workoutPlan.muscleGroupSetMap[muscle]}/>
                            )
                        })}
                    </div>
                </div>
            )
        }

        let cardioState = (
            <div>
                <h6>Schedule Cardio sessions to track cardio volume</h6>
                <p>The recommended amount of minimum cardio is 150 minutes a week. (As per the American Heart Association)</p>
            </div>
        )

        if (workoutPlan.totalCardioDuration){
            cardioState = (
                <div>
                    <h6>Weekly Cardio</h6>
                    <CardioScoreCardBar totalCardio={workoutPlan.totalCardioDuration}/>
                </div>
            )
        }

        let emptyState = null;

        if (!workoutPlan.totalCardioDuration && !Object.keys(workoutPlan.muscleGroupSetMap).length){
            emptyState = (
                <div>
                    <h6>Get Started</h6>
                        <p>As you build out your workout plan, we'll:</p>
                        <ul>
                            <li>Display weekly set counts (per muscle group) so that you avoid over and under training.</li>
                            <li>Show you the number of minutes of cardio you have planned out.</li>
                        </ul>
                    <hr/>
                </div>
            )
        }

        return (
            <div>
                <h4 className="text-center mt-5">Workout Scorecard</h4>
                {emptyState}
                {cardioState}
                <hr/>
                {muscleTargetedState}
            </div>
        )
    }

    useEffect(() => {
        loadInitialData();
    }, []);

    let modal = null;

    if (showWorkoutModal && selectedWorkoutIndex > -1){
        modal = <WorkoutModal workout={{...workoutPlan.days[selectedDayIndex].workouts[selectedWorkoutIndex]}} save={updateWorkout} close={closeModal} />
    } else if (showWorkoutModal && selectedWorkoutIndex === -1){
        modal = <WorkoutModal save={saveWorkout} close={closeModal} />
    } else if (showCardioModal && selectedCardioIndex > -1){
        modal = <CardioModal cardio={{...workoutPlan.days[selectedDayIndex].cardioSessions[selectedCardioIndex]}} save={updateCardio} close={closeModal} />
    } else if (showCardioModal && selectedCardioIndex === -1){
        modal = <CardioModal save={saveCardio} close={closeModal} />
    }


    return (
        <Container className="karved-content-container">
            {modal}
            <Form onSubmit={saveWorkoutPlan}>
                <Form.Group className="mt-3">
                    <Form.Label>Workout Plan Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter workout plan name" value={workoutPlan.workoutPlanName} onChange={(e) => setWorkoutPlan({...workoutPlan, workoutPlanName: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mt-3">
                    <Form.Label>Workout Plan Description</Form.Label>
                    <Form.Control type="text" placeholder="Enter workout plan description" value={workoutPlan.workoutPlanDescription} onChange={(e) => setWorkoutPlan({...workoutPlan, workoutPlanDescription: e.target.value})}/>
                </Form.Group>
                <Form.Group className="mt-3">
                    <Form.Label>Duration in Weeks</Form.Label>
                    <Form.Control min={1} max={26} type="number" placeholder="Enter workout plan duration in weeks" value={workoutPlan.workoutPlanDurationInWeeks} onChange={(e) => setWorkoutPlan({...workoutPlan, workoutPlanDurationInWeeks: e.target.value})}/>
                </Form.Group>
                <Row>
                    <Col xs={12} md={6}>
                        <h4 className="text-center mt-5">Your Workout Plan</h4>
                        {workoutPlan.days.map((day, index) => buildDay(day, index))}
                    </Col>
                    <Col xs={12} md={6}>
                        
                        {buildWorkoutScoreCard()}
                    </Col>
                </Row>

                <div className="text-center mt-5">
                    <hr/>
                    <Button className="primary-btn" type="submit" variant="success"><FontAwesomeIcon icon={faSave} /><br/> Save Workout Plan</Button>
                </div>
            </Form>
        </Container>
    )
}