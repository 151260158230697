import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';

export default combineReducers({
    activeAlerts: (state = [], action) => {
        switch(action.type){
            case ACTIONS.ADD_ALERT:
                return [action.payload, ...state];
            case ACTIONS.REMOVE_ALERT:
                return state.filter((alert, index) => index !== action.payload);
            case ACTIONS.REMOVE_ALL_ALERTS:
                return [];
            default:
                return state;
        }
    }
});