import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';
const defaultWeeklyStats = {
    totalWeeklyVolume: 0,
    totalWeeklySets: 0,
    totalWeeklyWorkouts: 0, 
    averageSetsPerWorkout: 0,
    averageVolumePerWorkout: 0,
    workoutDateDataVolumeMap: {},
    muscleVolumeMap: {}
}
export default combineReducers({
    userData: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_DATA:
                return action.payload;
            case ACTIONS.REMOVE_USER_DATA:
                return null;
            default:
                return state;
        }
    },
    userIntake: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_INTAKE:
                return action.payload;
            default:
                return state;
        }
    },
    userDailyMacros: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_DAILY_MACROS:
                return action.payload;
            default:
                return state;
        }
    },
    userDailyNutrientIntake: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_DAILY_NUTRIENT_INTAKE:
                return action.payload;
            default:
                return state;
        }
    },
    userDailyCheckin: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_DAILY_CHECKIN:
                return action.payload;
            default:
                return state;
        }
    },
    userDailyCheckinLastSevenDays: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_USER_DAILY_CHECKIN_LAST_SEVEN_DAYS:
                return action.payload;
            default:
                return state;
        }
    },
    userSavedMeals: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_USER_SAVED_MEALS:
                return action.payload;
            default:
                return state;
        }
    },
    userCompletedWorkouts: (state = [], action) => { 
        switch(action.type){
            case ACTIONS.SET_USER_COMPLETED_WORKOUTS:
                return action.payload;
            default:
                return state;
        }
    },
    userCompletedCardioSessions: (state = [], action) => {
        switch(action.type){
            case ACTIONS.SET_USER_CARDIO_WORKOUTS:
                return action.payload;
            default:
                return state;
        }
    },
    userWeeklyReportCard: (state = null, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_WEEKLY_REPORT_CARD:
                return action.payload;
            default:
                return state;
        }
    },
    userWeeklyStats: (state = defaultWeeklyStats, action) => {
        switch(action.type){
            case ACTIONS.SET_USER_WEEKLY_STATS:
                return action.payload;
            default:
                return state;
        }
    }
});