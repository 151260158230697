import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useRef, useState, useEffect } from "react";

export default function KarvedUpTooltip({ children, customButton = null, buttonProps = {}, style = {} }) {
    const [placement, setPlacement] = useState("top");
    const buttonRef = useRef(null);

    useEffect(() => {
        const handlePlacement = () => {
            if (buttonRef.current) {
                const rect = buttonRef.current.getBoundingClientRect();
                const { top, bottom } = rect;
                const viewportHeight = window.innerHeight;

                // Update placement based on position within the viewport
                if (top < viewportHeight / 2) {
                    setPlacement("bottom"); // Element is near the top
                } else {
                    setPlacement("top"); // Element is near the bottom
                }
            }
        };

        // Attach event listeners for resize and scroll
        window.addEventListener("resize", handlePlacement);
        window.addEventListener("scroll", handlePlacement);

        // Initial placement calculation
        handlePlacement();

        // Cleanup listeners on component unmount
        return () => {
            window.removeEventListener("resize", handlePlacement);
            window.removeEventListener("scroll", handlePlacement);
        };
    }, []);
    // Icon should be direct center of button
    const button = customButton ? <div ref={buttonRef}>{customButton}</div> : (
        <Button
            ref={buttonRef}
            variant="outline-light"
            style={{ borderRadius: "50%", width: 25, height: 25, textAlign: 'center', position: 'relative', ...style }}
            {...buttonProps}
        >
            <FontAwesomeIcon style={{position: 'absolute', left: 7, bottom: 4}} icon={faQuestion} />
        </Button>
    );
    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Tooltip>
                    {children}
                </Tooltip>
            }
        >
            {button}
        </OverlayTrigger>
    );
}
