import {combineReducers} from 'redux';
import * as ACTIONS from '../constants/actions';


export default combineReducers({
    usdaResults: (state = [], action) => {
        switch (action.type) {
            case ACTIONS.SET_USDA_RESULTS:
                return action.payload;
            default:
                return state;
        }
    },
    userRecipes: (state = [], action) => {
        switch (action.type) {
            case ACTIONS.SET_USER_MUSCLE_RECIPES:
                return action.payload;
            default:
                return state;
        }
    }
});