import { combineReducers } from 'redux';

export default combineReducers({
    user_workout_plans: (state = [], action) => {
        switch(action.type){
            case 'SET_USER_WORKOUT_PLANS':
                return action.payload;
            default:
                return state;
        }
    },
    premium_workout_plans: (state = [], action) => {
        switch(action.type){
            case 'SET_PREMIUM_WORKOUT_PLANS':
                return action.payload;
            default:
                return state;
        }
    },
    user_active_workout_plan: (state = null, action) => {
        switch(action.type){
            case 'SET_USER_ACTIVE_WORKOUT_PLAN':
                return action.payload;
            default:
                return state;
        }
    },
    exercises: (state = [], action) => {
        switch(action.type){
            case 'SET_ALL_EXERCISES':
                return action.payload;
            default:
                return state;
        }
    }
});