import {Modal, Button, Form} from 'react-bootstrap';
import {useState, useMemo} from 'react';
import { useDispatch } from 'react-redux';
import API from '../../../helpers/api';

export default function ReportBugModal({close}) {
    const dispatch = useDispatch();
    const api = useMemo(() => new API(dispatch), [dispatch]);
    const [bug, setBug] = useState(() => ({
        bug_description: '',
        bug_steps_to_reproduce: '',
        contact_user_when_fixed: false
    }));
    const [responseFromServer, setResponseFromServer] = useState(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [saving, setSaving] = useState(false);

    const handleInputChange = (e) => {
        setBug({
            ...bug,
            [e.target.name]: e.target.value
        });
    }

    const handleCheckboxChange = (e) => {
        setBug({
            ...bug,
            [e.target.name]: e.target.checked
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSaving(true);
        try {
            const response = await api.reportBug(bug);
            setResponseFromServer(response);
            setShowSuccess(true);
        } catch (e) {
            setResponseFromServer(e);
        } finally {
            setSaving(false);
        }
    }

    const buildForm = () => {
        if (responseFromServer){
            return (
                <div>
                    <h3>Our Team has received your feedback!</h3>
                    <hr/>
                    <p>{responseFromServer}</p>
                    <hr/>
                    <div className="text-center">
                        <Button onClick={close}>Continue Using App</Button>
                    </div>
                </div>
            )
        }
        if (saving) return (
            <div>
                <h3>Saving...</h3>
                <hr/>
                <p>Please wait as our system is contacting the proper team members.</p>
            </div>
        )

        return (
            <div>
                
                <p>Having an issue? Want a feature that is not available on the app? Tell us what we can do better down below.</p>
                <hr/>
                <Form onSubmit={handleSubmit}>
                    <Form.Group>
                        <h4>Bug Description</h4>
                        <p>Describe the bug we need to fix or the feature that you'd like on the app.</p>
                        <Form.Control as="textarea" name="bug_description" value={bug.bug_description} onChange={handleInputChange} required/>
                    </Form.Group>
                    <hr/>
                    <Form.Group>
                        <h4>Steps to Reproduce</h4>
                        <p>If you're reporting a bug, tell us where the bug happens on the site and how we can replicate.</p>
                        <Form.Control as="textarea" name="bug_steps_to_reproduce" value={bug.bug_steps_to_reproduce} onChange={handleInputChange}/>
                    </Form.Group>
                    <hr/>
                    <Form.Group>
                        <Form.Check type="checkbox" label="Check if your want us to contact you when the bug has been fixed" name="contact_user_when_fixed" checked={bug.contact_user_when_fixed} onChange={handleCheckboxChange}/>
                    </Form.Group>
                    <hr/>
                    <div className="text-center">
                        <Button type="submit">Submit Request</Button>
                    </div>
                </Form>
            </div>
        )
    }

    return(
        <Modal show={true} onHide={close}>
            <Modal.Header className="karved-up-modal" closeButton>
                <Modal.Title>Report a Bug / Request a Feature</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                {buildForm()}
            </Modal.Body>
        </Modal>
    )


}
