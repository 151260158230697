import {Container, Row, Col, Button, Card, Table} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import API from '../../helpers/api';
import {useSelector, useDispatch} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

export default function StartTheProgram(){
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const user = useSelector(state => state.user.userData);
    const [creatingWorkoutPlan, setCreatingWorkoutPlan] = useState(() => false);
    const [workoutPlanCreated, setWorkoutPlanCreated] = useState(() => false);
    const [workoutPlanCreationError, setWorkoutPlanCreationError] = useState(() => null);
    const [creatingRecipes, setCreatingRecipes] = useState(() => false);
    const [recipesCreated, setRecipesCreated] = useState(() => false);
    const [recipesCreationError, setRecipesCreationError] = useState(() => null);
    const [generatedWorkoutPlan, setGeneratedWorkoutPlan] = useState(() => null);
    const [generatedRecipes, setGeneratedRecipes] = useState(() => null);

    const kickoffWorkoutPlan = async () => {
        setCreatingWorkoutPlan(true);
        const userActiveWorkout = await api.getUserActiveWorkoutPlan();

        if (userActiveWorkout) {
            //NO NEED TO CREATE ONE, THEY ALREADY HAVE IT
            setWorkoutPlanCreated(true);
            setCreatingWorkoutPlan(false);
            setGeneratedWorkoutPlan(userActiveWorkout);
            return;
        }

        const userIntake = await api.getUserIntake();
        //GENERATE THE WORKOUT PLAN
        const generatedWorkout = await api.generateFullWorkoutPlan({
            fitness_level: userIntake.fitness_level,
            cardio_level: userIntake.cardio_level,
            goal: userIntake.goal.toLowerCase() === 'gain'? 'Build Muscle' : 'Lose Fat',
            equipment_access_map: {...userIntake.equipment_access},
            preferred_split_map: {...userIntake.training_preferences.workout_splits},
            improved_muscle_map: {...userIntake.training_preferences.body_part_focus},
            workout_availability_map: {...userIntake.training_preferences.workout_day_availability},
            injury_notes: userIntake.training_preferences.injury_history,
            exercise_notes: userIntake.training_preferences.workout_notes,
            dob: moment(userIntake.dob).format("MM/DD/YYYY"),
        });

        if(generatedWorkout) {
            setGeneratedWorkoutPlan(generatedWorkout);
            setWorkoutPlanCreated(true);
            setCreatingWorkoutPlan(false);
        }
        
    }

    const kickoffRecipePlan = async () => {
        setCreatingRecipes(true);
        const userIntake = await api.getUserIntake();

        let recipes = await api.getUserMuscleRecipes();

        if (recipes && recipes.length > 0){
           
            setGeneratedRecipes(recipes);
            setRecipesCreated(true);
            setCreatingRecipes(false);
            return;
        }

        recipes = await api.generateMuscleRecipesForUser({
            ...userIntake.nutrition_preferences,
        });

        if (recipes){
            
            setGeneratedRecipes(recipes);
            setRecipesCreated(true);
            setCreatingRecipes(false);
        }
    }

    const buildGeneratedWorkoutRow = () => {
        if (!generatedWorkoutPlan) return null;
        console.log(generatedWorkoutPlan);
        return (
            <Row>
                <Card>
                    <Card.Body>
                        <h4>{generatedWorkoutPlan.workout_plan_name}</h4>
                        <h6>{generatedWorkoutPlan.workout_plan_duration_in_weeks} Week Duration</h6>
                        <p>{generatedWorkoutPlan.workout_plan_description}</p>
                        <hr/>
                        <div>
                            <h5>Workout Plan Schedule</h5>

                            <Table variant="dark" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Day</th>
                                        <th>Workout Name</th>
                                        <th>Exercises</th>
                                        <th>Sets</th>
                                        <th>Cardio</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {generatedWorkoutPlan.workout_plan_data.days.map((day, index) => {
                                        const workout = day.workouts[0];
                                        return (
                                            <tr key={index}>
                                                <td>{day.day}</td>
                                                <td>{workout && workout.workoutName ? workout.workoutName : 'OFF'}</td>
                                                <td>{workout && workout.exercises ? workout.exercises.length : 'OFF'}</td>
                                                <td>{workout && workout.totalWorkoutSets ? workout.totalWorkoutSets : 'OFF'}</td>
                                                <td>{day && day.cardioSessions && day.cardioSessions.length ? 'Yes' : 'No'}</td>
                                            </tr>
                                        )
    })}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        )
    }

    const buildWorkoutCreationRow = () => {
        if(workoutPlanCreated) {
            return (
                <Row>
                    <Col>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Your Workout Plan has been Created!
                        </p>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faCheckCircle} /> - Creating Your Custom Workout Plan...
                        </p>
                    </Col>
                </Row>
            )
        }
    }

    const buildRecipesCreationRow = () => {
        if(recipesCreated) {
            return (
                <Row>
                    <Col>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Your Recipes have Been Created!
                        </p>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row>
                    <Col>
                        <p className="text-center">
                            <FontAwesomeIcon icon={faCheckCircle} /> - Creating Your Custom Recipes...
                        </p>
                    </Col>
                </Row>
            )
        }
    }

    const buildGeneratedRecipesRow = () => {
        if (!generatedRecipes) return null;
        console.log("RECIPES", generatedRecipes);
        return (
            <Row  className="mt-5">
                <Card>
                    <Card.Body>
                <h4 className="text-center">Your Custom Recipes</h4>
                <p>Below are {generatedRecipes.length} recipes customized for you! Your Muscle Cook Book will grow every month that you are a paying member.</p>
                <Row>
                {generatedRecipes.map((recipe, index) => {
                    return (
                        <Col key={index} xs={12} md={6}>
                            <Card>
                                <Card.Body>

                                    <h4>{recipe.recipe_name}</h4>
                                    <p>{recipe.recipe_description}</p>
                                    <div>
                                        <hr/>
                                        <p><strong>Serving Size:</strong> {recipe.serving_size}</p>
                                        <p><strong>Calories:</strong> {recipe.calories_per_serving}<br/>
                                           <strong>Protein:</strong> {recipe.protein_per_serving}g<br/>
                                           <strong>Carbs:</strong> {recipe.carbohydrate_per_serving}g <br/>
                                           <strong>Fat:</strong> {recipe.fat_per_serving}g</p>
                                    </div>
                                    <hr/>
                                    <h5>Ingredients</h5>
                                    <ul>
                                        {recipe.ingredients.map((ingredient, index) => {
                                            return (
                                                <li key={index}>{ingredient}</li>
                                            )
                                        })}
                                    </ul>
                                    <hr/>
                                    <h5>Instructions</h5>
                                    <ul>
                                        {recipe.cooking_instructions.map((instruction, index) => {
                                            return (
                                                <li key={index}>{instruction}</li>
                                            )
                                        })}
                                    </ul>
                                    <hr/>
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    )
                })}
                </Row>
                </Card.Body>
                </Card>
            </Row>
                
        )
    }

    useEffect(() => {
        //Kick Off the User Integration Process
        kickoffWorkoutPlan();
        kickoffRecipePlan();
        window.scrollTo(0, 0);
    }, [])
    return (
        <Container className="karved-content-container">
            <Row>
                <Col>
                    <h3 className="text-center">Let's Get Started!</h3>
                    <p className="text-center">
                        (We are working on getting your workout plan and recipes created. Sit tight as this may take a few minutes.)
                    </p>
                    <hr/>
                </Col>
            </Row>
            {buildWorkoutCreationRow()}
            {buildRecipesCreationRow()}
            {(generatedRecipes && generatedWorkoutPlan) && (
                <Row>
                    <Col>
                        <p className="text-center">
                            <Button  onClick={() => navigate('/dashboard/')}>Begin Your Journey</Button>
                            <br/> <br/>
                            (Or you can view your workout plan and recipes below)
                        </p>
                    </Col>
                </Row>
            ) }
            <hr/>
            {buildGeneratedWorkoutRow()}
            {buildGeneratedRecipesRow()}    
        </Container>
    )
}