export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERTS = 'REMOVE_ALL_ALERTS';
export const SET_USER_INTAKE = 'SET_USER_INTAKE';
export const SET_USER_DAILY_MACROS = 'SET_USER_DAILY_MACROS';
export const SET_USER_DAILY_NUTRIENT_INTAKE = 'SET_USER_DAILY_NUTRIENT_INTAKE';
export const SET_USER_DAILY_CHECKIN = 'SET_USER_DAILY_CHECKIN';
export const SET_USER_DAILY_CHECKIN_LAST_SEVEN_DAYS = 'SET_USER_DAILY_CHECKIN_LAST_SEVEN_DAYS';
export const SET_USDA_RESULTS = 'SET_USDA_RESULTS';
export const SET_USER_SAVED_MEALS = 'SET_USER_SAVED_MEALS';
export const SET_USER_CHECKIN_HISTORY = 'SET_USER_CHECKIN_HISTORY';
export const SET_USER_COMPLETED_WORKOUTS = 'SET_USER_COMPLETED_WORKOUTS';
export const SET_USER_WORKOUT_PLANS = 'SET_USER_WORKOUT_PLANS';
export const SET_USER_ACTIVE_WORKOUT_PLAN = 'SET_USER_ACTIVE_WORKOUT_PLAN';
export const SET_USER_CARDIO_WORKOUTS = 'SET_USER_CARDIO_WORKOUTS';
export const SET_USER_WEEKLY_REPORT_CARD = 'SET_USER_WEEKLY_REPORT_CARD';
export const SET_ALL_EXERCISES = 'SET_ALL_EXERCISES';
export const SET_PREMIUM_WORKOUT_PLANS = 'SET_PREMIUM_WORKOUT_PLANS';
export const SET_USER_MUSCLE_RECIPES = 'SET_USER_MUSCLE_RECIPES';
export const SET_USER_WEEKLY_STATS = 'SET_USER_WEEKLY_STATS';