import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import API from '../../helpers/api';
import {faBug } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';
import ReportBugModal from './modals/report-bug-modal';

export default function ReportBugButton() {
    const [showModal, setShowModal] = useState(() => false);
    const user = useSelector(state => state.user.userData);

    if (!user) return null;

    let modal = null;

    if (showModal){
        modal = <ReportBugModal close={() => setShowModal(false)} />
    }

    return (
        <div className="report-bug-button">
            {modal}
            <Button  variant="danger" onClick={() => setShowModal(true)}>
                <FontAwesomeIcon icon={faBug}  />
            </Button>
        </div>
    )
}