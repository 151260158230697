import {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col, Form, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import moment from 'moment';
import workout from '../../reducers/workout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import KarvedUpTooltip from '../shared/karved-up-tooltip';

const equipmentImageMap = {
    commercial_gym: '/buttons/equipment-options/Commercial-Gym.png',
    dumbbells: '/buttons/equipment-options/Dumbbells.png',
    barbell: '/buttons/equipment-options/Barbell.png',
    kettlebells: '/buttons/equipment-options/Kettlebells.png',
    resistance_bands: '/buttons/equipment-options/Resistance-Bands.png',
    none: '/buttons/equipment-options/None.png'
};

const muscleGroupImageMap = {
    chest: '/bodypart-icons/chest-icon.png',
    back: '/bodypart-icons/back-icon.png',
    quadriceps: '/bodypart-icons/quadriceps-icon.png',
    hamstrings: '/bodypart-icons/hamstrings-icon.png',
    abdominals: '/bodypart-icons/abdominals-icon.png',
    glutes: '/bodypart-icons/glutes-icon.png',
    shoulders: '/bodypart-icons/shoulders-icon.png',
    biceps: '/bodypart-icons/biceps-icon.png',
    triceps: '/bodypart-icons/triceps-icon.png',
    traps: '/bodypart-icons/traps-icon.png'
}

const helpMap = {
    splits: {
        push_pull_legs: 'Push Pull Legs is a workout split that focuses on training different muscle groups on different days. For example, on Push day you would train chest, shoulders, and triceps. On Pull day you would train back and biceps. On Leg day you would train quadriceps, hamstrings, and glutes.',
        full_body: 'Full Body is a workout split that focuses on training all major muscle groups in a single workout. This is a great option for beginners or those who have limited time to workout.',
        upper_lower: 'Upper Lower is a workout split that focuses on training the upper body on one day and the lower body on another day. This is a great option for those who want to train more frequently but still allow for adequate recovery.',
        traditional: 'Traditional is a workout split that focuses on training one muscle group per day. For example, on Chest day you would only train chest. This is a great option for those who want to focus on specific muscle groups.',
        no_preference: 'No Preference is a workout split that allows us to generate a workout plan based on your other preferences.'
    }
}

export default function WorkoutInformation(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(() => false);
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [workoutInformation, setWorkoutInformation] = useState(() => ({
        fitness_level: 0,
        cardio_level: 0,
        equipment_access: {
            commercial_gym: false,
            dumbbells: false,
            barbell: false,
            kettlebells: false,
            resistance_bands: false,
            none: false
        },
        training_preferences: {
            workout_splits: {
                push_pull_legs: false,
                full_body: false,
                upper_lower: false,
                traditional: false,
                no_preference: false
            },
            body_part_focus: {
                chest: false,
                back: false,
                quadriceps: false,
                hamstrings: false,
                abdominals: false,
                glutes: false,
                shoulders: false,
                biceps: false,
                triceps: false,
                traps: false
            },
            workout_day_availability: {
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false
            },
            injury_history: 'N/A',
            workout_notes: 'N/A',
        }
    }));

    useEffect(() => {
        ///START AT TOP OF PAGE
        window.scrollTo(0, 0);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        //Validations
        if (workoutInformation.fitness_level == 0 || workoutInformation.cardio_level == 0) return;
        if (!Object.values(workoutInformation.equipment_access).includes(true)) return;
        if (!Object.values(workoutInformation.training_preferences.workout_splits).includes(true)) return;
        if (!Object.values(workoutInformation.training_preferences.body_part_focus).includes(true)) return;
        setIsSaving(true);
        const response = await api.upsertUserIntake(workoutInformation);
        if (response) {
            navigate('/user-intake/nutrition');
            return
        }
        setIsSaving(false);
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            console.log("SCROLLED")
        }, 250)
    }

    const scrollToDiv = (id) => {
        setTimeout(() => {
            const element = document.getElementById(id);
            if (!element) return;
            element.scrollIntoView({behavior: "smooth"});
        }, 250)
    }

    const handleEquipmentChange = (key, value, divId) => {
        let newEquipmentAccess = {...workoutInformation.equipment_access};
        newEquipmentAccess[key] = value;
        if (key == 'none' && value){
            //If none is selected, unselect all other options
            Object.keys(newEquipmentAccess).forEach((k) => {
                if (k != 'none'){
                    newEquipmentAccess[k] = false;
                }
            })
        } else if (key != 'none' && value){
            //If none is selected and another option is selected, unselect none
            newEquipmentAccess['none'] = false;
        }


        setWorkoutInformation({...workoutInformation, equipment_access: newEquipmentAccess});
        scrollToDiv(divId);
    }

    const handleWorkoutSplitChange = (key, value, divId) => { 
        const newWorkoutSplits = {...workoutInformation.training_preferences.workout_splits};
        newWorkoutSplits[key] = value;

        if (key == 'no_preference' && value){
            //If no preference is selected, unselect all other options
            Object.keys(newWorkoutSplits).forEach((k) => {
                if (k != 'no_preference'){
                    newWorkoutSplits[k] = false;
                }
            })
        } else if (key != 'no_preference' && value){
            //If no preference is selected and another option is selected, unselect no preference
            newWorkoutSplits['no_preference'] = false;
        }
        setWorkoutInformation({...workoutInformation, training_preferences: {...workoutInformation.training_preferences, workout_splits: newWorkoutSplits}});
        scrollToDiv(divId);
    }

    const handleBodyPartFocusChange = (key, value, divId) => {
        setWorkoutInformation({...workoutInformation, training_preferences: {...workoutInformation.training_preferences, body_part_focus: {...workoutInformation.training_preferences.body_part_focus, [key]: value}}});
        scrollToDiv(divId);
    }

    const buildFitnessLevelRow = () => {
        const beginnerClass = `generic-button ${workoutInformation.fitness_level == 1 ? 'selected' : ''}`;
        const intermediateClass = `generic-button ${workoutInformation.fitness_level == 2 ? 'selected' : ''}`;
        const advancedClass = `generic-button ${workoutInformation.fitness_level == 3 ? 'selected' : ''}`;

        return (
            <Row className="mt-5 karved-up-row">
                <h5 className="mb-3">How would you rate your experience when it comes to Resistance Training
                   {'  '}
                    <KarvedUpTooltip>
                                <p style={{color: '#fff'}}>
                                   <strong>Beginner</strong> is someone who has either never lifted weights or has very little experience. <br/><br/>
                                   <strong>Intermediate</strong> is someone who has been lifting weights for a while and is comfortable with most exercises. <br/><br/>
                                   <strong>Advanced</strong> is someone who has been lifting weights for a long time and is comfortable with all exercises.

                                </p>
                    </KarvedUpTooltip>
                    </h5>
                <p>(e.g. Weight Lifting, Kettlebell Workouts, etc.) 
                </p>
                <Col className={["text-center", beginnerClass]} onClick={() => setWorkoutInformation({...workoutInformation, fitness_level: 1})}>
                    <img className="img-fluid" src="/buttons/level-buttons/BEGINNER.png"/>
                </Col>
                <Col className={["text-center", intermediateClass]} onClick={() => setWorkoutInformation({...workoutInformation, fitness_level: 2})}>
                    <img className="img-fluid" src="/buttons/level-buttons/INTERMEDIATE.png"/>
                </Col>
                <Col className={["text-center", advancedClass]} onClick={() => setWorkoutInformation({...workoutInformation, fitness_level: 3})}>
                    <img className="img-fluid" src="/buttons/level-buttons/ADVANCED.png"/>
                </Col>
                
            </Row>
        )
    }

    const buildCardioLevelRow = () => {
        if (workoutInformation.fitness_level == 0) return null;
        const beginnerClass = `generic-button ${workoutInformation.cardio_level == 1 ? 'selected' : ''}`;
        const intermediateClass = `generic-button ${workoutInformation.cardio_level == 2 ? 'selected' : ''}`;
        const advancedClass = `generic-button ${workoutInformation.cardio_level == 3 ? 'selected' : ''}`;

        return (
            <Row className="mt-5 karved-up-row">
                <h5 className="mb-3">How would your rate your Cardio? {' '}
                   
                           <KarvedUpTooltip>
                                <p style={{color: '#fff'}}>
                                   <strong>Beginner</strong> is someone sedentary or does light cardio a few times a week.<br/><br/>
                                   <strong>Intermediate</strong> is someone who does moderate cardio 3-6 days a week. <br/><br/>
                                   <strong>Advanced</strong> is someone who is very active and does intense cardio 5-7 days a week.

                                </p>
                       </KarvedUpTooltip>
                 </h5>
                    
                <p>(e.g. Ability to walk for long periods of time, running medium distances, etc.)</p>
                <Col className={["text-center", beginnerClass]} onClick={() => setWorkoutInformation({...workoutInformation, cardio_level:1})}>
                    <img className="img-fluid" src="/buttons/level-buttons/BEGINNER.png"/>
                </Col>
                <Col className={["text-center", intermediateClass]} onClick={() => setWorkoutInformation({...workoutInformation, cardio_level:2})}>
                    <img className="img-fluid" src="/buttons/level-buttons/INTERMEDIATE.png"/>
                </Col>
                <Col className={["text-center", advancedClass]} onClick={() => setWorkoutInformation({...workoutInformation, cardio_level:3})}>
                    <img className="img-fluid" src="/buttons/level-buttons/ADVANCED.png"/>
                </Col>
            </Row>
        )
    }

    const buildEquipmentAccessRow = () => {
        if (workoutInformation.cardio_level == 0) return null;
        return(
            <Row className='mt-5 karved-up-row'>
                <h5 className="mb-3">What equipment do you have access to?</h5>
                {
                    Object.keys(workoutInformation.equipment_access).map((key, index) => {
                        const className = `generic-button ${workoutInformation.equipment_access[key] ? 'selected' : ''}`;
                        const value = workoutInformation.equipment_access[key];
                        return (
                            <Col className={['text-center', className]} 
                                 key={index} xs={6} sm={6} md={4} lg={4}
                                 onClick={() => handleEquipmentChange(key, !value)}>
                                <img src={equipmentImageMap[key]} className="img-fluid"/><br/>
                                <p>{key.replace(/_/gi, ' ').toUpperCase()}</p>
                            </Col>
                        )
                    })
                }
                
            </Row>
        )
    }

    const buildWorkoutSplitRow = () => {
        //Check that atleast one gym access has been selected
        if (!Object.values(workoutInformation.equipment_access).includes(true)) return null;
        return (
            <Row className='mt-5 karved-up-row'>
                <h5 className="mb-3">Which workout splits do you prefer?</h5>
                <p>(Select Atleast One, pick ALL that apply)</p>
                {
                    Object.keys(workoutInformation.training_preferences.workout_splits).map((key, index) => {
                        
                        let variant = workoutInformation.training_preferences.workout_splits[key] ? 'primary' : 'secondary';
                        return (
                            <Col key={index} className="text-center mt-1"  xs={6} sm={6} md={4} lg={4}>
                                <KarvedUpTooltip
                                    customButton={(
                                        <Button variant={variant} onClick={() => handleWorkoutSplitChange(key, !workoutInformation.training_preferences.workout_splits[key])}>
                                            {key.replace(/_/gi, ' ').toUpperCase()}
                                        </Button>
                                    )}
                                >
                                    <p style={{color: '#fff'}}>
                                                    {helpMap.splits[key]}
                                                </p>

                                </KarvedUpTooltip>
                                
                            </Col>
                        )
                    })
                }
            </Row>
        )
    }

    const buildBodyPartFocusRow = () => {
        //Check that atleast one gym access has been selected
        if (!Object.values(workoutInformation.training_preferences.workout_splits).includes(true)) return null;
        return (
            <Row className='mt-5 karved-up-row'>
                <h5 className="mb-3">Which muscles do you want to improve the most? {' '}
                    <KarvedUpTooltip>
                    <p style={{color: '#fff'}}>
                                    Muscle Groups that you want to focus on the most. <br/><br/>
                                    All muscles (not injured) will be trained, but these muscle groups will be prioritized.
                                </p>
                    </KarvedUpTooltip>
                </h5>
                <p>(Select Atleast One, pick ALL that apply)</p>

                {
                    Object.keys(workoutInformation.training_preferences.body_part_focus).map((key, index) => {
                        const className = `generic-button ${workoutInformation.training_preferences.body_part_focus[key] ? 'selected' : ''}`;
                        const value = workoutInformation.training_preferences.body_part_focus[key];

                        return (
                            <Col className={['text-center', className]} 
                                 key={index} xs={6} sm={6} md={4} lg={4}
                                 onClick={() => handleBodyPartFocusChange(key, !value)}>
                                <img src={muscleGroupImageMap[key]} className="img-fluid"/><br/>
                                <p>{key.replace(/_/gi, ' ').toUpperCase()}</p>
                            </Col>
                        )
                    })
                }
                
            </Row>
        )
    }

    const workoutDayAvailabilityRow = () => {
        if (!Object.values(workoutInformation.training_preferences.body_part_focus).includes(true)) return null;
        return (
            <Row className='mt-5 karved-up-row'>
                <h5 className="mb-3">Which days are you available to workout? {' '}
                    <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                    Days that you are available to workout. <br/><br/>
                                    We will generate a workout plan that fits your schedule.
                            </p>
                    </KarvedUpTooltip>
                    </h5>
                <p>(Select Atleast One, pick ALL that apply)</p>
                {
                    Object.keys(workoutInformation.training_preferences.workout_day_availability).map((key, index) => {
                        const variant = workoutInformation.training_preferences.workout_day_availability[key] ? 'primary' : 'secondary';
                        return (
                            <Col key={index}  xs={6} sm={6} md={4} lg={4} className="text-center mt-1">
                                <Button variant={variant} onClick={() => setWorkoutInformation({...workoutInformation, training_preferences: {...workoutInformation.training_preferences, workout_day_availability: {...workoutInformation.training_preferences.workout_day_availability, [key]: !workoutInformation.training_preferences.workout_day_availability[key]}}})}>
                                    {key.replace(/_/gi, ' ').toUpperCase()}
                                </Button>
                              
                            </Col>
                        )
                    })
                }
            </Row>
        )
    }

    const historyInjuryRow = () => {
        if (!Object.values(workoutInformation.training_preferences.body_part_focus).includes(true)) return null;
        return (
            <Row className='mt-5 karved-up-row'>
                <h5 className="mb-3">Do you have any injuries we should be aware of? {' '}
                    <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                    Injuries that you have had in the past or are currently dealing with. <br/><br/>
                                    We will generate a workout plan that avoids exercises that may exacerbate your injuries.
                                    Keep in mind that all exercise programs should be reviewed by a qualified professional before starting.

                            </p>
                    </KarvedUpTooltip>
                </h5>
                <p>(e.g. Knee Pain, Shoulder Pain, etc.)</p>
                <Form.Control as="textarea" rows={3} value={workoutInformation.training_preferences.injury_history} onChange={(e) => setWorkoutInformation({...workoutInformation, training_preferences: {...workoutInformation.training_preferences, injury_history: e.target.value}})} required/>
            </Row>
        )
    }

    const workoutNotesRow = () => {
        if (!Object.values(workoutInformation.training_preferences.body_part_focus).includes(true)) return null;
        return (
            <Row className='mt-5 karved-up-row'>
                <h5 className="mb-3">Is there anything specific you would like included or excluded from the workout? (we will do our best to facilitate) {' '}
                    <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                    Any additional information that you would like to provide. <br/><br/>
                                    We will generate a workout plan that fits your preferences.
                                    Keep in mind that all exercise programs should be reviewed by a qualified professional before starting.

                            </p>
                    </KarvedUpTooltip>
                </h5>
                <p>(e.g. Goals, Preferences, etc.)</p>
                <Form.Control as="textarea" rows={3} value={workoutInformation.training_preferences.workout_notes} onChange={(e) => setWorkoutInformation({...workoutInformation, training_preferences: {...workoutInformation.training_preferences, workout_notes: e.target.value}})} required/>
            </Row>
        )
    }

    const buildSubmitButton = () => {
        if (!Object.values(workoutInformation.training_preferences.body_part_focus).includes(true)) return null;
        return (
            <Row className='mt-5 karved-up-row'>
                <Button disabled={isSaving} className="primary-btn" type="submit" onClick={handleSubmit}>
                    {isSaving ? 'Saving... Hang Tight!' : 'Continue'}
                </Button>

                <p className="mt-5">
                   * - Your workout will be generated based on the information you provided, after you have successfully completed the rest of the onboarding process. <br/><br/>
                   * - All exercise programs should be reviewed by a qualified professional before starting. <br/> 
                </p>
            </Row>
        )
    }

    return (
        <Container className="mb-5 karved-content-container">
            <Row>
                <h1 className="text-center">Let's Generate Your Workout</h1>
                <h6 className="text-center">As part of your membership you get a custom workout plan every 8-12 weeks.</h6>
                <Col xs={{span: 10, offset: 1}} sm={{span: 8, offset:2}} md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}}>
                    {buildFitnessLevelRow()}
                    {buildCardioLevelRow()}
                    {buildEquipmentAccessRow()}
                    {buildWorkoutSplitRow()}
                    {buildBodyPartFocusRow()}
                    {workoutDayAvailabilityRow()}
                    {historyInjuryRow()}
                    {workoutNotesRow()}
                    {buildSubmitButton()}
                </Col>
            </Row>
        </Container>
    )

}