import {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import KarvedUpTooltip from '../shared/karved-up-tooltip';


export default function NutritionInformation(){ 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [nutritionPreferences, setNutritionPreferences] = useState(() => ({
        favorite_foods: "",
        food_allergies: "",
        diet_restrictions: ""
    }));
    useEffect(() => {
        ///START AT TOP OF PAGE
        window.scrollTo(0, 0);
    }, []);
    const [isSaving, setIsSaving] = useState(() => false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        const response = await api.upsertUserIntake({nutrition_preferences: nutritionPreferences});
        if (response) {
            navigate('/user-intake/first-goal');
            return
        }
        setIsSaving(false);
    }

    const favoriteFoodsRow = () => {
        return (
            <Row className="karved-up-row">
                <Col>
                    <h3>Favorite Foods
                        {' '}
                        <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                Tell us about your favorite foods so we can create healthy alternatives for you!
                            </p>
                        </KarvedUpTooltip>
                    </h3>
                    <p>Let's start by telling us about your favorite foods. (List all of them, seperate by comma!)</p>
                    
                    <Form.Group>
                        <textarea
                            required
                            minLength={5}    
                            className="form-control"
                            placeholder="Enter favorite foods (seperate by comma)"
                            value={nutritionPreferences.favorite_foods}
                            onChange={(e) => setNutritionPreferences({...nutritionPreferences, favorite_foods: e.target.value})} />
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    const allergyRow = () => {
        return (
            <Row className="karved-up-row">
                <Col>
                    <h3>Food Allergies {' '}
                        <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                Tell us about your food allergies so we can avoid them in your recipes!
                            </p>
                        </KarvedUpTooltip>
                    </h3>
                    <p>Do you have any food allergies?</p>
                    <Form.Group>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter all food allergies, seperate by comma (e.g. peanuts, shellfish)"
                            value={nutritionPreferences.food_allergies}
                            onChange={(e) => setNutritionPreferences({...nutritionPreferences, food_allergies: e.target.value})
                        }
                    />
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    const dietRestrictionsRow = () => {
        return (
            <Row className="karved-up-row">
                <Col>
                    <h3>Diet Restrictions {' '}
                        <KarvedUpTooltip>
                            <p style={{color: '#fff'}}>
                                Tell us about your diet restrictions so we can create recipes that work for you!
                            </p>
                        </KarvedUpTooltip>
                    </h3>
                    <p>Do you have any diet restrictions?</p>
                    <Form.Group>
                        <textarea
                            type="text"
                            className="form-control"
                            placeholder="Enter all diet restrictions, seperate by comma (e.g. vegan, vegetarian)"
                            value={nutritionPreferences.diet_restrictions}
                            onChange={(e) => setNutritionPreferences({...nutritionPreferences, diet_restrictions: e.target.value})
                        }
                    />
                    </Form.Group>
                </Col>
            </Row>
        )
    }

    const buildSubmitButton = () => {
      
        return (
            <Row className="karved-up-row">
                <Button className="primary-btn" variant="primary" type="submit" disabled={isSaving}>
                    {isSaving ? "Saving... Hang Tight!" : "Continue"}
                </Button>
                <p className="mt-5">
                    Recipes will be created for you once you complete the onboarding process.
                </p>
            </Row>
        )
    }

    

    return (
        <Container className="mb-5 karved-content-container">
            <h1 className="text-center">Let's start building your Muscle Cook Book!</h1>
            <p className="text-center">We're going to use the information below to create a cook book of HEALTHY MEALS based on your food choices.</p>
            <p className="text-center">BE HONEST... You'll be amazed at what we can create healthy alternatives for!</p>
            <Form onSubmit={handleSubmit}>
                {favoriteFoodsRow()}
                {allergyRow()}
                {dietRestrictionsRow()}
                {buildSubmitButton()}
            </Form>
        </Container>
    )
}