import {Line} from 'react-chartjs-2';
import {
    Chart as ChartJs,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
} from 'chart.js';

ChartJs.register(
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
);

export default function LineChart({data, options}){
    return (
        <Line style={{backgroundColor: '#333', padding: 20}} data={data} options={options} />
    )
}