import { ProgressBar } from "react-bootstrap";


export default function MuscleScorecardBar({muscleName, muscleSets}){ 
    const percentage = muscleSets / 20 * 100; //20 is max number volume for growth

    const constructVolumeText = (muscleSets) => {
        if (muscleSets === 0) return <p>No sets for this muscle</p>
        if (muscleSets < 5) {
            return <p>Volume: Below Muscle Maitenance</p>
        } else if (muscleSets < 10){
            return <p>Volume: <span>Muscle Maintenance</span></p>
        } else if (muscleSets < 15){
            return <p>Volume: <span>Muscle Growth</span></p>
        } else if (muscleSets <= 30){
            return <p>Volume: <span>Optimal Muscle Growth</span></p>
        } else {
            return <p>Volume: <span>Potential Muscle Overload</span></p>
        }
    }

    const constructBackgroundColor = (muscleSets) => {
        if (muscleSets === 0) return 'danger';
        if (muscleSets < 5) return 'danger';
        if (muscleSets < 10) return 'warning';
        if (muscleSets < 15) return 'success';
        if (muscleSets <= 30) return 'success';
        return 'danger';
    }
    return (
        <div className="muscle-scorecard-bar">
            <h6>{muscleName}</h6>
            <ProgressBar
            variant={constructBackgroundColor(muscleSets)}
           
            animated now={percentage} label={`${muscleSets} sets`} striped/>
            {constructVolumeText(muscleSets)}
        </div>
    )
}