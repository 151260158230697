import axios from 'axios';
import * as keys from '../constants/keys';
import * as actions from '../constants/actions';
import moment from 'moment';
axios.defaults.baseURL = 'https://api.karvedup.com' //'http://localhost:5001'; // Pointing to Local Express Server


export default class API {
    constructor({dispatch}){
        this.dispatch = dispatch;
        this.token    = window.localStorage.getItem(keys.USER_TOKEN) || null;
        this.setToken();
    }

    setToken = (token) => {
        let t = token;
        if(!t){
            t = window.localStorage.getItem(keys.USER_TOKEN) || null;
        }
        if (!t) return null;
        axios.defaults.headers.common['Authorization'] = `${t}`;
        return true;
    }

    createUser = async (requestObj) => {
        try {
            const response = await axios.post('/user/sign-up', requestObj);
            window.localStorage.setItem(keys.USER_TOKEN, response.data.token);
            return true;
        } catch (error) {
            console.log("ERROR CREATING USER: ", error);
            //ERROR HANDLING
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Create Account",
                message: "There was an error creating your account. Please try again.",
                variant: "danger"
                }
            })
            return false
        }
    }

    loginUser = async (requestObj) => {
        try {
            const response = await axios.post('/user/login', requestObj);
            window.localStorage.setItem(keys.USER_TOKEN, response.data.token);
            return true;
        } catch (error) {
            console.log("ERROR LOGGING IN USER: ", error);
            //TODO: INTEGRATE ALERTS
            //ERROR HANDLING
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Login",
                message: "There was an error Logging in, please check your email and password and try again.",
                variant: "danger"
                }
            })
            return false;

        }
    }

    upsertUserIntake = async (requestObj) => {
        try {
            const response = await axios.post('/user/upsert-user-intake', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR UPDATING USER INTAKE: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Update Intake",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserIntake = async () => {
        try {
            const response = await axios.get('/user/get-user-intake');
            this.dispatch({type: actions.SET_USER_INTAKE, payload: response.data});
            return response.data;
        } catch(err) {
            console.log("ERROR GETTING USER INTAKE: ", err);
            return false;
        }
    }

    createUserGoal = async (requestObj) => {
        try {
            const response = await axios.post('/user/create-user-goal', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR CREATING USER GOAL: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Update Intake",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserGoal = async () => {
        try {
            const {data} = await axios.get('/user/get-user-goal');
            return data;
        } catch(err) {
            console.log("ERROR GETTING USER GOAL: ", err);
            return false;
        }
    }

    createUserDailyMacros = async (requestObj) => {
        try {
            const response = await axios.post('/user/create-user-daily-macros', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR CREATING USER DAILY MACROS: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Update Macros",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserDailyMacros = async () => {
        try {
            const response = await axios.get('/user/get-user-daily-macros');
            this.dispatch({type: actions.SET_USER_DAILY_MACROS, payload: response.data});
            return response.data;
        } catch(err) {
            console.log("ERROR GETTING USER DAILY MACROS: ", err);
            return false;
        }
    }

    getUserDailyNutrientIntake = async () => {
        try {
            const response = await axios.get('/user/get-user-daily-nutrient-intake', {
                params: {
                    intake_date: moment().format('YYYY-MM-DD')
                }
            });
            const intake = response.data.intake || {};
            this.dispatch({type: actions.SET_USER_DAILY_NUTRIENT_INTAKE, payload: intake});
        } catch(err) {
            console.log("ERROR GETTING USER DAILY NUTRIENT INTAKE: ", err);
            return false;
        }
    }

    upsertUserDailyNutrientIntake = async (requestObj) => {
        try {
            requestObj.intake_date = moment().format('YYYY-MM-DD');
            const response = await axios.post('/user/upsert-user-daily-nutrient-intake', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR UPDATING USER DAILY NUTRIENT INTAKE: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Update Intake",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    /*
        router.post('/upsert-user-daily-checkin', auth, userCtrl.upsertUserDailyCheckin);
        router.get('/get-user-daily-checkin', auth, userCtrl.getUserDailyCheckin);
    */
    upsertUserDailyCheckin = async (requestObj) => {
        try {
            requestObj.intake_date = moment().format('YYYY-MM-DD');
            const response = await axios.post('/user/upsert-user-daily-checkin', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR UPDATING USER DAILY CHECKIN: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Update Intake",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserDailyCheckin = async () => {
        try {
            const response = await axios.get('/user/get-user-daily-checkin', {
                params: {
                    intake_date: moment().format('YYYY-MM-DD')
                }
            });
            this.dispatch({type: actions.SET_USER_DAILY_CHECKIN, payload: response.data.checkin || null});
            return response.data.checkin || null;
        } catch(err) {
            console.log("ERROR GETTING USER DAILY CHECKIN: ", err);
            return false;
        }
    }

    searchUSDA = async (query) => {
        try {
            const {data} = await axios.get('/nutrition/usda-search', {
                params: {
                    search: query
                }
            });
            this.dispatch({type: actions.SET_USDA_RESULTS, payload: data.results});
            return true;
        } catch(err) {
            console.log("ERROR SEARCHING USDA: ", err);
            return false;
        }
    }

    searchBarcode = async (barcode) => {
        try {
            const {data} = await axios.post('/nutrition/barcode-search', {
                barcode
            });
            return data.result;
        } catch(err){
            console.log("ERROER SEARCHING BARCODE: ", err);
            return null;
        }
    }

    saveUserMeal = async (requestObj) => {
        try {
            const response = await axios.post('/meal/save', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR SAVING USER MEAL: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Update Intake",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserSavedMeals = async () => {
        try {
            const response = await axios.get('/meal/saved');
            this.dispatch({type: actions.SET_USER_SAVED_MEALS, payload: response.data.meals});
        } catch(err) {
            console.log("ERROR GETTING USER SAVED MEALS: ", err);
            return false;
        }
    }

    getUserDailyCheckinByDateRange = async (duration) => {
        try {
            const response = await axios.get('/user/get-user-daily-checkin-by-date-range', {
                params: {
                    duration: moment().subtract(duration, 'days').format('YYYY-MM-DD')
                }
            });
            this.dispatch({type: actions.SET_USER_DAILY_CHECKIN_LAST_SEVEN_DAYS, payload: response.data.checkins});
            return response.data.checkins || [];
        } catch(err) {
            console.log("ERROR GETTING USER DAILY CHECKIN BY DATE RANGE: ", err);
            return false;
        }
    }

    saveUserCompleteWorkout = async (requestObj) => {
        try {
            const response = await axios.post('/user/upsert-complete-user-workout', requestObj);
            return true;
        } catch (error) {
            console.log("ERROR SAVING USER WORKOUT: ", error);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Save Workout",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserCompleteWorkoutsByDate = async (date) => {
        try {
            const response = await axios.get('/user/get-user-complete-workouts', {
                params: {
                    workout_date: date
                }
            });
            this.dispatch({type: actions.SET_USER_COMPLETED_WORKOUTS, payload: response.data.workouts});
            return true;
        } catch(err){
            console.log("ERROR GETTING USER COMPLETE WORKOUTS BY DATE: ", err);
            return false;
        }
    }

    getUserCompleteWorkoutsFromLastSevenDays = async () => {
        try {
            const response = await axios.get('/user//get-completed-workouts-by-date-range', {
                params: {
                    duration: moment().subtract(7, 'days').format('YYYY-MM-DD')
                }
            });
            const workouts = response.data.workouts || [];
            console.log(workouts);
            let   statData = {
                totalWeeklyVolume: 0,
                totalWeeklySets: 0,
                averageSetsPerWorkout: 0,
                averageVolumePerWorkout: 0,
                workoutDateDataVolumeMap: {},
                muscleVolumeMap: {}
            }

            //Handle Iterations Here Versus in the component
            for (let i = 0, len = workouts.length; i < len; i++){
                const workout = workouts[i].workout_data;
                statData.totalWeeklyVolume += workout.totalVolume;
                statData.totalWeeklySets += workout.totalWorkoutSetCount;

                if (workout.muscleSetMap){
                    for (let muscle in workout.muscleSetMap){
                        if (statData.muscleVolumeMap[muscle]){
                            statData.muscleVolumeMap[muscle] += Number(workout.muscleSetMap[muscle]);
                        } else {
                            statData.muscleVolumeMap[muscle] = Number(workout.muscleSetMap[muscle]);
                        }
                    }
                }

                if(statData.workoutDateDataVolumeMap[workout.workoutDate]){
                    statData.workoutDateDataVolumeMap[workout.workoutDate] += workout.totalVolume;
                } else {
                    statData.workoutDateDataVolumeMap[workout.workoutDate] = workout.totalVolume;
                }
            }

            statData.averageSetsPerWorkout = Math.floor(statData.totalWeeklySets / workouts.length);
            statData.averageVolumePerWorkout = Math.floor(statData.totalWeeklyVolume / workouts.length);
            statData.totalWeeklyWorkouts = workouts.length;
            this.dispatch({type: actions.SET_USER_WEEKLY_STATS, payload: statData});
            return statData;
            

        } catch(err){
            console.log("ERROR GETTING USER COMPLETE WORKOUTS FROM LAST SEVEN DAYS: ", err);
            return false;
        }
    }

    saveUserActiveWorkoutPlan = async (requestObj) => {
        try {
            const response = await axios.post('/workout/create-user-active-workout-plan', requestObj);
            return true;
        } catch(err){
            console.log("ERROR SAVING USER ACTIVE WORKOUT PLAN: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Activate Workout Plan",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserActiveWorkoutPlan = async () => {
        try {
            const response = await axios.get('/workout/get-user-active-workout');
            this.dispatch({type: actions.SET_USER_ACTIVE_WORKOUT_PLAN, payload: response.data.data});
            
            return response.data.data;
        } catch(err) {
            console.log("ERROR GETTING USER ACTIVE WORKOUT PLAN: ", err);
            return false;
        }
    }

    saveUserWorkoutPlan = async(requestObj) => {
        try {
            const response = axios.post('/workout/create-workout-plan', requestObj);
            return true;
        } catch(err){
            console.log("ERROR SAVING USER WORKOUT PLAN: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Save Your Workout Plan",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserWorkoutPlans = async () => {
        try {
            const response = await axios.get('/workout/get-user-workout-plans');
            this.dispatch({type: actions.SET_USER_WORKOUT_PLANS, payload: response.data.data});
            return true;
        } catch(err){
            console.log("ERROR GETTING USER WORKOUT PLANS: ", err);
            return false;
        }
    }

    triggerForgotPassword = async (requestObj) => {
        try {
            await axios.post('/user/trigger-forget-password-email', requestObj);
            return true;
        } catch(err){
            console.log("ERROR TRIGGERING FORGOT PASSWORD: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Reset Password",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    resetPassword = async (requestObj) => {
        try {
            await axios.post('/user/reset-password', requestObj);
            return true;
        } catch(err){
            console.log("ERROR RESETTING PASSWORD: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Reset Password",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    createCheckoutSession = async (priceId) => {
        try {
            const response = await axios.post('/payment/create-checkout-session', {priceId});
            return response.data;
        } catch(err){
            console.log("ERROR CREATING CHECKOUT SESSION: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Create Checkout Session",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    confirmSessionSuccessfulPayment = async (sessionId) => {
        try {
            const response = await axios.post('/payment/confirm-session-successful-payment', {sessionId});
            return response.data;
        } catch(err){
            console.log("ERROR CONFIRMING SUCCESSFUL PAYMENT: ", err);
            return false;
        }
    }

    changeEmail = async (requestObj) => {
        try {
            await axios.post('/user/change-email-address', requestObj);
            return true;
        } catch(err){
            console.log("ERROR CHANGING EMAIL: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Change Email",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    changePassword = async (requestObj) => {
        try {
            await axios.post('/user/change-password', requestObj);
            return true;
        } catch(err){
            console.log("ERROR CHANGING PASSWORD: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Change Password",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    cancelSubscription = async () => {
        try {
            await axios.post('/payment/deactivate-subscription');
            return true;
        } catch(err){
            console.log("ERROR CANCELING SUBSCRIPTION: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Cancel Subscription",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    reactivateSubscription = async () => {
        try {
            await axios.post('/payment/reactivate-subscription');
            return true;
        } catch(err){
            console.log("ERROR REACTIVATING SUBSCRIPTION: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Reactivate Subscription",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getSubscriptionInformation = async () => {
        try {
            const response = await axios.post('/payment/get-subscription-information');
            return response.data;
        } catch(err){
            console.log("ERROR GETTING SUBSCRIPTION INFORMATION: ", err);
            return false;
        }
    }

    getUserData = async () => {
        try {
            if (!this.setToken()) return false; //SAVE AN API CALL
            const response = await axios.get('/user/get-user');
            this.dispatch({type: actions.SET_USER_DATA, payload: response.data});
            return response.data;
        } catch(err){
            console.log("ERROR GETTING USER DATA: ", err);
            return false;
        }
    }


    upsertCardioWorkout = async (requestObj) => {
        try {
            await axios.post('/user/upsert-complete-user-cardio', requestObj);
            return true;
        } catch(err){
            console.log("ERROR SAVING CARDIO WORKOUT: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Save Cardio Workout",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserCardioWorkouts = async (date) => {
        try {
            const response = await axios.get('/user/get-user-cardio', {
                params: {
                    cardio_date: date
                }
            });
            this.dispatch({type: actions.SET_USER_CARDIO_WORKOUTS, payload: response.data.cardio});
            return true;
        } catch(err){
            console.log("ERROR GETTING USER CARDIO WORKOUTS: ", err);
            return false;
        }
    }

    getUserWeeklyReportCard = async (date) => {
        try {
            const {data} = await axios.get('/user/get-user-report-card', {
                params: {
                    report_card_date: date
                }
            });
            this.dispatch({type: actions.SET_USER_WEEKLY_REPORT_CARD, payload: data.report_card});
            return data.report_card;
        } catch(err) {
            console.log("ERROR GETTING USER WEEKLY REPORT CARD: ", err);
            return false;
        }
    }

    upsertUserReportCard = async (requestObj) => {
        try {
            await axios.post('/user/upsert-user-report-card', requestObj);
            return true;
        } catch(err){
            console.log("ERROR UPDATING USER REPORT CARD: ", err);
            this.dispatch({
                type: actions.ADD_ALERT,
                payload: {
                title: "Unable to Save Weekly Report Card",
                message: "Please wait a moment and try again.",
                variant: "danger"
                }
            })
            return false;
        }
    }

    getUserDailyNutrientIntakeByRateRange = async (duration) => {
        try {
            const response = await axios.get('/user/get-user-daily-nutrient-intake-by-date-range', {
                params: {
                    duration: moment().subtract(duration, 'days').format('YYYY-MM-DD')
                }
            });
            return response.data.checkins || [];
        } catch(err){
            console.log("ERROR GETTING USER DAILY NUTRIENT INTAKE BY DATE RANGE: ", err);
            return false;
        }
    }

    getUserCompletedWorkoutsByDateRange = async (duration) => {
        try {
            const response = await axios.get('/user/get-completed-workouts-by-date-range', {
                params: {
                    duration: moment().subtract(duration, 'days').format('YYYY-MM-DD')
                }
            });
            return response.data.workouts || [];
        } catch(err){
            console.log("ERROR GETTING USER COMPLETED WORKOUTS BY DATE RANGE: ", err);
            return false;
        }
    }

    getUserCompletedCardioByDateRange = async (duration) => {
        try {
            const response = await axios.get('/user/get-completed-cardio-by-date-range', {
                params: {
                    duration: moment().subtract(duration, 'days').format('YYYY-MM-DD')
                }
            });
            return response.data.cardio || [];
        } catch(err){
            console.log("ERROR GETTING USER CARDIO BY DATE RANGE: ", err);
            return false;
        }
    }

    

        searchBarcodeV2 = async (barcode) => {
            try {
                const {data} = await axios.post('/nutrition/barcode-search-v2', {
                    barcode
                });
                return data.result;
            } catch(err){
                console.log("ERROER SEARCHING BARCODE: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Find Barcode",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return null;
            }
        }

        searchFoodItems = async (query) => {
            try {
                const {data} = await axios.get('/nutrition/search-food-items', {
                    params: {
                        search: query
                    }
                });
                this.dispatch({type: actions.SET_USDA_RESULTS, payload: data.results});
                return data.results;
            } catch(err){
                console.log("ERROER SEARCHING FOOD ITEMS: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Find Food Item",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return null;
            }
        }

        manualFoodLookup = async (foodName) => {
            try {
                const {data} = await axios.post('/nutrition/manual-food-lookup', {
                   food_name: foodName
                });
                this.dispatch({type: actions.SET_USDA_RESULTS, payload: [data.result]})
                return data.result;
            } catch(err){
                console.log("ERROER SEARCHING MANUAL FOOD: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Find Food Item",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return null;
            }
        }

        getAllExercises = async () => {
            try {
                const {data} = await axios.get('/workout/get-all-exercises');
                this.dispatch({type: actions.SET_ALL_EXERCISES, payload: data.exercises});
                console.log(data.exercises.length)
                return data.exercises;
            } catch(err){
                console.log("ERROR GETTING ALL EXERCISES: ", err);
                return null;
            }
        }

        generateSingleAIWorkout = async (requestObj) => {
            try {
                const {data} = await axios.post('/workout/create-ai-single-workout', requestObj);
                return data.workout;
            } catch(err){
                console.log("ERROR GENERATING SINGLE AI WORKOUT: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Generate Workout",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return null;
            }
        }

        getPremiumWorkoutPlans = async () => {
            try {
                const {data} = await axios.get('/workout/get-premium-workout-plans');
                this.dispatch({type: actions.SET_PREMIUM_WORKOUT_PLANS, payload: data.workouts});
                return data.plans;
            } catch(err){
                console.log("ERROR GETTING PREMIUM WORKOUT PLANS: ", err);
                return null;
            }
        }

        getPaymentPortalLink = async () => {
            try {
                const {data} = await axios.post('/payment/create-portal-link', {
                    return_url: window.location.href
                });
                return data.url;
            } catch(err){
                console.log("ERROR GETTING PAYMENT PORTAL LINK: ", err);
                return null;
            }
        }

        reportBug = async (requestObj) => {
            try {
                const response = await axios.post('/bugs/report-bug', requestObj);
                return response.data.response_to_customer;
            } catch(err){
                console.log("ERROR REPORTING BUG: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Report Bug",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return false;
            }
        }

        generateFullWorkoutPlan = async (requestObj) => {
            try {
                const response = await axios.post('/workout/generate-full-workout-plan', requestObj);
                return response.data.workout_plan;
            } catch(err){
                console.log("ERROR GENERATING FULL WORKOUT PLAN: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Generate Workout Plan",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return null;
            }
        }

        generateMuscleRecipesForUser = async (requestObj) => {
            try {
                const response = await axios.post('/nutrition/generate-muscle-recipes-for-user', requestObj);
                return response.data.recipes;
            } catch(err){
                console.log("ERROR GENERATING MUSCLE RECIPES: ", err);
                this.dispatch({
                    type: actions.ADD_ALERT,
                    payload: {
                    title: "Unable to Generate Recipes",
                    message: "Please wait a moment and try again.",
                    variant: "danger"
                    }
                })
                return null;
            }
        }

        getUserMuscleRecipes = async () => {
            try {
                const response = await axios.get('/nutrition/get-user-muscle-recipes');
                this.dispatch({type: actions.SET_USER_MUSCLE_RECIPES, payload: response.data.recipes})
                return response.data.recipes;
            } catch(err){
                console.log("ERROR GETTING USER MUSCLE RECIPES: ", err);
                return null;
            }
        }

}