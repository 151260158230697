import { Modal, Form, Button } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import API from "../../../helpers/api";
import { useDispatch, useSelector } from "react-redux";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export default function UpdateEmailModal({close}){
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const user = useSelector(state => state.user.userData);
    const [email, setEmail] = useState(() => '');
    const [isSubmitting, setIsSubmitting] = useState(() => false);

    const updateEmail = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const success = await api.changeEmail({
            old_email: user.email_address,
            new_email: email
        });
        if(success){
            close();
        }
        setIsSubmitting(false);
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="karved-up-modal" closeButton>
                <Modal.Title>Update Email</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Form onSubmit={updateEmail}>
                    <Form.Group>
                        <Form.Label>Current Email</Form.Label>
                        <Form.Control type="email" value={user.email_address} disabled />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email"
                                      value={email} 
                                      onChange={(e) => setEmail(e.target.value)}
                                      isInvalid={email.length > 0 && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)}
                                      required/>
                    </Form.Group>
                    <div className="text-center mt-2">
                        <Button className="primary-btn" type="submit" disabled={isSubmitting}><FontAwesomeIcon icon={faSave} />Update Email</Button>
                    </div>
                </Form>
            </Modal.Body>
            </Modal>
    )
}