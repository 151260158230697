import {Modal, Row, Container, Form, Button} from 'react-bootstrap';
import {useState, useMemo} from 'react';
import API from '../../../helpers/api';
import {useDispatch} from 'react-redux';

export default function ReactivateSubscriptionModal({close}){
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const [success, setSuccess] = useState(() => false);

    const reactivateSubscription = async () => {
        setIsSubmitting(true);
        const didSucceed = await api.reactivateSubscription();
        setIsSubmitting(false);
        if(didSucceed){
            setSuccess(true);
            setTimeout(() => {
                close();
            }, 3000)
        }
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton className="karved-up-modal">
                <Modal.Title>Reactivate Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Container>
                    <Row>
                        <h4>Are you sure you want to reactivate your subscription?</h4>
                        <p>If reactivated within a current billing cycle, you will not be charged until the next billing cycle.</p>
                        <hr/>
                        <Button className="primary-btn" onClick={() => reactivateSubscription()} disabled={isSubmitting}>Yes, I want to reactivate</Button>
                        <br/>
                        <Button className="mt-2" variant="danger" onClick={close}>No, I don't want to reactivate my subscription</Button>
                    </Row>
                    {success && <p>Thank you for reactivating! This prompt will close in 5 seconds (or you can close it now)</p>}
                </Container>
            </Modal.Body>
        </Modal>
    )

}