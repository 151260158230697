import {useState, useRef, useMemo, useEffect} from 'react';
import {Modal, Row, Col, Button, Form, Table,InputGroup} from 'react-bootstrap';
import {useSelector, useDispatch} from 'react-redux';
import {Typeahead} from 'react-bootstrap-typeahead';   
import API from '../../../helpers/api';
import moment from 'moment';
import { faCancel, faComputer, faDumbbell, faPencil, faPlusCircle, faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const generationMuscleGroups = [
    'chest', 'back', 'shoulders', 'biceps', 'triceps', 'quadriceps', 'hamstrings', 'abdominals', 'traps', 'glutes'
]

export default function LogWorkoutModal({close, workout, preplannedWorkout, isPreplannedWorkout}){
    const dispatch = useDispatch();
    const formTouched = useRef(false);
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const exerciseData = useSelector(state => state.workout.exercises);
    const [userWorkout, setUserWorkout] = useState(() => {
        if (preplannedWorkout){
            return {...preplannedWorkout, workoutDate: moment().format('YYYY-MM-DD')};
        }

        if(workout){
            return workout.workout_data;
        }
        return {
            workoutName: '',
            workoutDate: moment().format('YYYY-MM-DD'),
            exercises: [
                {
                    exerciseId: '',
                    exerciseName: '',
                    primaryMuscles: [],
                    secondaryMuscles: [],
                    sets: [
                        {
                            reps: 0,
                            weight: 0,
                            setType: 'standard',
                            setMetadata: {}
                        }
                    ],
                }
            ],
            primaryMusclesTrained: [],
            secondaryMusclesTrained: [],
            totalWorkoutSetCount: 0,
            muscleSetMap: {}
        }
    });

    const [modalState, setModalState] = useState(() => 'main');
    const [isLogging, setIsLogging] = useState(() => false);

    const [generatedWorkoutQuestionaire, setGeneratedWorkoutQuestionaire] = useState(() => ({
        musclesToTrain: [],
        difficultyLevel: 1, //Out of 10
        minSets: 10,
        maxSets: 15,
        totalExercises: 4
    }));

    const [aiWorkoutIsGenerating, setAIWorkoutIsGenerating] = useState(() => false);
    const [aiWorkoutGenerationComplete, setAIWorkoutGenerationComplete] = useState(() => false);

    const [exercises, setExercises] = useState(() => {
        return exerciseData.map(exercise => {
            return {
                id: exercise.id,
                name: exercise.exercise_name,
                primaryMuscles: exercise.primary_muscle_groups,
                secondaryMuscles: exercise.secondary_muscle_groups,
            }
        });
    });

    const [deleteToggleMap, setDeleteToggleMap] = useState(() => ({}))
    const [editToggleMap, setEditToggleMap] = useState(() => ({}))
    const [exerciseSetDeleteToggleMap, setExerciseSetDeleteToggleMap] = useState(() => ({}))

    const handleFormAttempt = (evt) => {
        evt.preventDefault();
        formTouched.current = true;
        handleLogWorkout(evt)
    }

    const handleLogWorkout = async(e) => {
        formTouched.current = true;
        setIsLogging(true);
        
        let muscleSetMap = {};
        let totalWorkoutSetCount = 0;
        let primaryMuscleMap = {};
        let secondaryMuscleMap = {};
        let totalVolume = 0;
        //Iterate through each exercise and make sure that there is an exercise name
        for (let i = 0; i < userWorkout.exercises.length; i++){
            if (!userWorkout.exercises[i].exerciseName || userWorkout.exercises[i].exerciseName.length < 1){
                alert('Please make sure all exercises have a name');
                setIsLogging(false);
                return;
            }

            if (!userWorkout.exercises[i].sets || !userWorkout.exercises[i].sets.length){ 
                alert('Please make sure all exercises have at least one set');
                setIsLogging(false);
                return;
            }

            for (let j = 0; j < userWorkout.exercises[i].sets.length; j++){
                if (!userWorkout.exercises[i].sets[j].reps || userWorkout.exercises[i].sets[j].reps < 1){
                    alert('Please make sure all sets have a valid number of reps');
                    setIsLogging(false);
                    return;
                }

                if (userWorkout.exercises[i].sets[j].weight == undefined || userWorkout.exercises[i].sets[j].weight < 0){
                    alert('Please make sure all sets have a valid weight');
                    setIsLogging(false);
                    return;
                }
            }
        }
        userWorkout.exercises.forEach(exercise => {
            //REVAMP THIS ITERATE THROUGH SETS ONE TIME
            //First Iterate Through Primary and Secondary Muscles
            totalWorkoutSetCount += exercise.sets.length;
            exercise.primaryMuscles.forEach(muscle => {
                if (!muscleSetMap[muscle]){
                    muscleSetMap[muscle] = exercise.sets.length;
                } else {
                    muscleSetMap[muscle] += exercise.sets.length;
                }
                if (!primaryMuscleMap[muscle]){
                    primaryMuscleMap[muscle] = 1;
                }
            });
            exercise.secondaryMuscles.forEach(muscle => {
                if (!muscleSetMap[muscle]){
                    muscleSetMap[muscle] = .5 * exercise.sets.length;
                } else {
                    muscleSetMap[muscle] += .5 * exercise.sets.length;
                }
                if (!secondaryMuscleMap[muscle]){
                    secondaryMuscleMap[muscle] = 1;
                }
            });
            //Tally Up Volume
            exercise.sets.forEach(set => {
                if (set.reps && set.weight){
                    totalVolume += Number(set.reps) * Number(set.weight);
                }
                
                

                if (set.setType && set.setType == 'superset' && set.setMetadata){
                    //Tally Up the Reps and Weight to the volume
                    totalWorkoutSetCount++;
                    if (set.setMetadata.reps && set.setMetadata.weight){
                        totalVolume += Number(set.setMetadata.reps) * Number(set.setMetadata.weight);
                    }
                    
                    //Add the superset exercise to the muscleSetMap
                    //Iterate Through supersetExercisePrimaryMuscles and supersetExerciseSecondaryMuscles
                    if (set.setMetadata.supersetExercisePrimaryMuscles && set.setMetadata.supersetExercisePrimaryMuscles.length){
                        set.setMetadata.supersetExercisePrimaryMuscles.forEach(muscle => {
                            if (!muscleSetMap[muscle]){
                                muscleSetMap[muscle] = 1;
                            } else {
                                muscleSetMap[muscle]++;
                            }
                        });
                 }
                    if (set.setMetadata.supersetExerciseSecondaryMuscles && set.setMetadata.supersetExerciseSecondaryMuscles.length){
                    set.setMetadata.supersetExerciseSecondaryMuscles.forEach(muscle => {
                        if (!muscleSetMap[muscle]){
                            muscleSetMap[muscle] = .5;
                        }
                        else {
                            muscleSetMap[muscle] += .5;
                        }
                    });
                }
                } else if (set.setType && set.setType == 'dropset' && set.setMetadata){
                    totalWorkoutSetCount++;
                    if (set.setMetadata.reps && set.setMetadata.weight){
                        totalVolume += Number(set.setMetadata.reps) * Number(set.setMetadata.weight);
                    }
                    
                    //These Will be the same as the primary and secondary muscles of the exercise
                    if (exercise.primaryMuscles){
                        exercise.primaryMuscles.forEach(muscle => {
                            if (!muscleSetMap[muscle]){
                                muscleSetMap[muscle] = 1;
                            } else {
                                muscleSetMap[muscle]++;
                            }
                        });
                    }
                    if (exercise.secondaryMuscles){
                        exercise.secondaryMuscles.forEach(muscle => {
                            if (!muscleSetMap[muscle]){
                                muscleSetMap[muscle] = .5;
                            } else {
                                muscleSetMap[muscle] += .5;
                            }
                        });
                    }
                }
            });
        });

    

        await api.saveUserCompleteWorkout({
            id: workout ? workout.id : undefined,
            workout_date: userWorkout.workoutDate,
            workout_data: {...userWorkout, muscleSetMap, totalVolume, totalWorkoutSetCount, primaryMusclesTrained: Object.keys(primaryMuscleMap), secondaryMusclesTrained: Object.keys(secondaryMuscleMap)}
        });
        setIsLogging(false);
        setUserWorkout({...userWorkout, muscleSetMap, totalVolume, totalWorkoutSetCount})
        setModalState('workout-complete');
    }

    const addExercise = () => {
        setUserWorkout({...userWorkout, exercises: [...userWorkout.exercises, {
            exerciseId: '',
            exerciseName: '',
            primaryMuscles: [],
            secondaryMuscles: [],
            sets: [
                {
                    reps: 0,
                    weight: 0,
                    setType: 'standard',
                    setMetadata: {}
                }
            ],
        }]});
    }

    const removeExercise = (exerciseIndex) => {
        setUserWorkout({...userWorkout, exercises: userWorkout.exercises.filter((exercise, index) => index !== exerciseIndex)});
    }

    const addSetToExercise = (exerciseIndex) => { 
        setUserWorkout({...userWorkout, exercises: userWorkout.exercises.map((exercise, index) => {
            if(index === exerciseIndex){
                return {
                    ...exercise,
                    sets: [...exercise.sets, {
                        reps: 0,
                        weight: 0
                    }]
                }
            }
            return exercise;
        })});
    }

    const removeSetFromExercise = (exerciseIndex, setIndex) => {
        setUserWorkout({...userWorkout, exercises: userWorkout.exercises.map((exercise, index) => {
            if(index === exerciseIndex){
                return {
                    ...exercise,
                    sets: exercise.sets.filter((set, i) => i !== setIndex)
                }
            }
            return exercise;
        })})
    };

    const handleSetChange = (exerciseIndex, setIndex, key, value) => { 
        const workoutCopy = {...userWorkout};
       
        if (key === 'reps' || key === 'weight'){ 
            if (isNaN(value)){
              
                value = "0";
            }
            //Replace any non-numeric characters or decimals with an empty string
            value = parseInt(value, 10);
        }
   
        workoutCopy.exercises[exerciseIndex].sets[setIndex][key] = value;
        setUserWorkout(workoutCopy);
    }

    const handleSetMetadataChange = (exerciseIndex, setIndex, key, value) => {
        const workoutCopy = {...userWorkout};
        if (!workoutCopy.exercises[exerciseIndex].sets[setIndex].setMetadata){
            workoutCopy.exercises[exerciseIndex].sets[setIndex].setMetadata = {};
        }
        workoutCopy.exercises[exerciseIndex].sets[setIndex].setMetadata[key] = value;
        setUserWorkout(workoutCopy);
    }

    const handleDeleteToggle = (exerciseIndex) => {
        const newDeleteToggleMap = {...deleteToggleMap};
        let dt = newDeleteToggleMap[exerciseIndex];
        if (dt == undefined){
            dt = false;
        }
        newDeleteToggleMap[exerciseIndex] = !dt;
        setDeleteToggleMap(newDeleteToggleMap);
    }

    const handleEditToggle = (exerciseIndex) => {
        const newEditToggleMap = {...editToggleMap};
        let et = newEditToggleMap[exerciseIndex];
        if (et == undefined){
            et = false;
        }
        newEditToggleMap[exerciseIndex] = !et;
        setEditToggleMap(newEditToggleMap);
    }

    const updateExercise = (exerciseIndex, exercise) => {
        const workoutCopy = {...userWorkout};
        workoutCopy.exercises[exerciseIndex] = exercise;
        setUserWorkout(workoutCopy);
        handleEditToggle(exerciseIndex); //Close the edit toggle
    }

    const eraseExerciseName = (exerciseIndex) => {
        const workoutCopy = {...userWorkout};
        workoutCopy.exercises[exerciseIndex].exerciseName = '';
        setUserWorkout(workoutCopy);
    }

    const handleExerciseSetDeleteToggle = (exerciseIndex, setIndex) => {
        const newExerciseSetDeleteToggleMap = {...exerciseSetDeleteToggleMap};
        let dt = newExerciseSetDeleteToggleMap[exerciseIndex];
        if (dt == undefined){
            dt = {};
        }
        dt[setIndex] = !dt[setIndex];
        newExerciseSetDeleteToggleMap[exerciseIndex] = dt;
        setExerciseSetDeleteToggleMap(newExerciseSetDeleteToggleMap);
    }


    const buildExercises = () => {
        if (!userWorkout.exercises || !userWorkout.exercises.length){
            return (
                <Row>
                    <Button onClick={addExercise}>(+) Add Exercise To Workout</Button>
                </Row>
            )
        }
        return (
            <div>
                {userWorkout.exercises.map((exercise, index) => {
                    //DELETE BUTTON
                    let deleteButton = (
                        <div>
                            {!deleteToggleMap[index] && (
                                <Button variant="danger" onClick={() => {
                                    handleDeleteToggle(index);
                                }}>
                                    <FontAwesomeIcon icon={faTrashCan} />
                                </Button>
                            )}
                            
                            {deleteToggleMap[index] && (
                                <div>
                                    <h6>Remove Exercise?</h6>
                                    <Button variant="danger" onClick={() => {
                                        removeExercise(index)
                                        handleDeleteToggle(index);
                                        }}>

                                         <FontAwesomeIcon icon={faTrashCan} /> Delete
                                    </Button>  
                                    <Button variant="secondary" onClick={() => handleDeleteToggle(index)}>
                                        <FontAwesomeIcon icon={faCancel}/> Cancel
                                    </Button>
                                </div>
                            )}
                        </div>
                    );

                    let typeaheadClass = 'd-flex align-items-center';

                    if (editToggleMap[index]){
                        typeaheadClass = '';
                    }

                    //IF NO EXERCISENAME show the typeahead
                    //IF EXERCISENAME show the exercise name and the edit button
                    //IF EDIT TOGGLE IS TRUE show the typeahead
                    let typeahead = null;
                    if (!exercise.exerciseName || editToggleMap[index]){
                        typeahead = (
                            <Typeahead
                                id={`exercise-${index}`}
                                labelKey='name'
                                isValid={exercise.exerciseName && exercise.exerciseName.length > 0}
                                isInvalid={formTouched.current && (!exercise.exerciseName || exercise.exerciseName.length < 1)}
                                options={exercises} // Options from your exerciseDB
                                selected={exercise.exerciseName ? [{ name: exercise.exerciseName }] : []} // Wrap exerciseName in an array for the selected prop
                                onChange={(selected) => {
                                    if (selected.length > 0) {
                                        const selectedExercise = selected[0];
                                        updateExercise(index, {
                                            ...exercise,
                                            exerciseId: selectedExercise.id,
                                            exerciseName: selectedExercise.name,
                                            primaryMuscles: selectedExercise.primaryMuscles,
                                            secondaryMuscles: selectedExercise.secondaryMuscles
                                        });
                                    }
                                }}
                                placeholder="Choose an exercise..."
                            />
                        )
                    } else {
                        typeahead = (
                            <div className={typeaheadClass}>
                                <p>{exercise.exerciseName}</p>
                                <Button onClick={() => {
                                        eraseExerciseName(index);
                                        handleEditToggle(index)
                                    }}><FontAwesomeIcon icon={faPencil}/></Button>
                            </div>
                        )
                    }

                    return (
                        <div key={index} className="mt-2 mb-3 border-solid-row">
                            <Row>
                            
                                <h4>EXERCISE {index + 1} - {exercise.exerciseName.toUpperCase()}</h4>
                                {deleteButton}
                                <Col className="mt-3">
                                    <Form.Label>Exercise Name</Form.Label>
                                    <Form.Group>
                                        
                                       {typeahead}
                                        
                                    </Form.Group>
                                </Col>
                               
                                
                            </Row>
                            <Row>
                            {exercise.sets.map((set, setIndex) => {
                                const repsPlaceholder = set && set.minReps && set.maxReps ? `${set.minReps} - ${set.maxReps} Reps` : 'Reps';
                                //Account for Drop Sets and Super Sets
                                let setSpecialty = null;
                                let deleteButton = (
                                    <div className="mb-3">
                                        {(!exerciseSetDeleteToggleMap[index] || !exerciseSetDeleteToggleMap[index][setIndex]) && (
                                            <Button variant="danger" onClick={() => {
                                                handleExerciseSetDeleteToggle(index, setIndex);
                                            }}>
                                                <FontAwesomeIcon icon={faTrashCan} />
                                            </Button>
                                        )}
                                        
                                        {exerciseSetDeleteToggleMap[index] && exerciseSetDeleteToggleMap[index][setIndex] && (
                                            <div>
                                                <h6>Remove Set?</h6>
                                                <Button variant="danger" onClick={() => {
                                                    removeSetFromExercise(index, setIndex)
                                                    handleExerciseSetDeleteToggle(index, setIndex);
                                                    }}>

                                                    <FontAwesomeIcon icon={faTrashCan} /> Delete
                                                </Button>  
                                                <Button variant="secondary" onClick={() => handleExerciseSetDeleteToggle(index, setIndex)}>
                                                    <FontAwesomeIcon icon={faCancel}/> Cancel
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                )

                                if (set.setType === 'superset' && set.setMetadata){
                                    //Div that shows the exercise and the min/max reps
                                    const supersetRepsPlaceholder = set.setMetadata.supersetMinReps && set.setMetadata.supersetMaxReps ? `${set.setMetadata.supersetMinReps} - ${set.setMetadata.supersetMaxReps} Reps` : 'Reps';
                                    setSpecialty = (
                                        <Row className="mt-3">
                                            <h6>Superset with</h6>
                                            <p>{set.setMetadata.supersetExercise}</p>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Weight</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control type="text" 
                                                                      value={set.setMetadata.weight} onChange={(e) => {
                                                                        let value = e.target.value.replace(/^0+(?!\.)/, '').replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); // Prevent multiple decimal points
                                                                        if (isNaN(value) || value == '' || value == '.' || value == undefined){
                                                                            value = "0";
                                                                        }
                                                                        handleSetMetadataChange(index, setIndex, 'weight', value);
                                                                      }}
                                                                      min={0}
                                                                      isValid={set.setMetadata && set.setMetadata.weight >= 0}
                                                                      isInvalid={formTouched.current && (!set.setMetadata ||isNaN(set.setMetadata.weight) || set.setMetadata.weight < 0)}
                                                                      required/>
                                                   
                                                   <Button disabled={true}>
                                                    LBS
                                                    </Button>
                                                       
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Reps</Form.Label>
                                                    <Form.Control
                                                        placeholder={supersetRepsPlaceholder}
                                                        type="text" 
                                                        value={set.setMetadata.reps} 
                                                        onChange={(e) => {
                                                            let value = e.target.value.replace(/^0+(?!\.)/, '').replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); // Prevent multiple decimal points
                                                            if (isNaN(value) || value == '' || value == '.' || value == undefined){
                                                                value = "0";
                                                            }
                                                            handleSetMetadataChange(index, setIndex, 'reps', value);
                                                        }}
                                                        min={1}
                                                        isValid={set.setMetadata && set.setMetadata.reps >= 1}
                                                        isInvalid={formTouched.current && (!set.setMetadata || isNaN(set.setMetadata.reps) || set.setMetadata.reps < 1)}
                                                        required/>
                                                </Form.Group>
                                            </Col>
                                            
                                        </Row>
                                    )
                                } else if (set.setType === 'dropset' && set.setMetadata){
                                    //Div that shows the exercise and the min/max reps
                                    const dropsetRepsPlaceholder = set.setMetadata.dropsetMinReps && set.setMetadata.dropsetMaxReps ? `${set.setMetadata.dropsetMinReps} - ${set.setMetadata.dropsetMaxReps} Reps` : 'Reps';
                                    setSpecialty = (
                                        <Row className="mt-3">
                                            <h6>Dropset</h6>
                                            
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Weight</Form.Label>
                                                    <InputGroup>
                                                        <Form.Control type="text" 
                                                                      value={set.setMetadata.weight} 
                                                                      onChange={(e) => {
                                                                        let value = e.target.value.replace(/^0+(?!\.)/, '').replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); // Prevent multiple decimal points
                                                                        if (isNaN(value) || value == '' || value == '.' || value == undefined){
                                                                            value = "0";
                                                                        }
                                                                        handleSetMetadataChange(index, setIndex, 'weight', value);
                                                                      }}
                                                                      min={0}
                                                                      isValid={set.setMetadata && set.setMetadata.weight >= 0}
                                                                      isInvalid={formTouched.current && (!set.setMetadata || isNaN(set.setMetadata.weight) || set.setMetadata.weight < 0)}
                                                                      required/>
                                                        <Button>
                                                        LBS
                                                        </Button>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Reps</Form.Label>
                                                    <Form.Control
                                                        placeholder={dropsetRepsPlaceholder}
                                                        type="text" 
                                                        value={set.setMetadata.reps} 
                                                        onChange={(e) => {
                                                            handleSetMetadataChange(index, setIndex, 'reps', e.target.value);
                                                        }}
                                                        min={1}
                                                        isValid={set.setMetadata && set.setMetadata.reps >= 1}
                                                        isInvalid={formTouched.current && (!set.setMetadata || isNaN(set.setMetadata.reps) || set.setMetadata.reps < 1)}
                                                        required/>
                                                </Form.Group>
                                            </Col>
                                            
                                        </Row>
                                    )
                                }
                                return (
                                    
                                    <Col xs={6} sm={6} lg={4}  key={setIndex} className="mt-3 border-solid-row" >
                                        <h6>Set {setIndex + 1}</h6>
                                        {deleteButton}
                                        
                                            
                                      
                                            <Form.Group>
                                                <Form.Label>Weight</Form.Label>
                                                <InputGroup>
                                                    <Form.Control type="text" 
                                                                  value={set.weight} 
                                                                  onChange={(e) => {
                                                                    let value = e.target.value.replace(/^0+(?!\.)/, '').replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); // Prevent multiple decimal points
                                                                    console.log(value);
                                                                    if (isNaN(value) || value == '' || value == '.' || value == undefined){
                                                                        value = "0";
                                                                    }
                                                                    handleSetChange(index, setIndex, 'weight', value);

                                                                  }}
                                                                  min={0}
                                                                  isValid={!isNaN(set.weight) && set.weight >= 0}
                                                                  isInvalid={formTouched.current && (isNaN(set.weight) || set.weight < 0)}
                                                                  required/>
                                                   <Button disabled={true}>
                                                    LBS
                                                    </Button>
                                                   
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group className="mt-2">
                                                <Form.Label>Reps</Form.Label>
                                                <Form.Control
                                                    placeholder={repsPlaceholder}
                                                    type="text" 
                                                    value={set.reps} 
                                                    onChange={(e) => {
                                                        let value = e.target.value.replace(/^0+(?!\.)/, '').replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'); // Prevent multiple decimal points
                                            
                                                        if (isNaN(value) || value == '' || value == '.' || value == undefined){
                                                            value = "0";
                                                        }
                                                        handleSetChange(index, setIndex, 'reps', value);
                                                    }}
                                                    min={1}
                                                    isValid={!isNaN(set.reps) && set.reps >= 1}
                                                    isInvalid={formTouched.current && (isNaN(set.reps) || set.reps < 1)}
                                                    required/>
                                            </Form.Group>
                                       
                                        {setSpecialty}
                                           
                                    </Col>
                                    
                                    
                                )
                            })}
                            {(!preplannedWorkout && !isPreplannedWorkout) &&
                                <div className="mt-3">
                                    <Button variant="primary" onClick={() => addSetToExercise(index)}><FontAwesomeIcon icon={faPlusCircle} /> <br/>Add Set</Button>
                                </div>
                            }   
                            </Row>
                            
                        </div>
                    )
                })}
                {!preplannedWorkout &&
                <div className="text-center">
                    <Button onClick={addExercise}><FontAwesomeIcon icon={faPlusCircle}/><br/>Add Exercise To Workout</Button>
                </div>
    }
            </div>
        )
    }

    const addRemoveMuscleGroup = (muscleGroup) => {
        if (generatedWorkoutQuestionaire.musclesToTrain.includes(muscleGroup)){
            setGeneratedWorkoutQuestionaire({...generatedWorkoutQuestionaire, musclesToTrain: generatedWorkoutQuestionaire.musclesToTrain.filter(muscle => muscle !== muscleGroup)});
        } else {
            setGeneratedWorkoutQuestionaire({...generatedWorkoutQuestionaire, musclesToTrain: [...generatedWorkoutQuestionaire.musclesToTrain, muscleGroup]});
        }
    }

    const generateWorkout = async(e) => {
        e.preventDefault();
        if (!generatedWorkoutQuestionaire.musclesToTrain.length){
            alert('Please select at least one muscle group to train');
            return;
        }
        setAIWorkoutIsGenerating(true);
        const gw = await api.generateSingleAIWorkout(generatedWorkoutQuestionaire);
        if (!gw){
            alert('There was an issue generating your workout. Please try again');
            setAIWorkoutIsGenerating(false);
            return;
        }
        setAIWorkoutIsGenerating(false);
        setUserWorkout(gw);
        setModalState('main');
    }

    

    if (modalState === 'workout-complete'){ 
        return (
            <Modal show={true} onHide={close} size="xl" >
                <Modal.Header className="karved-up-modal" closeButton>
                    Workout Logged
                </Modal.Header>

                <Modal.Body className="karved-up-modal">
                    <h2 className="text-center">WORKOUT COMPLETE!</h2>
                    <h6 className="text-center">Congrats on completing the workout!</h6>
                    <hr/>
                    <Row>
                        <Col className="text-center" xs="12" md="6">
                            <h4>WORKOUT NAME</h4>
                            <h6>{userWorkout.workoutName}</h6>
                            <hr/>
                            <h4>WEIGHT LIFTED</h4>
                            <h6 className="karved-up-green">{userWorkout.totalVolume} lbs</h6>

                            <hr/>
                            <h4>SETS COMPLETED</h4>
                            <h6 className="karved-up-green">{userWorkout.totalWorkoutSetCount}</h6>
                        </Col>
                        <Col xs="12" md="6">
                            <h4 className="text-center">MUSCLES TRAINED</h4>
                            <Table variant="dark" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>MUSCLE GROUP</th>
                                        <th className="text-center">SETS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {userWorkout.muscleSetMap && Object.keys(userWorkout.muscleSetMap).sort((a, b) => {
                                    return userWorkout.muscleSetMap[b] - userWorkout.muscleSetMap[a];
                                }).map((muscle, index) => { 
                                    
                                    return (
                                        <tr key={index}>
                                            <td>{muscle.toUpperCase()}</td>
                                            <td className="text-center"><span className="karved-up-green">{userWorkout.muscleSetMap[muscle]}</span></td>
                                        </tr>
                                        
                                    )
                            })}
                                </tbody>
                            </Table>
                            
                        </Col>
                    </Row>
                    <div className="text-center mt-5">
                        <hr/>
                        <Button onClick={close}>Back to Dashboard</Button> <br/><br/>
                        
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    if (modalState === 'AI-Generation'){
        return (
            <Modal show={true} onHide={close} size='xl'>
                <Modal.Header className="karved-up-modal" closeButton>
                    Generate Your Workout
                </Modal.Header>

                <Modal.Body className="karved-up-modal">
                    {aiWorkoutIsGenerating ? (
                        <div className="p-5">
                            <h5>Generating Your Workout...</h5>
                        </div>
                    ) : (
                        <Form onSubmit={generateWorkout}>
                        <Form.Group className="text-center">
                            <h5>WHICH MUSCLES DO YOU WANT TO TRAIN?</h5>
                            <hr/>
                            {
                                generationMuscleGroups.map((muscleGroup, index) => { 
                                    //Check if selected
                                    let selected = generatedWorkoutQuestionaire.musclesToTrain.includes(muscleGroup);
                                    let className = 'body-part-selection';
                                    if (selected){
                                        className += ' selected';
                                    }
                                    return (
                                        <img className={className} onClick={() => addRemoveMuscleGroup(muscleGroup)} key={index} src={`/bodypart-icons/${muscleGroup.toLowerCase()}-icon.png`} alt="chest" />
                                    )
                                })

                            }
                            <hr className="mb-3"/>
                            <h5>HOW DIFFICULT DO YOU WANT THE WORKOUT?</h5>
                            <p>(On a Scale from 1-10)</p>
                            <Form.Control type="number" 
                                          min={1}
                                          max={10}
                                          value={generatedWorkoutQuestionaire.difficultyLevel} 
                                          onChange={(e) => setGeneratedWorkoutQuestionaire({...generatedWorkoutQuestionaire, difficultyLevel: e.target.value})}
                                          required/>
                            <hr className="mb-3"/>
                            <h5>HOW MANY EXERCISES DO YOU WANT TO DO FOR THIS WORKOUT?</h5>
                            <p>(Choose between 3 and 8)</p>
                            <Form.Control type="number" 
                                          min={3}
                                          max={8}
                                          value={generatedWorkoutQuestionaire.totalExercises} 
                                          onChange={(e) => setGeneratedWorkoutQuestionaire({...generatedWorkoutQuestionaire, totalExercises: e.target.value})}
                                          required/>
                            <hr className="mb-3"/>
                            <h5>HOW MANY TOTAL SETS DO YOU WANT TO COMPLETE FOR THIS WORKOUT?</h5>
                            <p>(Choose between 10 and 30)</p>
                            <Row>
                                <Col>
                                    <h6>Minimum Sets</h6>
                                    <Form.Control type="number" 
                                          min={10}
                                          max={30}
                                          value={generatedWorkoutQuestionaire.minSets} 
                                          onChange={(e) => setGeneratedWorkoutQuestionaire({...generatedWorkoutQuestionaire, minSets: e.target.value})}
                                          required/>
                                </Col>
                                <Col>
                                    <h6>Maximum Sets</h6>
                                    <Form.Control type="number" 
                                          min={10}
                                          max={30}
                                          isValid={generatedWorkoutQuestionaire.maxSets >= generatedWorkoutQuestionaire.minSets}
                                          isInvalid={generatedWorkoutQuestionaire.maxSets < generatedWorkoutQuestionaire.minSets}
                                          value={generatedWorkoutQuestionaire.maxSets} 
                                          onChange={(e) => setGeneratedWorkoutQuestionaire({...generatedWorkoutQuestionaire, maxSets: e.target.value})}
                                          required/>
                                </Col>
                            </Row>
                        </Form.Group>
                        <div className="text-center mt-5">
                            <hr/>
                            <Button type="submit">
                                <FontAwesomeIcon icon={faDumbbell} />
                                <br/>
                                Generate Workout
                            </Button>
                        </div>
                    </Form>
                    )}
                
                </Modal.Body>
            </Modal>
        )
    }

    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header className="karved-up-modal" closeButton>
                Log Workout
            </Modal.Header>

            <Modal.Body className="karved-up-modal">
                <Form onSubmit={evt => {
                    formTouched.current = true
                    handleFormAttempt(evt)
                    }}>
                    <Form.Group>
                        <Form.Label>Workout Name</Form.Label>
                        <Form.Control type="text" 
                                      value={userWorkout.workoutName} 
                                      onChange={(e) => setUserWorkout({...userWorkout, workoutName: e.target.value})}
                                      isValid={userWorkout.workoutName && userWorkout.workoutName.length > 0}
                                      isInvalid={formTouched.current && (!userWorkout.workoutName || userWorkout.workoutName.length < 1)}
                                      required/>
                    </Form.Group>

                    {(!preplannedWorkout && !workout) &&(
                        <div className="text-center mt-3">
                            <Button onClick={() => setModalState('AI-Generation')}>
                                <FontAwesomeIcon icon={faComputer} />
                                <br/>
                                Automatically Generate Workout
                            </Button>
                        </div>
                    )}

                    {buildExercises()}
                    <hr className="mt-5"/>
                    <div className="text-center">
                        <Button disabled={isLogging} type="submit"><FontAwesomeIcon icon={faSave} /><br/>Log Workout</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}