import {Modal, Form, Button} from 'react-bootstrap';
import {useState, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import API from '../../../helpers/api';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle, faTrashCan} from '@fortawesome/free-solid-svg-icons';


export default function LogCardioModal({close, cardioSession, plannedCardioSession}) {
    const dispatch = useDispatch();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [cardio, setCardio] = useState(() => {
        if (plannedCardioSession) {
            return plannedCardioSession;
        }
        if (cardioSession) {
            return cardioSession.cardio_data;
        }
        return {
            cardioWorkoutName: '',
            cardioDescription: '',
            cardioDate: moment().format('YYYY-MM-DD'),
            activities: [
                {
                    activity: '',
                    duration: 0,
                }
            ],
            totalDuration: 0,
        }
    });

    const handleSave = async(e) => {
        e.preventDefault();
        const totalDuration = cardio.activities.reduce((acc, activity) => acc + Number(activity.duration), 0);

        if (cardioSession && cardioSession.id) {
            await api.upsertCardioWorkout({
                ...cardioSession,
                cardio_data: {...cardio, totalDuration}
            })
        } else {
            let requestObj = {
                cardio_data: {...cardio, totalDuration},
                cardio_date: moment().format('YYYY-MM-DD')
            }
            await api.upsertCardioWorkout(requestObj);
        }  
        
        close();

    }

    const handleUpdateActivity = (index, key, value) => { 
        const cardioCopy = {...cardio};
        cardioCopy.activities[index][key] = value;
        setCardio(cardioCopy);
    }

    const handleAddCardioActivity = () => {
        setCardio({
            ...cardio,
            activities: [...cardio.activities, {
                activity: '',
                duration: 0,
            }]
        })
    }

    const handleRemoveCardioActivity = (index) => {
        setCardio({
            ...cardio,
            activities: cardio.activities.filter((act, i) => i !== index)
        })
    }

    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header closeButton={true} className="karved-up-modal">
                <Modal.Title>Log Cardio Workout</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Form onSubmit={handleSave}>
                    <div className="border-solid-row">
                        <Form.Group>
                            <Form.Label>Workout Name</Form.Label>
                            <Form.Control disabled={plannedCardioSession} type="text" value={cardio.cardioWorkoutName} onChange={(e) => setCardio({...cardio, cardioWorkoutName: e.target.value})} />
                        </Form.Group>
                       
                    </div>
                    

                    {
                        cardio.activities.map((activity, index) => {
                            return (
                                <div key={index} className="border-solid-row mt-2">
                                    <Form.Group>
                                        <Form.Label>Activity</Form.Label>
                                        <Form.Control type="text" value={activity.activity} onChange={(e) => handleUpdateActivity(index, 'activity', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Duration (mins)</Form.Label>
                                        <Form.Control type="number" value={activity.duration} onChange={(e) => handleUpdateActivity(index, 'duration', e.target.value)} />
                                    </Form.Group>
                                    <Button className='mt-2' variant="danger" onClick={() => handleRemoveCardioActivity(index)}><FontAwesomeIcon icon={faTrashCan}/><br/> Remove Activity</Button>
                                </div>
                            )
                        })
                    }
                    <div className="mt-4 text-center">
                    <Button  onClick={handleAddCardioActivity}><FontAwesomeIcon icon={faPlusCircle}/><br/> Add Activity</Button>
                    </div>
                    

                    <hr className="mt-5"/>
                    <div className="text-center">
                        <Button type="submit" variant="primary">Save/Log Cardio Workout</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )

}