import {Modal, Row, Container, Form, Button} from 'react-bootstrap';
import {useState, useMemo} from 'react';
import API from '../../../helpers/api';
import {useDispatch} from 'react-redux';

export default function CancelSubscriptionModal({close}){
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const [isSubmitting, setIsSubmitting] = useState(() => false);
    const [success, setSuccess] = useState(() => false);

    const cancelSubscription = async () => {
        setIsSubmitting(true);
        const didSucceed = await api.cancelSubscription();
        setIsSubmitting(false);
        if(didSucceed){
            setSuccess(true);
            setTimeout(() => {
                close();
            }, 3000)
        }
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header closeButton className="karved-up-modal">
                <Modal.Title>Cancel Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Container>
                    <Row>
                        <h4>Are you sure you want to cancel your subscription?</h4>
                        <p>By canceling your subscription, you will lose access to all premium features on.</p>
                        <hr/>
                        <div className="text-center">
                            <Button variant="danger" onClick={() => cancelSubscription()} disabled={isSubmitting}>Yes, I want to cancel</Button>
                            <br/>
                            <Button className="mt-2" variant="light" onClick={close}>No, I want to keep my subscription</Button>
                        </div>
                    </Row>
                    {success && <p>Your subscription has been canceled. You will lose access to premium features at the end of your current billing cycle.</p>}
                </Container>
            </Modal.Body>
        </Modal>
    )

}