import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import API from '../../helpers/api';
import { Button, Card, Col, Container, Row } from "react-bootstrap";


export default function SelectPremiumPlan() {
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const premiumPlans = useSelector(state => state.workout.premium_workout_plans);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(() => true);
    const [isSaving, setIsSaving] = useState(() => false);

    const loadData = async() => {
        setIsLoading(true);
        await api.getPremiumWorkoutPlans();
        setIsLoading(false);
    }

    useEffect(() => {
        loadData();
        //Scroll to the top of the screen
        window.scrollTo(0, 0);
    }, []);


    if (isLoading){
        return (
            <Container>
                <Card className="text-center">
                    <Card.Body>
                        <h3>Loading...</h3>
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    console.log("Premium Plans: ", premiumPlans);   

    const getPlanDifficulty = (difficulty) => {
        switch(difficulty){
            case 1:
                return "Beginner";
            case 2:
                return "Intermediate";
            case 3:
                return "Advanced";
            default:
                return "Unknown";
        }
    }
    
    const makeActiveWorkoutPlan = async(workoutPlanId, workout) => {
        setIsSaving(true);
        const requestObj = {
            workoutPlanId: workoutPlanId,
            workoutStartDate: moment().format('YYYY-MM-DD'),
            workoutEndDate: moment().add(workout.workout_plan_duration_in_weeks, 'weeks').format('YYYY-MM-DD')
        }
        const success = await api.saveUserActiveWorkoutPlan(requestObj);
        if (success){
            navigate('/dashboard');
        }
        setIsSaving(false);
    }

    return (
        <Container className="karved-content-container">
            <h3 className="text-center">Select a Premium Workout Plan</h3>
            <p className="text-center">Workouts volume will change as you progress through the plan.</p>
            <hr/>

            <Row>
                {premiumPlans.map((plan, index) => {
                    return (
                        <Col xs={12} md={6} index={index}>
                            <Card>
                                <Card.Header className="text-center">
                                    <h5>{plan.workout_plan_name}</h5>
                                    <h6>{getPlanDifficulty(plan.workout_plan_level)} Level</h6>
                                </Card.Header>
                                <Card.Body>
                                    <p>{plan.workout_plan_description}</p>
                                   
                                </Card.Body>

                                <Card.Footer className="text-center">
                                    <Button className="primary-btn"
                                            disabled={isSaving}
                                            onClick={() => {
                                                makeActiveWorkoutPlan(plan.id, plan)
                                            }}>SELECT PLAN</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}