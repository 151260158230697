const questions = [
    {
      "question": "What is KarvedUp?",
      "answer": "KarvedUp is an intuitive fitness app designed to make tracking your nutrition and workouts effortless. It adapts to your progress each week, providing a personalized experience that helps you stay on track with your fitness goals. Whether you're looking to build muscle, lose fat, or maintain your current level, KarvedUp gives you the tools and guidance to achieve your goals efficiently."
    },
    {
      "question": "How much does it cost?",
      "answer": "Until January 1, 2025, you can get KarvedUp for a special introductory price of $4.99 per month or $49.99 per year. This offer gives you full access to all features, helping you stay committed to your fitness journey at an unbeatable value."
    },
    {
      "question": "Will prices go up over time?",
      "answer": "When you sign up for KarvedUp, you’re locked into your initial subscription rate for as long as you remain subscribed. So, if you join at $49.99 per year, that’s the rate you’ll pay year after year—no price hikes, no surprises. Consider it our commitment to your fitness journey, inflation be damned!"
    },
    {
      "question": "What workout features does KarvedUp offer?",
      "answer": "KarvedUp provides a comprehensive suite of workout features to support all fitness levels:\n- Easy-to-Use Workout Tracker: Log your exercises with ease, track your progress, and stay organized on your fitness journey.\n- AI-Generated Impromptu Workouts: Get customized workout suggestions on the spot, tailored to your goals and available equipment.\n- 20+ Pre-Made Workout Plans: Access a library of expertly designed workout plans to suit various goals, from building muscle to improving endurance.\n- Volume Tracking: Monitor your workout volume over time to see how your strength and endurance are progressing.\nWith KarvedUp, you have everything you need to stay motivated and maximize your results."
    },
    {
      "question": "What nutrition features does KarvedUp offer?",
      "answer": "KarvedUp equips you with powerful nutrition tools to keep your diet on track:\n- Barcode Lookup: Quickly scan food items for instant nutritional information.\n- AI-Powered Food Database: Search for foods in our extensive database, which provides accurate nutrition details tailored to your needs.\n- Weekly Macro Adjustments: Receive personalized macro recommendations based on your weekly progress to ensure you stay aligned with your goals.\n- Calorie + Macro Tracking: Track your daily calories and macronutrients with ease, so you’re always aware of your intake.\nWith KarvedUp, managing your nutrition becomes simple and adaptable, helping you achieve results faster."
    },
    {
      "question": "What if I don't like the service?",
      "answer": "KarvedUp is committed to building a fitness app that meets your needs, and we’re driven by your feedback. If something isn’t up to your standard, you experience a bug, or you have a feature request, simply click the bug icon at the bottom of any page to report it. We take every suggestion seriously to ensure KarvedUp continues to improve for our users.\nIf you're not satisfied with the service, you can easily cancel your subscription with just one click—no hassle, no questions asked. Your satisfaction is our priority."
    },
    {
      "question": "Is KarvedUp available on the App Stores?",
      "answer": "Not yet! KarvedUp will be available on the App Stores once we exit beta and reach a critical mass of users. Our goal is to keep subscription costs as low as possible, and currently, the 30% fee on subscriptions charged by platforms like Apple would impact this. By initially offering KarvedUp directly, we can pass those savings on to you while ensuring a more affordable service."
    },
    {
      "question": "Is there a long-term commitment?",
      "answer": "No, there’s no long-term commitment. You can cancel your subscription anytime, whether it’s monthly or annual. After canceling, you’ll still have access to your plan until the end of your current subscription period, and you won’t be charged again once it expires."
    }
  ]
  
export default questions;