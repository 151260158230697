import {Modal, Form, Button} from "react-bootstrap";
import {useState, useEffect, useMemo} from "react";
import API from "../../../helpers/api";
import {useDispatch, useSelector} from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";



export default function UpdatePasswordModal({close}){
    const dispatch = useDispatch();
    const api = useMemo(() => new API({dispatch}), [dispatch]);
    const user = useSelector(state => state.user.userData);
    const [oldPassword, setOldPassword] = useState(() => '');
    const [newPassword, setNewPassword] = useState(() => '');
    const [isSubmitting, setIsSubmitting] = useState(() => false);

    const updatePassword = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const success = await api.changePassword({
            old_password: oldPassword,
            new_password: newPassword
        });
        if(success){
            close();
        }
        setIsSubmitting(false);
    }

    return (
        <Modal show={true} onHide={close}>
            <Modal.Header className="karved-up-modal" closeButton>
                <Modal.Title>Update Password</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Form onSubmit={updatePassword}>
                    <Form.Group>
                        <Form.Label>Old Password</Form.Label>
                        <Form.Control type="password"
                                      value={oldPassword}
                                      onChange={(e) => setOldPassword(e.target.value)}
                                      required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>New Password</Form.Label>
                        <Form.Control type="password"
                                      value={newPassword}
                                      isInvalid={newPassword.length < 8  || newPassword == oldPassword}
                                      onChange={(e) => setNewPassword(e.target.value)}
                                      required />
                    </Form.Group>
                    <div className="text-center mt-2">
                        <Button className="primary-btn" type="submit" disabled={isSubmitting}><FontAwesomeIcon icon={faSave}/> Update Password</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}