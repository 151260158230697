import {Modal, Form, Button, Row, Col, InputGroup} from 'react-bootstrap';
import {useMemo, useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Typeahead} from 'react-bootstrap-typeahead'; 
import {faCopy, faMinusCircle, faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default function WorkoutModal({save, close, workout}){
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const exerciseData = useSelector(state => state.workout.exercises);
    const [newWorkout, setNewWorkout] = useState(() => {
        if (workout) return workout;
        return {
            workoutName: '',
            workoutDescription: '',
            exercises: [
                {
                    exercise: '',
                    sets: [
                        {
                            minReps: 0,
                            maxReps: 0,
                            setType: 'standard', //Will integrate super sets and drop sets in the future
                            setMetadata: {}
                        }
                    ]
                }
            ],
            totalWorkoutSets: 1,
            workoutSetMap: {}

        }
    });

    const [exercises, setExercises] = useState(() => {
        return exerciseData.map(exercise => {
            return {
                id: exercise.id,
                name: exercise.exercise_name,
                primaryMuscles: exercise.primary_muscle_groups,
                secondaryMuscles: exercise.secondary_muscle_groups
            }
        });
    });

    useEffect(() => {
        console.log("TALLYING UP SETS");
        tallyUpSets();
    }, [newWorkout.exercises]);

    const handleSave = (e) => {
        e.preventDefault();
        console.log(newWorkout);
        if (!formRef.current.checkValidity()){
            formRef.current.reportValidity();
            return;
        }

        //Check that there is at leasst one exercise
        if (newWorkout.exercises.length < 1){
            alert('You must have at least one exercise in the workout');
            return;
        }

        //Check that each exercise has a name associated with it
        for (let i = 0, len = newWorkout.exercises.length; i < len; i++){
            const exercise = newWorkout.exercises[i];
            if (!exercise.exerciseName){
                alert('Each exercise must have a name associated with it');
                return;
            }
            //Iterate through the sets
            for (let j = 0, len = exercise.sets.length; j < len; j++){
                const set = exercise.sets[j];
                if (set.setType == 'superset' && set.setMetadata && !set.setMetadata.supersetExercise){
                    alert('Each superset must have a superset exercise associated with it');
                    return;
                }
            }
        }
        save({...newWorkout});
    }

    const tallyUpSetsV2 = (workout) => {
        let totalSets = 0;
        let workoutGroupMap = {};
        //Same as tallyUpSets only we are returning
        workout.exercises.forEach(exercise => {
            totalSets += exercise.sets.length;
            if (exercise.primaryMuscles) {
                exercise.primaryMuscles.forEach(muscle => {
                    if (workoutGroupMap[muscle]) {
                        workoutGroupMap[muscle] += 1 * exercise.sets.length;
                    } else {
                        workoutGroupMap[muscle] = 1 * exercise.sets.length;
                    }
                });
            }
            if (exercise.secondaryMuscles) {
                exercise.secondaryMuscles.forEach(muscle => {
                    if (workoutGroupMap[muscle]) {
                        workoutGroupMap[muscle] += .5 * exercise.sets.length;
                    } else {
                        workoutGroupMap[muscle] = .5 * exercise.sets.length;
                    }
                });
            }

            for (let i = 0, len = exercise.sets.length; i < len; i++){ 
                console.log("SET: ", exercise.sets[i]);
                const set = exercise.sets[i];
                if (set.setType == 'dropset'){
                    console.log('dropset');
                    totalSets += 1;
                    if (exercise.primaryMuscles){
                        exercise.primaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += 1;
                            } else {
                                workoutGroupMap[muscle] = 1;
                            }
                        });
                    }

                    if (exercise.secondaryMuscles){
                        exercise.secondaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += .5;
                            } else {
                                workoutGroupMap[muscle] = .5;
                            }
                        });
                    }
                }

                if (set.setType == 'superset'){
                    totalSets += 1;
                    if (set.setMetadata && set.setMetadata.supersetExercisePrimaryMuscles){
                        set.setMetadata.supersetExercisePrimaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += 1;
                            } else {
                                workoutGroupMap[muscle] = 1;
                            }
                        });
                    }
                    if (set.setMetadata && set.setMetadata.supersetExerciseSecondaryMuscles){
                        set.setMetadata.supersetExerciseSecondaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += .5;
                            } else {
                                workoutGroupMap[muscle] = .5;
                            }
                        })
                    }
                }
            }
        });

        return {
            totalWorkoutSets: totalSets,
            workoutSetMap: workoutGroupMap
        }
    }

    const tallyUpSets = () => {
        let totalSets = 0;
        let workoutGroupMap = {};
        newWorkout.exercises.forEach(exercise => {
            totalSets += exercise.sets.length;
            if (exercise.primaryMuscles) {
                exercise.primaryMuscles.forEach(muscle => {
                    if (workoutGroupMap[muscle]) {
                        workoutGroupMap[muscle] += 1 * exercise.sets.length;
                    } else {
                        workoutGroupMap[muscle] = 1 * exercise.sets.length;
                    }
                });
            }
            if (exercise.secondaryMuscles) {
                exercise.secondaryMuscles.forEach(muscle => {
                    if (workoutGroupMap[muscle]) {
                        workoutGroupMap[muscle] += .5 * exercise.sets.length;
                    } else {
                        workoutGroupMap[muscle] = .5 * exercise.sets.length;
                    }
                });
            }

            for (let i = 0, len = exercise.sets.length; i < len; i++){ 
                console.log("SET: ", exercise.sets[i]);
                const set = exercise.sets[i];
                if (set.setType == 'dropset'){
                    totalSets += 1;
                    console.log('dropset');
                    if (exercise.primaryMuscles){
                        exercise.primaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += 1;
                            } else {
                                workoutGroupMap[muscle] = 1;
                            }
                        });
                    }

                    if (exercise.secondaryMuscles){
                        exercise.secondaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += .5;
                            } else {
                                workoutGroupMap[muscle] = .5;
                            }
                        });
                    }
                }

                if (set.setType == 'superset'){
                    totalSets += 1;
                    if (set.setMetadata && set.setMetadata.supersetExercisePrimaryMuscles){
                        set.setMetadata.supersetExercisePrimaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += 1;
                            } else {
                                workoutGroupMap[muscle] = 1;
                            }
                        });
                    }
                    if (set.setMetadata && set.setMetadata.supersetExerciseSecondaryMuscles){
                        set.setMetadata.supersetExerciseSecondaryMuscles.forEach(muscle => {
                            if (workoutGroupMap[muscle]) {
                                workoutGroupMap[muscle] += .5;
                            } else {
                                workoutGroupMap[muscle] = .5;
                            }
                        });
                }
            }
        }
            
        });
        //Tally Up Sets Per Muscle Group
        setNewWorkout({
            ...newWorkout,
            totalWorkoutSets: totalSets,
            workoutSetMap: workoutGroupMap
        });
    }

    const addExercise = () => {
        setNewWorkout({
            ...newWorkout,
            exercises: [...newWorkout.exercises, {
                exercise: '',
                sets: [
                    {
                        minReps: 0,
                        maxReps: 0,
                        setType: 'standard',
                        setMetaData: {} //Will integrate super sets and drop sets in the future
                    }
                ]
            }]
        })
       
    }

    const removeExercise = (index) => {
        setNewWorkout({
            ...newWorkout,
            exercises: newWorkout.exercises.filter((ex, i) => i !== index)
        })
       
    }

    const addSetToExercise = (exceriseIndex) => {
        const workoutCopy = {...newWorkout};
        workoutCopy.exercises[exceriseIndex].sets.push({
            minReps: 0,
            maxReps: 0,
            setType: 'standard',
            setMetadata: {}
        });
        const tally = tallyUpSetsV2(workoutCopy);
        setNewWorkout({...workoutCopy,
            totalWorkoutSets: tally.totalWorkoutSets,
            workoutSetMap: tally.workoutSetMap
        });
        

      
    }

    const removeSetFromExercise = (exerciseIndex, setIndex) => {
        const workoutCopy = {...newWorkout};
        workoutCopy.exercises[exerciseIndex].sets = workoutCopy.exercises[exerciseIndex].sets.filter((set, i) => i !== setIndex);
        const tally = tallyUpSetsV2(workoutCopy);
        setNewWorkout({...workoutCopy,
            totalWorkoutSets: tally.totalWorkoutSets,
            workoutSetMap: tally.workoutSetMap
        });
    }

    const addSetCopy = (exerciseIndex, setIndex) => {
        const workoutCopy = {...newWorkout};
        const setCopy = JSON.parse(JSON.stringify(workoutCopy.exercises[exerciseIndex].sets[setIndex])); // Deep clone the set
        workoutCopy.exercises[exerciseIndex].sets.push(setCopy);
        const tally = tallyUpSetsV2(workoutCopy);
        setNewWorkout({...workoutCopy,
            totalWorkoutSets: tally.totalWorkoutSets,
            workoutSetMap: tally.workoutSetMap
        });
        
    }

    const handleSetDataChange = (exerciseIndex, setIndex, key, value) => {
        const workoutCopy = {...newWorkout};
        if (key === 'minReps' || key === 'maxReps'){
            //Make Sure it is a valid number
            if (isNaN(value)){
                value = 0;
            }
            //Make sure it is a positive number
            if (value < 0){
                return;
            }
            //Make sure it is a whole number
            value = Math.floor(value);
        }
        workoutCopy.exercises[exerciseIndex].sets[setIndex][key] = value;
        const tally = tallyUpSetsV2(workoutCopy);
        setNewWorkout({
            ...workoutCopy,
            totalWorkoutSets: tally.totalWorkoutSets,
            workoutSetMap: tally.workoutSetMap
        });
    }

    const handleSetMetadataChange = (exerciseIndex, setIndex, key, value) => {
        const workoutCopy = {...newWorkout};
        if (!workoutCopy.exercises[exerciseIndex].sets[setIndex].setMetadata){
            workoutCopy.exercises[exerciseIndex].sets[setIndex].setMetadata = {};
        }
        workoutCopy.exercises[exerciseIndex].sets[setIndex].setMetadata[key] = value;
        const tally = tallyUpSetsV2(workoutCopy);
        setNewWorkout({
            ...workoutCopy,
            totalWorkoutSets: tally.totalWorkoutSets,
            workoutSetMap: tally.workoutSetMap
        });
        
    }


    const buildExercisesSection = () => {
        if (!newWorkout.exercises.length){
            return (
                <Row>
                    <h4>Add Exercises to this workout</h4>
                    <Button onClick={addExercise}>Add Exercise</Button>
                </Row>
            )
        }

        return newWorkout.exercises.map((exercise, index) => { 
            const exerciseTitle = exercise.exerciseName ? `Exercise ${index + 1} - ${exercise.exerciseName}` : `Exercise ${index + 1}`;
            return (
                <div key={index}  className="p4 border-solid-row mb-3">
                <Row key={index}>
                    <Col>
                    <Form.Group className="mb-3">
                        <Form.Label>{exerciseTitle} <a className="pull-right" variant='danger' onClick={() => removeExercise(index)}>{'(REMOVE)'}</a></Form.Label>
                        <Typeahead
                            id={`exercise-${index}`}
                            labelKey='name'
                            required
                            style={{color: 'white'}}
                            isInvalid={!exercise.exerciseName}
                            options={exercises} // Options from your exerciseDB
                            selected={exercise.exerciseName ? [{ name: exercise.exerciseName }] : []} // Wrap exerciseName in an array for the selected prop
                            onChange={(selected) => {
                                if (selected.length > 0) {
                                    const selectedExercise = selected[0];
                                    console.log(selectedExercise);
                                    setNewWorkout({
                                        ...newWorkout, 
                                        exercises: newWorkout.exercises.map((ex, i) => {
                                            if (i === index) {
                                                return {
                                                    ...ex,
                                                    exerciseId: selectedExercise.id,
                                                    exerciseName: selectedExercise.name,
                                                    primaryMuscles: selectedExercise.primaryMuscles,
                                                    secondaryMuscles: selectedExercise.secondaryMuscles
                                                };
                                            }
                                            return ex;
                                        })
                                    });
                                }
                            }}
                            placeholder="Choose an exercise..."
                        />
                    </Form.Group>
                    </Col>
                </Row>
                <Row >
                    {
                        //SETS
                        exercise.sets.map((set, setIndex) => { 
                            //Vertically align the column to the bottom
                            console.log(set);
                            return (
                                <Col  key={`${setIndex}-set`} xs={6}  className="mt-3 border-solid-row">
                                    <h5>Set {setIndex + 1} <Button onClick={e => {
                                        
                                        addSetCopy(index, setIndex)}}><FontAwesomeIcon icon={faCopy}/></Button></h5>
                                   
                                    <Form.Group className="mt-2">
                                        <Form.Label>Min Reps</Form.Label>
                                        <InputGroup>
                                            <Form.Control type="number" 
                                                          value={set.minReps} 
                                                          min={1}
                                                          isInvalid={set.minReps < 1 || isNaN(set.minReps) || set.minReps > set.maxReps} 
                                                          onChange={(e) => {
                                                handleSetDataChange(index, setIndex, 'minReps', e.target.valueAsNumber);
                                            }} required/>
                                           
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group className="mt-2">
                                        <Form.Label>Max Reps</Form.Label>
                                        <Form.Control type="number" 
                                                      value={set.maxReps} 
                                                      min={1}
                                                      isInvalid={set.maxReps < 1 || isNaN(set.maxReps) || set.maxReps < set.minReps} 
                                                      onChange={(e) => {
                                            handleSetDataChange(index, setIndex, 'maxReps', e.target.valueAsNumber);
                                        }} required />
                                    </Form.Group>

                                    <Form.Group className="mt-2">
                                    <Form.Label>Set Type</Form.Label>
                                        <Form.Control as="select" value={set.setType} onChange={(e) => {
                                            handleSetDataChange(index, setIndex, 'setType', e.target.value);
                                        }}>
                                            <option value="standard">Standard</option>
                                            <option value="dropset">Dropset</option>
                                            <option value="superset">Superset</option>
                                        </Form.Control>
                                    </Form.Group>
                                    {set.setType === 'dropset' && ( 
                                        <div className="mt-4">
                                            <h6>Dropset Info</h6>

                                            <Form.Group>
                                                <Form.Label>Dropset Min Reps</Form.Label>
                                                <Form.Control type="number" 
                                                              value={set.setMetadata.dropsetMinReps} 
                                                              min={1}
                                                              isInvalid={set.setMetadata.dropsetMinReps < 1 || isNaN(set.setMetadata.dropsetMinReps) || set.setMetadata.dropsetMinReps > set.setMetadata.dropsetMaxReps} 
                                                              onChange={(e) => {
                                                                handleSetMetadataChange(index, setIndex, 'dropsetMinReps', e.target.valueAsNumber);
                                                              }} 
                                                              required/>
                                            </Form.Group>

                                            <Form.Group>
                                                <Form.Label>Dropset Max Reps</Form.Label>
                                                <Form.Control type="number" 
                                                              value={set.setMetadata.dropsetMaxReps} 
                                                              min={1} 
                                                              isInvalid={set.setMetadata.dropsetMaxReps < 1 || isNaN(set.setMetadata.dropsetMaxReps) || set.setMetadata.dropsetMaxReps < set.setMetadata.dropsetMinReps}
                                                              onChange={(e) => {
                                                                handleSetMetadataChange(index, setIndex, 'dropsetMaxReps', e.target.valueAsNumber);
                                                              }} 
                                                              required/>
                                            </Form.Group>
                                        </div>
                                    )}

                                    {
                                        set.setType === 'superset' && (
                                            <div className="mt-4">
                                                <h6>Superset Info</h6>
                                                <Form.Group>
                                                    <Form.Label>Superset Exercise</Form.Label>
                                                    <Typeahead
                                                        id={`superset-${index}-${setIndex}`}
                                                        labelKey='name'
                                                        required
                                                        isInvalid={set.setMetadata && set.setMetadata.supersetExercise && !set.setMetadata.supersetExercise}
                                                        options={exercises} // Options from your exerciseDB
                                                        selected={set.setMetadata && set.setMetadata.supersetExercise ? [{ name: set.setMetadata.supersetExercise }] : []} // Wrap exerciseName in an array for the selected prop
                                                        onChange={(selected) => {
                                                            if (selected.length > 0) {
                                                                const selectedExercise = selected[0];
                                                                console.log(selectedExercise);
                                                                const workoutCopy = {...newWorkout};
                                                                if (!workoutCopy.exercises[index].sets[setIndex].setMetadata){
                                                                    workoutCopy.exercises[index].sets[setIndex].setMetadata = {};
                                                                }
                                                                workoutCopy.exercises[index].sets[setIndex].setMetadata.supersetExercise = selectedExercise.name;
                                                                workoutCopy.exercises[index].sets[setIndex].setMetadata.supersetExerciseId = selectedExercise.id;
                                                                workoutCopy.exercises[index].sets[setIndex].setMetadata.supersetExercisePrimaryMuscles = selectedExercise.primaryMuscles;
                                                                workoutCopy.exercises[index].sets[setIndex].setMetadata.supersetExerciseSecondaryMuscles = selectedExercise.secondaryMuscles;
                                                                setNewWorkout(workoutCopy);
                                                            }
                                                        }}
                                                        placeholder="Choose an exercise..."
                                                        
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Superset Min Reps</Form.Label>
                                                    <Form.Control type="number" 
                                                                  value={set && set.setMetadata && set.setMetadata.supersetMinReps ? set.setMetadata.supersetMinReps : 0} 
                                                                  min={1}
                                                                  isInvalid={set.setMetadata  &&  (set.setMetadata.supersetMinReps < 1 || isNaN(set.setMetadata.supersetMinReps) || set.setMetadata.supersetMinReps > set.setMetadata.supersetMaxReps)} 
                                                                  onChange={(e) => {
                                                                    handleSetMetadataChange(index, setIndex, 'supersetMinReps', e.target.valueAsNumber);
                                                                  }} 
                                                                  required/>
                                                </Form.Group>

                                                <Form.Group>
                                                    <Form.Label>Superset Max Reps</Form.Label>
                                                    <Form.Control type="number" 
                                                                  value={set && set.setMetadata && set.setMetadata.supersetMaxReps ? set.setMetadata.supersetMaxReps : 0} 
                                                                  min={1}
                                                                  isInvalid={set.setMetadata  && (set.setMetadata.supersetMaxReps < 1 || isNaN(set.setMetadata.supersetMaxReps) || set.setMetadata.supersetMaxReps < set.setMetadata.supersetMinReps)} 
                                                                  onChange={(e) => {
                                                                    handleSetMetadataChange(index, setIndex, 'supersetMaxReps', e.target.valueAsNumber);
                                                                  }} 
                                                                  required/>
                                                </Form.Group>
                                            </div>
                                        )
                                    }
                                    
                                    <Form.Group className="text-center">
                                    {setIndex > 0 && (
                                        <Button className="mt-1" variant="danger" onClick={() => removeSetFromExercise(index, setIndex)}>
                                            <FontAwesomeIcon icon={faMinusCircle}/> <br/> Remove
                                        </Button>
                                    )}
                                    </Form.Group>
                                   
                                </Col>
                            )
                        })
                    }

                    
                    <Col style={{position: 'relative', minHeight: 150}}>
                        <Button style={{position: 'absolute', bottom: 0}} variant="primary" className="align-center" onClick={() => addSetToExercise(index)}> <FontAwesomeIcon icon={faPlusCircle}/> <br/>Add Another Set</Button>
                    </Col>
                </Row>
                
                </div>
            )
        })


    }
   
    return (
        <Modal show={true} onHide={close} size='xl'>
            <Modal.Header closeButton className="karved-up-modal">
                <Modal.Title>{workout ? 'Edit Workout' : `Create Workout - ${newWorkout.workoutName}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Form ref={formRef}>
                    <div className="">
                    <Form.Group>
                        <Form.Label>Workout Name</Form.Label>
                        <Form.Control type="text" value={newWorkout.workoutName} onChange={(e) => setNewWorkout({...newWorkout, workoutName: e.target.value})} isInvalid={newWorkout.workoutName.length < 1} required />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Workout Description</Form.Label>
                        <Form.Control as="textarea" value={newWorkout.workoutDescription} onChange={(e) => setNewWorkout({...newWorkout, workoutDescription: e.target.value})} isInvalid={newWorkout.workoutDescription.length < 1} required/>
                    </Form.Group>
                    </div>
                    <hr/>
                    <h5 className="mb-4">Exercises</h5>

                    {buildExercisesSection()}
                    <Row>
                    <div className="text-center mt-4">
                        <Button variant="primary" onClick={addExercise}> <FontAwesomeIcon icon={faPlusCircle} /> <br/> ADD ANOTHER EXERCISE</Button>
                    </div>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            <h5>Total Exercises: {newWorkout.exercises.length}</h5>
                            <h5>Total Sets: {newWorkout.totalWorkoutSets}</h5>
                            <p>* Supersets count as 2 working sets</p>
                            <p>* Dropsets count as an additional set</p>
                        </Col>
                        <Col>
                            <h5>Muscle Group Breakdown</h5>
                            <ul>
                                {Object.keys(newWorkout.workoutSetMap).sort((a, b) => {
                                    //Sort by most worked muscle group
                                    return newWorkout.workoutSetMap[b] - newWorkout.workoutSetMap[a];
                                }).map((muscle, index) => {
                                    return (
                                        <li key={index}>{muscle}: {newWorkout.workoutSetMap[muscle]}</li>
                                    )
                                })}
                            </ul>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Button onClick={handleSave}>Save Workout</Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}