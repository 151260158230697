import {useState, useEffect, useMemo} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import {useDispatch} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../helpers/api';
import moment from 'moment';

export default function BasicInformation(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(() => false);
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const [basicInformation, setBasicInformation] = useState(() => ({
        current_weight: undefined,
        gender: undefined,
        goal: undefined,
        target_weight: undefined,
        height_in_inches: 0,
        dob: undefined // Date of Birth
    }));

    const [dob, setDob] = useState(() => ({
        month: undefined,
        day: undefined,
        year: undefined
    }));

    const [dobMaxes, setDobMaxes] = useState(() => ({
        month: 12,
        day: 31,
        year: moment().year()
    }));

    const changeDob = (key, value) => {
        // Adjust dob maxes
        if (key == 'month'){
            //Use Moment to get the number of days in the month
            const daysInMonth = moment(`${dob.year}-${value}`, 'YYYY-MM').daysInMonth();
            setDobMaxes({...dobMaxes, day: daysInMonth});
        }
        let filteredValue = value;
        if (key == 'day' && value > dobMaxes.day){
            filteredValue = dobMaxes.day;
        }
        if (key == 'year' && value > dobMaxes.year){
            filteredValue = dobMaxes.year;
        }
        setDob({...dob, [key]: filteredValue});

        const newDob = {...dob, [key]: filteredValue};
        if (!newDob.month || !newDob.day || !newDob.year) return;

        const birthMoment = moment({year: newDob.year, month: newDob.month - 1, day: newDob.day});
        if (!birthMoment.isValid() || !birthMoment.isBefore(moment().subtract(18, 'years'))
            || newDob.year.toString().length != 4){
            const newBasicInformation = {...basicInformation, dob: undefined};
            setBasicInformation(newBasicInformation);
            return;
        }
        const newBasicInformation = {...basicInformation, dob: birthMoment.format('YYYY-MM-DD')};
        setBasicInformation(newBasicInformation);
        
    }

    const [height, setHeight] = useState(() => ({   
        feet: 0,
        inches: 0
    }))

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!basicInformation.dob || !moment(basicInformation.dob).isValid() || !moment(basicInformation.dob).isBefore(moment().subtract(18, 'years'))){
            return;
        }

        if (!basicInformation.target_weight || !basicInformation.current_weight || !basicInformation.goal || !basicInformation.gender){
            return;
        }

        setIsSaving(true);

        const response = await api.upsertUserIntake(basicInformation);
        if (response) {
            navigate('/user-intake/workout');
            return
        }
        setIsSaving(false);
    }


    const scrollToDiv = (id) => {
        setTimeout(() => {
            const element = document.getElementById(id);
            if (!element) return;
            element.scrollIntoView({behavior: "smooth"});
        }, 250)
    }

    const handleChange = (key, value, rowId) => {
        setBasicInformation({...basicInformation, [key]: value});
        if (rowId){
            scrollToDiv(rowId);
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            console.log("SCROLLED")
        }, 250)
        
    }

    useEffect(() => {
        if (height.feet && height.inches >= 0){
            console.log(height)
            const height_in_inches = (height.feet * 12) + height.inches;
            console.log(height_in_inches)
            setBasicInformation({...basicInformation, height_in_inches})
        }
    },[height])

    useEffect(() => {
        ///START AT TOP OF PAGE
        window.scrollTo(0, 0);
    }, []);

    const buildGenderRow = () => {
        return(
            <Row className="mt-5 karved-up-row">
                    <h5 className="mb-3">Please Select your Gender</h5>
                    <Col className="text-center">
                        <div className={`gender-button ${basicInformation.gender == 'female' ? 'active-female' : ''}`}
                             onClick={e => handleChange('gender', 'female', 'goal-row')}>
                            <img src="/buttons/female-button.png" className="img-fluid"/>
                        </div>
                        
                    </Col>
                    <Col className="text-center">
                        <div className={`gender-button ${basicInformation.gender == 'male' ? 'active-male' : ''}`}  
                             onClick={e => handleChange('gender', 'male', 'goal-row')}>
                            <img src="/buttons/male-button.png" className="img-fluid"/>
                        </div>
                    </Col>

                </Row>
        )
    }

    const buildGoalRow = () => {
        if (!basicInformation.gender) return null;

           let fatLossImageUrl, buildMuscleImageUrl, fatLossImageClass, buildMuscleImageClass;

           if (basicInformation.gender == 'female'){
                fatLossImageUrl = "/buttons/female-fat-loss-goal.png";
                buildMuscleImageUrl = "/buttons/female-muscle-goal.png";
                fatLossImageClass = `goal-button-female ${basicInformation.goal == 'lose' ? 'active' : ''}`;
                buildMuscleImageClass = `goal-button-female ${basicInformation.goal == 'gain' ? 'active' : ''}`;
           } else {
                fatLossImageUrl = "/buttons/male-fat-loss-goal.png";
                buildMuscleImageUrl = "/buttons/male-muscle-goal.png";
                fatLossImageClass = `goal-button-male ${basicInformation.goal == 'lose' ? 'active' : ''}`;
                buildMuscleImageClass = `goal-button-male ${basicInformation.goal == 'gain' ? 'active' : ''}`;
           }
        
            return (
                <Row className="mt-5 karved-up-row" id="goal-row">
                    <h5 className="mb-3">Please Select your Goal</h5>

                    <Col className="text-center">
                        <div className={fatLossImageClass}
                             onClick={() => handleChange('goal', 'lose', 'current-weight')}>
                            <img src={fatLossImageUrl} className="img-fluid"/>
                        </div>
                    </Col>

                    <Col className="text-center">
                        <div className={buildMuscleImageClass}
                             onClick={() => handleChange('goal', 'gain', 'current-weight')}>
                            <img src={buildMuscleImageUrl} className="img-fluid"/>
                        </div>
                    </Col>
                </Row>
            )
        
    }

    const buildCurrentWeightRow = () => { 
        if (!basicInformation.goal) return null;
        return (
            <Row className="mt-5 karved-up-row" id="current-weight">
                <h5 className="mb-3">Your Current Body Weight (in lbs)</h5>
                <Col>
                    <Form.Control type="number" 
                                  placeholder="Current Weight in LBS" 
                                  isValid={basicInformation.current_weight && basicInformation.current_weight > 0}
                                  onChange={(e) => handleChange('current_weight', parseFloat(e.target.value), 'target-weight')}
                                  required/>
                </Col>
                
            </Row>
        )
    }

    const buildTargetWeightRow = () => {
        if (!basicInformation.current_weight) return null;

        
        return (
            <Row className="mt-5 karved-up-row" id="target-weight">
                <h5 className="mb-3">Your Target Body Weight (in lbs)</h5>
                <Col>
                    <Form.Control type="number" 
                                  placeholder="Target Weight in LBS" 
                                  isValid={basicInformation.target_weight && basicInformation.target_weight > 0}
                                  onChange={(e) => handleChange('target_weight', parseFloat(e.target.value), 'dob')}
                                  required/>
                </Col>
                
            </Row>
        )   
    }

    const buildDateOfBirthRow = () => {
        if (!basicInformation.target_weight) return null;
        // Experiment with 3 Input Fields for DOB
        return (
            <Row className='mt-5 karved-up-row' id='target-weight'>
                <h5 className="mb-3">Your Date of Birth</h5>
                <p>MM/DD/YYYY (e.g. 10/24/1988)</p>
                <input type='number'
                       style={{width: 85, height: 50, textAlign: 'center'}}
                       placeholder='Month'
                       value={dob.month}
                       onChange={(e) => changeDob('month', e.target.value)} 
                       min={1}
                       max={dobMaxes.month}  
                       required/>
                      
                <input type='number'
                          placeholder='Day'
                          style={{width: 85, height: 50, textAlign: 'center', marginLeft: 10, marginRight: 10}}
                            value={dob.day}
                            onChange={(e) => changeDob('day', e.target.value)}
                            min={1}
                            max={dobMaxes.day}
                            required/>
                <input  type='number'
                        placeholder='Year'
                        style={{width: 85, height: 50, textAlign: 'center'}}
                        value={dob.year}
                        onChange={(e) => changeDob('year', e.target.value)}
                        min={1900}
                        max={dobMaxes.year}
                        required/>
            </Row>
        )
       
    }

    const buildHeightRow = () => {
        if (!basicInformation.dob) return null;
        return (
            <Row className="mt-5 karved-up-row" id="height">
                <h5 className="mb-3">Your Height</h5>
                <Col>
                    <Form.Label>Feet</Form.Label>
                    <Form.Control type="number" 
                                  placeholder="Feet"
                                  value={height.feet} 
                                  isValid={height.feet > 0}
                                  onChange={(e) => setHeight({...height, feet: e.target.valueAsNumber})}
                                  required/>
                </Col>
                <Col>
                    <Form.Label>Inches</Form.Label>
                    <Form.Control type="number" 
                                  placeholder="Inches" 
                                  value={height.inches}
                                  isValid={height.inches >= 0}
                                  onChange={(e) => setHeight({...height, inches: e.target.valueAsNumber})}
                                  required/>
                </Col>
            </Row>
        )
    }

    const buildSubmitButton = () => {
        if (!basicInformation.height_in_inches) return null;
        return (
            <Row className="mt-5 karved-up-row " id="submit-button">
                    <Button className="primary-btn" variant="primary" type="submit" disabled={isSaving} onClick={handleSubmit}>
                        {!isSaving ? 'Continue' : 'Saving... Hang Tight!'}
                    </Button>  
            </Row>
        )
    }


    return (
        <Container>
            <Row className="karved-content-container">
            <Col  xs={12}  md={{span: 8, offset: 2}} lg={{span: 8, offset: 2}}>
                <h1 className="text-center">Let's get you KarvedUp</h1>
                <h6 className="text-center">Let's start off with some basic information.</h6>
                {buildGenderRow()}
                {buildGoalRow()}
                {buildCurrentWeightRow()}
                {buildTargetWeightRow()}
                {buildDateOfBirthRow()}
                {buildHeightRow()}
                {buildSubmitButton()}
            </Col>
            </Row>
        </Container>
    )
};