import {useMemo, useState, useEffect} from 'react';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import API from '../../helpers/api';
import { useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';


export default function SuccessfulPayment(){
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    const {sessionId} = useParams();

    useEffect(() => {
        const handleSuccess = async () => {
            const response = await api.confirmSessionSuccessfulPayment(sessionId);
            
            if (response){
                navigate('/start-the-program');
            }
                
        }

        handleSuccess();
    }, [sessionId])

    return (
        <div>

        </div>
    )
}