import {useState, useMemo, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import API from '../../../helpers/api';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';


export default function CheckinModal({close, existingCheckin}){
    const dispatch = useDispatch();
    const [api] = useMemo(() => [new API({dispatch})], [dispatch]);
    
    const [checkin, setCheckin] = useState(() => {
        if (existingCheckin){
            return existingCheckin;
        }
        return {
            weight: 0,
            energy_level: 0,
            mood: 0,
            sleep_hours: 0
        }
    });

    const updateCheckin = (key, value) => {
        setCheckin({
            ...checkin,
            [key]: value
        });
    }

    const saveCheckin = async(e) => {
        e.preventDefault();
        await api.upsertUserDailyCheckin(checkin);
        close();
    }

    return (
        <Modal show={true} onHide={close} >
            <Modal.Header closeButton className="karved-up-modal">
                <Modal.Title>Daily Checkin</Modal.Title>
            </Modal.Header>
            <Modal.Body className="karved-up-modal">
                <Form onSubmit={saveCheckin}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">Weight</Form.Label>
                        <Col sm="8">
                            <Form.Control type="number" value={checkin.weight} onChange={(e) => updateCheckin('weight', e.target.valueAsNumber)} required/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">Energy Level</Form.Label>
                        <Col sm="8">
                            <Form.Control type="number" value={checkin.energy_level} onChange={(e) => updateCheckin('energy_level', e.target.valueAsNumber)} required/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">Mood</Form.Label>
                        <Col sm="8">
                            <Form.Control type="number" value={checkin.mood} onChange={(e) => updateCheckin('mood', e.target.valueAsNumber)} required/>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm="4">Sleep Hours</Form.Label>
                        <Col sm="8">
                            <Form.Control type="number" value={checkin.sleep_hours} onChange={(e) => updateCheckin('sleep_hours', e.target.valueAsNumber)} required/>
                        </Col>
                    </Form.Group>
                    <Row>
                        <Button className="primary-btn" variant="primary" type="submit"> <FontAwesomeIcon icon={faSave}/> Save Checkin</Button>
                    </Row>
                </Form>

            </Modal.Body>
            
        </Modal>
    )

}