import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Provider} from 'react-redux';
import { createStore } from 'redux';
import rootReducer from './reducers';

import BasicInformation from './components/user-intake/basic-information';
import WorkoutInformation from './components/user-intake/workout-information';
import NutritionInformation from './components/user-intake/nutrition-information';
import FirstGoal from './components/user-intake/first-goal';
import MainDashboard from './components/dashboard/main-dashboard';
import Navbar from './components/shared/navbar';
import WorkoutPlanner from './components/workout-creator/workout-planner';
import ForgotPassword from './components/user-management/forgot-password';
import ResetPassword from './components/user-management/reset-password';
import UserPayment from './components/user-intake/user-payment';
import SuccessfulPayment from './components/payment/successful-payment';
import AccountOverview from './components/account/account-overview';
import SelectWorkoutPlan from './components/dashboard/select-workout-plan';
import WeeklyReportCard from './components/weekly-report-card/weekly-report-card';
import PrivacyPolicy from './components/legal/privacy-policy';
import TermsAndConditions from './components/legal/terms-and-conditions';
import Footer from './components/shared/footer';
import AlertContainer from './shared/alert-container';
import DetermineInitialRoute from './Determine-Initial-Route';
import SelectPremiumPlan from './components/dashboard/select-premium-plan';
import ReportBugButton from './components/shared/report-bug';
import StartTheProgram from './components/dashboard/start-the-program';
import StickyFooter from './components/shared/footer-bar';
import WorkoutDashboard from './components/workout-dashboard/workout-dashboard';
import NutritionDashboard from './components/nutrition-dashboard/nutrition-dashboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './styles/main.scss';

const store = createStore(rootReducer); // Create a Redux store
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
   
       
        <Router>
         <Navbar/>
         <ReportBugButton/>
         <AlertContainer/>
            <Routes>
                <Route path="/" element={<DetermineInitialRoute/>} />
                <Route path="/access" element={<App/>} />
                <Route path="/user-intake" element={<BasicInformation/>} />
                <Route path="/user-intake/workout" element={<WorkoutInformation/>} />
                <Route path="/user-intake/nutrition" element={<NutritionInformation/>} />
                <Route path="/user-intake/first-goal" element={<FirstGoal/>} />
                <Route path="/dashboard" element={<MainDashboard/>} />
                <Route path="/workout-planner" element={<WorkoutPlanner/>} />
                <Route path="/forgot-password" element={<ForgotPassword/>} />
                <Route path="/reset-password/:resetToken" element={<ResetPassword/>} />
                <Route path="/user-payment" element={<UserPayment/>} />
                <Route path="/stripe-success/:sessionId" element={<SuccessfulPayment/>} />
                <Route path="/account" element={<AccountOverview/>} />
                <Route path="/select-workout-plan" element={<SelectWorkoutPlan/>} />
                <Route path="/weekly-report-card" element={<WeeklyReportCard/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions/>} />
                <Route path="/select-premium-plan" element={<SelectPremiumPlan/>} />  
                <Route path="/start-the-program" element={<StartTheProgram/>} />  
                <Route path="/workout-home" element={<WorkoutDashboard/>} />
                <Route path="/nutrition-home" element={<NutritionDashboard/>} />
            </Routes>
            <StickyFooter/>
            <Footer/>
        </Router>
    
    </Provider>
);

